import fieldsParser from "@/formFields/fieldsParser";

export const _logicSensorsFields = {
    sensors_s1Sensor: {
        label: {
            fr: "Capteur S1",
            en: "S1 sensor"
        },
        category: ['ebc', 'kessler'],
        type: "radio",
        options: {
            OK: {label: {fr: "Bon", en: "Good"}},
            KO: {label: {fr: "Mauvais", en: "Bad"}}
        }
    },
    sensors_s1SensorValue: {
        label: {
            fr: "Capteur S1 (valeur)",
            en: "S1 sensor (value)"
        },
        category: ['ebc', 'kessler'],
        type: "select",
        defaultOptions: {
            nad: {label: {fr: "RAS", en: "NAD"}},
            sensor_out_of_adjustement: {label: {fr: "Capteur déréglé", en: "Sensor out of adjustment"}},
            sensor_out_of_use: {label: {fr: "Capteur H.S.", en: "Sensor out of use"}},
            deteriorated_cables: {label: {fr: "Câbles détérioré", en: "Deteriorated cables"}},
            deteriorated_detection_face: {
                label: {
                    fr: "Face de détection détérioré",
                    en: "Deteriorated detection face"
                }
            },
        }
    },
    sensors_s2Sensor: {
        label: {
            fr: "Capteur S2",
            en: "S2 sensor"
        },
        category: ['ebc', 'kessler'],
        type: "radio",
        options: {
            OK: {label: {fr: "Bon", en: "Good"}},
            KO: {label: {fr: "Mauvais", en: "Bad"}}
        }
    },
    sensors_s2SensorValue: {
        label: {
            fr: "Capteur S2 (valeur)",
            en: "S2 sensor (value)"
        },
        category: ['ebc', 'kessler'],
        type: "select",
        defaultOptions: {
            nad: {label: {fr: "RAS", en: "NAD"}},
            sensor_out_of_adjustement: {label: {fr: "Capteur déréglé", en: "Sensor out of adjustment"}},
            sensor_out_of_use: {label: {fr: "Capteur H.S.", en: "Sensor out of use"}},
            deteriorated_cables: {label: {fr: "Câbles détérioré", en: "Deteriorated cables"}},
            deteriorated_detection_face: {
                label: {
                    fr: "Face de détection détérioré",
                    en: "Deteriorated detection face"
                }
            },
        }
    },
    sensors_s3Sensor: {
        label: {
            fr: "Capteur S3",
            en: "S3 sensor"
        },
        category: ['ebc', 'kessler'],
        type: "radio",
        options: {
            OK: {label: {fr: "Bon", en: "Good"}},
            KO: {label: {fr: "Mauvais", en: "Bad"}}
        }
    },
    sensors_s3SensorValue: {
        label: {
            fr: "Capteur S3 (valeur)",
            en: "S3 sensor (value)"
        },
        category: ['ebc', 'kessler'],
        type: "select",
        defaultOptions: {
            nad: {label: {fr: "RAS", en: "NAD"}},
            sensor_out_of_adjustement: {label: {fr: "Capteur déréglé", en: "Sensor out of adjustment"}},
            sensor_out_of_use: {label: {fr: "Capteur H.S.", en: "Sensor out of use"}},
            deteriorated_cables: {label: {fr: "Câbles détérioré", en: "Deteriorated cables"}},
            deteriorated_detection_face: {
                label: {
                    fr: "Face de détection détérioré",
                    en: "Deteriorated detection face"
                }
            },
        }
    },
    sensors_s4Sensor: {
        label: {
            fr: "Capteur S4",
            en: "S4 sensor"
        },
        category: ['ebc', 'kessler'],
        type: "radio",
        options: {
            OK: {label: {fr: "Bon", en: "Good"}},
            KO: {label: {fr: "Mauvais", en: "Bad"}}
        }
    },
    sensors_s4SensorValue: {
        label: {
            fr: "Capteur S4 (valeur)",
            en: "S4 sensor (value)"
        },
        category: ['ebc', 'kessler'],
        type: "select",
        defaultOptions: {
            nad: {label: {fr: "RAS", en: "NAD"}},
            sensor_out_of_adjustement: {label: {fr: "Capteur déréglé", en: "Sensor out of adjustment"}},
            sensor_out_of_use: {label: {fr: "Capteur H.S.", en: "Sensor out of use"}},
            deteriorated_cables: {label: {fr: "Câbles détérioré", en: "Deteriorated cables"}},
            deteriorated_detection_face: {
                label: {
                    fr: "Face de détection détérioré",
                    en: "Deteriorated detection face"
                }
            },
        }
    },
    sensors_analogic: {
        label: {
            fr: "Capteur analogique",
            en: "Analogic sensor"
        },
        category: ['ebc', 'kessler'],
        type: "radio",
        options: {
            OK: {label: {fr: "Bon", en: "Good"}},
            KO: {label: {fr: "Mauvais", en: "Bad"}}
        },
        columnWeight: 2,
    },
    sensors_sensorsPhotos: {
        label: {
            fr: "Photos des capteurs",
            en: "Sensors photos"
        },
        category: ['ebc', 'kessler'],
        type: 'photo',
        directoryName: 'Capteurs',
        columnWeight: 2,
    },
    sensors_stateDivision_3: {
        label: {
            fr: "Etat des capteurs en -3",
            en: "Sensor state in -3"
        },
        category: ['ebc', 'kessler'],
        type: "input",
        columnWeight: 2,
    },
    sensors_stateDivision1: {
        label: {
            fr: "Etat des capteurs en 1",
            en: "Sensor state in 1"
        },
        category: ['ebc', 'kessler'],
        type: "input",
        columnWeight: 2,
    },
    sensors_stateDivision_2: {
        label: {
            fr: "Etat des capteurs en -2",
            en: "Sensor state in -2"
        },
        category: ['ebc', 'kessler'],
        type: "input",
        columnWeight: 2,
    },
    sensors_stateDivision2: {
        label: {
            fr: "Etat des capteurs en 2",
            en: "Sensor state in 2"
        },
        category: ['ebc', 'kessler'],
        type: "input",
        columnWeight: 2,
    },
    sensors_stateDivision_1: {
        label: {
            fr: "Etat des capteurs en -1",
            en: "Sensor state in -1"
        },
        category: ['ebc', 'kessler'],
        type: "input",
        columnWeight: 2,
    },
    sensors_stateDivision3: {
        label: {
            fr: "Etat des capteurs en 3",
            en: "Sensor state in 3"
        },
        category: ['ebc', 'kessler'],
        type: "input",
        columnWeight: 2,
    },
    sensors_tight_loose: {
        label: {
            fr: "État Serrage capteur",
            en: "Sensor tightening state"
        },
        category: ['ebc', 'kessler'],
        type: "radio",
        options: {
            tightened_with_tools: {label: {fr: "Serré sans outil", en: "Tightened with tools"}},
            loose: {label: {fr: "Déserré", en: "Loose"}}
        },
        columnWeight: 2,
    }
}

export const logicSensorsFields = fieldsParser(_logicSensorsFields)