/* CHECKLIST INFORMATIONS GÉNÉRALES */
import { _checklistGeneralInformationsFields } from "./checklist/generalInformations";
/* CHECKLIST ÉQUILIBRAGE */
import { _checklistBalancingFields } from "./checklist/balancing";
import { _checklistBalancingMaxBearingFields } from "./checklist/maxBearing";
import { _checklistBalancingCustomerBearingFields } from "./checklist/customerBearing";
/* CHECKLIST TEST DYNAMIQUE */
import {_checklistDynamicTestsFields} from "@/formFields/conformityReport/checklist/dynamicTests";
/* CHECKLIST GRAISSAGE */
import { _checklistLubrificationFields } from "./checklist/lubrification";
import { _checklistLubrificationsLubrificationFields } from "./checklist/lubrifications";
/* CHECKLIST GÉOMÉTRIE/CAPTEURS */
import { _checklistGeometryFields } from "./checklist/geometry";
import { _checklistSensorFields } from "./checklist/sensor";
/* CHECKLIST CONTRÔLE MOTEUR ENCODEUR */
import { _checklistEncoderMotorControlFields } from "./checklist/encoderMotorControl";
/* CHECKLIST CONTRÔLE AVANT LIVRAISON */
import { _checklistCheckBeforeDeliveryFields } from "./checklist/checkBeforeDelivery";
/* PV DE CONFORMITÉ SPÉCIFICATIONS MOTEUR */
import { _conformityReportMotorSpecificationsFields } from "./conformityReport/motorSpecifications";
/* PV DE CONFORMITÉ GÉOMÉTRIE */
import { _conformityReportGeometryFields } from "./conformityReport/geometry";
/* PV DE CONFORMITÉ CONTRÔLE SERRAGE OUTIL */
import { _conformityReportToolTighteningCheckFields } from "./conformityReport/toolTighteningCheck";
/* PV DE CONFORMITÉ ANNEXE */
import { _conformityReportAnnexFields } from "./conformityReport/annex";
export const conformityReportFields = {
    /* CHECKLIST INFORMATIONS GÉNÉRALES */
    ..._checklistGeneralInformationsFields,
    /* CHECKLIST ÉQUILIBRAGE */
    ..._checklistBalancingFields,
    ..._checklistBalancingMaxBearingFields,
    ..._checklistBalancingCustomerBearingFields,
    /* CHECKLIST TEST DYNAMIQUE */
    ..._checklistDynamicTestsFields,
    /* CHECKLIST GRAISSAGE */
    ..._checklistLubrificationFields,
    ..._checklistLubrificationsLubrificationFields,
    /* CHECKLIST GÉOMÉTRIE/CAPTEURS */
    ..._checklistGeometryFields,
    ..._checklistSensorFields,
    /* CHECKLIST CONTRÔLE MOTEUR ENCODEUR */
    ..._checklistEncoderMotorControlFields,
    /* CHECKLIST CONTRÔLE AVANT LIVRAISON */
    ..._checklistCheckBeforeDeliveryFields,
    /* PV DE CONFORMITÉ SPÉCIFICATIONS MOTEUR */
    ..._conformityReportMotorSpecificationsFields,
    /* PV DE CONFORMITÉ GÉOMÉTRIE */
    ..._conformityReportGeometryFields,
    /* PV DE CONFORMITÉ CONTRÔLE SERRAGE OUTIL */
    ..._conformityReportToolTighteningCheckFields,
    /* PV DE CONFORMITÉ ANNEXE */
    ..._conformityReportAnnexFields,
};

/* CHECKLIST INFORMATIONS GÉNÉRALES */
export { checkListGeneralInformationsFields } from "./checklist/generalInformations";
/* CHECKLIST ÉQUILIBRAGE */
export { checkListBalancingFields } from "./checklist/balancing";
export { checkListBalancingMaxBearingFields } from "./checklist/maxBearing";
export { checkListBalancingCustomerBearingFields } from "./checklist/customerBearing";
/* CHECKLIST TEST DYNAMIQUE */
export { checklistDynamicTestsFields } from "./checklist/dynamicTests";
/* CHECKLIST GRAISSAGE */
export { checkListLubrificationFields } from "./checklist/lubrification";
export { checkListLubrificationsLubrificationFields } from "./checklist/lubrifications";
/* CHECKLIST GÉOMÉTRIE/CAPTEURS */
export { checkListGeometryFields } from "./checklist/geometry";
export { checkListSensorFields } from "./checklist/sensor";
/* CHECKLIST CONTRÔLE MOTEUR ENCODEUR */
export { checkListEncoderMotorControlFields } from "./checklist/encoderMotorControl";
/* CHECKLIST CONTRÔLE AVANT LIVRAISON */
export { checkListCheckBeforeDeliveryFields } from "./checklist/checkBeforeDelivery";
/* PV DE CONFORMITÉ SPÉCIFICATIONS MOTEUR */
export { conformityReportMotorSpecificationsFields } from "./conformityReport/motorSpecifications";
/* PV DE CONFORMITÉ GÉOMÉTRIE */
export { conformityReportGeometryFields } from "./conformityReport/geometry";
/* PV DE CONFORMITÉ CONTRÔLE SERRAGE OUTIL */
export { conformityReportToolTighteningCheckFields } from "./conformityReport/toolTighteningCheck";
/* PV DE CONFORMITÉ ANNEXE */
export { conformityReportAnnexFields } from "./conformityReport/annex";