import fieldsParser from "../../fieldsParser";

export const _encoderFaultsCausesFields = {
    encoder_faultsCauses_oilyAtmosphere: {
        label: {
            fr: "Atmosphère huileuse",
            en: "Oily atmosphere"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    encoder_faultsCauses_lastRepairs: {
        label: {
            fr: "Réparation précédente",
            en: "Last repairs"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    encoder_faultsCauses_flood: {
        label: {
            fr: "Inondation",
            en: "Flood"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    encoder_faultsCauses_wheelEncoderContact: {
        label: {
            fr: "Contact roue/encodeur",
            en: "Wheel/encoder contact"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    encoder_faultsCauses_normalWear: {
        label: {
            fr: "Usure normale",
            en: "Normal wear"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    encoder_faultsCauses_overvoltage: {
        label: {
            fr: "Surtension",
            en: "Overvoltage"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    encoder_faultsCauses_damagedCable: {
        label: {
            fr: "Câbles endommagés",
            en: "Damaged cable"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
};

export const encoderFaultsCausesFields = fieldsParser(_encoderFaultsCausesFields);