import fieldsParser from "../../fieldsParser";

export const _coolingSystemWaterMantleAndSheathFields = {
    coolingSystem_waterMantleAndSheath_mantleAndSheathValue: {
        label: {
            fr: "Circuit refroidissement",
            en: "Cooling circuit"
        },
		category: ['ebc'],
		type: "select",
        defaultOptions: {
            pollution: { label: { fr: "Pollution", en: "Pollution" } },
            significant_oxidation: { label: { fr: "Oxydation importante", en: "Significant oxidation" } },
            deformation: { label: { fr: "Déformation", en: "Deformation" } },
        },
        extraColumnSpace: 2
    }
};

export const coolingSystemWaterMantleAndSheathFields = fieldsParser(_coolingSystemWaterMantleAndSheathFields);