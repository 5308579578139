import fieldsParser from "@/formFields/fieldsParser"

export const _spindleRestGeometry = {
    spindleRest_geometryControl: {
        label: {
            fr: "Contrôle de la géométrique post-rodage",
            en: "Control of the post-machining geometry"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } }
        }
    }
}

export const spindleRestGeometry = fieldsParser(_spindleRestGeometry)