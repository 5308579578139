import fieldsParser from "../../fieldsParser";

export const _encoderRepairsFields = {
    encoder_repairs_encoderReplacement: {
        label: {
            fr: "Remplacement de l'encodeur",
            en: "Encoder replacement"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    encoder_repairs_rotaryEncoderReplacement: {
        label: {
            fr: "Remplacement de la roue codeuse",
            en: "Rotary encoder replacement"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    encoder_repairs_socketReplacement: {
        label: {
            fr: "Remplacement de la prise",
            en: "Socket replacement"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
};

export const encoderRepairsFields = fieldsParser(_encoderRepairsFields);