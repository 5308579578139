import fieldsParser from "../../fieldsParser";

export const _encoderFields = {
    encoder_coderType: {
        label: {
            fr: "Type de codeur",
            en: "Coder type"
        },
		category: ['ebc', 'kessler'],
		type: "input"
    },
    encoder_generalAppearance_okko: {
        label: {
            fr: "Aspect général",
            en: "General appearance"
        },
		category: ['ebc'],
        type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    encoder_generalAppearance: {
        label: {
            fr: "Aspect général",
            en: "General appearance"
        },
		category: ['ebc'],
		type: "input"
    },
    encoder_cabling: {
        label: {
            fr: "Câblage",
            en: "Cabling"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    encoder_baseplate: {
        label: {
            fr: "Embase",
            en: "Baseplate"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    encoder_encoderMacroPhoto: {
        label: {
            fr: "Photo encodeur",
            en: "Encoder photo"
        },
		category: ['ebc', 'kessler'],
		type: 'photo',
        directoryName: 'Encodeur'
    },
    encoder_encodingWheelPhoto: {
        label: {
            fr: "Photo roue codeuse",
            en: "Encoding wheel photo"
        },
        category: ['ebc', 'kessler'],
        type: 'photo',
        directoryName: 'Encodeur',
        extraColumnSpace: 2
    },
    encoder_serialNumber: {
        label: {
            fr: "Numéro de série",
            en: "Serial number"
        },
		category: ['kessler'],
		type: "input",
        extraColumnSpace: 3
    },
    encoder_surface: {
        label: {
            fr: "Surface",
            en: "Surface"
        },
		category: ['precise'],
		type: "input"
    },
    encoder_nonexistent: {
        label: {
            fr: "Inexistant",
            en: "Nonexistent"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    encoder_nonDisassembled: {
        label: {
            fr: "Non démonté",
            en: "Non-disassembled"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    }
};

export const encoderFields = fieldsParser(_encoderFields);