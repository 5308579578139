<style scoped>
    .expertAssessmentTitle {
        font-weight: 600;
    }

    .two-column-div {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .three-column-div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    #form-nomenclature {
        justify-content: flex-start;
    }

    #form-subcontracting {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
    }

    #form-nomenclature :deep(.article-container), #form-subcontracting :deep(.article-container) {
        display: flex;
        justify-content: space-between;
        max-width: 15vw;
    }

    #form-nomenclature :deep(.articles-list .inputeo), #form-subcontracting :deep(.articles-list .inputeo) {
        margin: 0px 10px;
        width: 100px;
    }

    #form-nomenclature .article-detail-container , #form-subcontracting .article-detail-container {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: space-between;
    }
    #form-subcontracting .article-detail-container .inputeo {
        margin: 10px 5px;
    }

    #form-nomenclature .article-detail-container div:nth-child(1), #form-subcontracting .article-detail-container div:nth-child(1) {
        flex: 2;
    }

    #form-nomenclature .article-detail-container div:nth-child(2), #form-subcontracting .article-detail-container div:nth-child(2) {
        flex: 1;
    }

    #form-nomenclature > div, #form-subcontracting > div {
        justify-content: flex-start;
        flex: 2;
    }

    #form-nomenclature > div:first-child, #form-subcontracting > div:first-child {
        flex: 1.3;
    }

    #form-nomenclature > div:last-child, #form-subcontracting > div:last-child {
        flex: 3;
    }

    #form-nomenclature :deep(.inputeo), #form-subcontracting :deep(.inputeo) {
        width: 100%;
    }

    #form-subcontracting .sublabel {
        font-size: 10px;
        color: #8a8a8a;
        font-style: italic;
    }

    .bearings-list form {
        padding-top: 10px;
        border-right: 1px solid rgb(238, 127, 0);
        border-bottom: 1px solid rgb(238, 127, 0);
    }

    .bearings-list :deep(.checkbox-button-container) {
        margin-bottom: 13px;
    }

    .bearings-list > div {
        position: relative;
        min-height: 525px;
    }

    .delete-bearing-button {
        position: absolute;
        right: 5px;
        z-index: 1;
        background-color: #FFF !important;
        color: #646e78 !important;
        border: 1px solid #646e78 !important;
    }

    .add-bearing-button-div {
        box-sizing: border-box;
        display: flex;
    }

    .add-bearing-button-border {
        margin: 8px;
        border: 2px dashed rgb(180, 190, 200);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        width: 100%;

    }

    .add-bearing-button {
        background-color: #FFFFFF;
        color: #646e78 !important;
        font-size: 80px !important;

    }

    .add-bearing-button svg {
        margin: auto;
    }

    #expert-assessment-modal :deep(.modal-content) {
        overflow: hidden !important;
    }

    .articles-group-by-family:not(:last-child) {
        margin-bottom: 25px;
    }

    .articles-group-by-family {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .articles-group-by-family .inputeo {
        margin-bottom: 0;
    }

    #expert-assessment-modal :deep(.inputeo) {
        width: calc(100% - 15px);
        margin: 0px 15px 18px 15px;
    }

    #expert-assessment-modal :deep(.inputeo > :not(.multiselect) input) {
        min-height: 41px;
    }

    .custom-form-title {
        background-color: #0d3b70;
        color: white;
        height: 32px;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 13px;
        position: relative;
        margin-top: 20px;
    }

    .custom-form-title:after {
        position: absolute;
        right: -20px;
        top: 0;
        content: '';
        width: 0;
        height: 0;
        border-top: 0 solid transparent;
        border-bottom: 32px solid transparent;
        border-left: 20px solid #0d3b70;
    }

    .articles-family-list {
        width: calc(100% - 15px);
        max-height: 600px;
        overflow: auto;
        margin: 0px 15px 10px 15px;
        border: 1px solid #c8d2dc;
        -ms-overflow-style: none;
        scrollbar-width: none;
        box-sizing: border-box;
    }

    .articles-family-list::-webkit-scrollbar{
        display: none;
    }

    .articles-family-list div {
        font-size: 16px;
        margin: 5px 10px;
        padding: 5px 0;
    }

    
    .modal-bottom-title {
        display: flex;
        flex-direction: row;
        gap: 5px;
    }

    @media (max-width: 1200px) {
        .modal :deep(.button-text) {
            font-size: 9px;
        }

        
    .modal-bottom-title {
        font-size: 9px;
    }

    }
</style>

<template>
    <div>
        <Modal id="expert-assessment-modal" mode="step" :fullscreen="true">
            <template v-slot:modalStep>
                <ModalStep
                    ref="expertAssessmentModalStep"
                    step="1"
                    @onExit="onExit"
                    @onPreviousStep="onExpertAssessmentModalPreviousStep"
                    @onNextStep="onExpertAssessmentModalNextStep"
                    @onValidate="onSaveExpertAssessmentForm"
                    validateTextButton="Enregistrer"
                    validateIconButton="save"
                    :autoPrevious="false"
                    :autoNext="false"
                    mode="menu"
                    :menu="expertAssessmentMenu"
                >
                    <template v-slot:modalStepMenuExtraButtons>
                        <BaseButton v-if="!iAmTechnicianOnBrowser()" @click="onValidateExpertAssessmentForm" buttonText="Valider" title="Valider le rapport" buttonClass="orange-button" :disabled="!isNomenclatureValidable || !expertAssessment.completed">
                            <template v-slot:iconBefore>
                                <font-awesome-icon :icon="['fas', 'check']" />
                            </template>
                        </BaseButton>
                        <BaseButton @click="openMessagesModal" buttonText="" title="Accéder au fil de discussion" buttonClass="green-button">
                            <template v-slot:iconBefore>
                                <font-awesome-icon :icon="['fas', 'comment-check']" />
                            </template>
                        </BaseButton>
                    </template>
                    <template v-slot:modalStepMenuTitle>
                        <div class="modal-bottom-title">
                            <div style="flex:auto;"></div>
                            <div style="flex:none;">Rapport d'expertise<span style="font-style: italic; color: grey;"> ({{eaType.label}})</span></div>
                            <div style="flex:none;" class="expertAssessmentTitle">{{ `${affair.affairNumber} ${affair.spindle ? `${affair.spindle?.spindleType.spindleBrand.name} ${affair.spindle?.spindleType.name}` : ''}` }}</div>
                            <div style="flex:auto;"></div>
                        </div>
                    </template>
                    <template v-slot:modalStepItem>
                        <!-- INFORMATIONS GÉNÉRALES -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.generalInformationsFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- PRÉ-DÉMONTAGE -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.preDisassemblyFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- ARBRE-ROTOR -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.rotorShaftFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- ARBRE-ROTOR (ARBRE/CORPS DE LA BROCHE) -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.rotorShaftSpindleAxisFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                    formTitle="Arbre de la broche"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.rotorShaftSpindleBodyFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                    formTitle="Corps de la broche"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- ARBRE-ROTOR (POINTS DE MESURES) -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <div class="three-column-div">
                                    <div>
                                        <CustomForm
                                            v-model="expertAssessment"
                                            :fields="eaForm.rotorShaftMeasurementsPointsRL1Fields"
                                            :fieldCategory="eaType.value"
                                            :fieldsPerRow="2"
                                            formTitle="RL1"
                                            :categoryBorder="eaType.value !== 'ebc'"
                                        >
                                        </CustomForm>
                                    </div>

                                    <div>
                                        <CustomForm
                                            v-model="expertAssessment"
                                            :fields="eaForm.rotorShaftMeasurementsPointsRL2Fields"
                                            :fieldCategory="eaType.value"
                                            :fieldsPerRow="2"
                                            formTitle="RL2"
                                            :categoryBorder="eaType.value !== 'ebc'"
                                        >
                                        </CustomForm>
                                    </div>

                                    <div>
                                        <CustomForm
                                            v-model="expertAssessment"
                                            :fields="eaForm.rotorShaftMeasurementsPointsRL3Fields"
                                            :fieldCategory="eaType.value"
                                            :fieldsPerRow="2"
                                            formTitle="RL3"
                                            :categoryBorder="eaType.value !== 'ebc'"
                                        >
                                        </CustomForm>
                                    </div>

                                    <div>
                                        <CustomForm
                                            v-model="expertAssessment"
                                            :fields="eaForm.rotorShaftMeasurementsPointsPL1Fields"
                                            :fieldCategory="eaType.value"
                                            :fieldsPerRow="2"
                                            formTitle="PL1"
                                            :categoryBorder="eaType.value !== 'ebc'"
                                        >
                                        </CustomForm>
                                    </div>

                                    <div>
                                        <CustomForm
                                            v-model="expertAssessment"
                                            :fields="eaForm.rotorShaftMeasurementsPointsPL2Fields"
                                            :fieldCategory="eaType.value"
                                            :fieldsPerRow="2"
                                            formTitle="PL2"
                                            :categoryBorder="eaType.value !== 'ebc'"
                                        >
                                        </CustomForm>
                                    </div>

                                    <div>
                                        <CustomForm
                                            v-model="expertAssessment"
                                            :fields="eaForm.rotorShaftMeasurementsPointsPL3Fields"
                                            :fieldCategory="eaType.value"
                                            :fieldsPerRow="2"
                                            formTitle="PL3"
                                            :categoryBorder="eaType.value !== 'ebc'"
                                        >
                                        </CustomForm>
                                    </div>
                                </div>
                            </template>
                        </ModalStepItem>
                        <!-- ARBRE-ROTOR (CAUSES/RÉPARATIONS) -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.rotorShaftFaultsCausesFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Causes des défauts"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.rotorShaftRepairsFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Opérations de réparation"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- ROULEMENTS (LISTE) -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <div class="two-column-div bearings-list">
                                    <div v-for='index in expertAssessment.bearingsCount' :key="index">
                                        <BaseButton @click="function() { deleteBearing(index) }" buttonText="" title="Supprimer le roulement" class="delete-bearing-button">
                                            <template v-slot:iconBefore>
                                                <font-awesome-icon :icon="['fas', 'times']" />
                                            </template>
                                        </BaseButton>
                                        <CustomForm
                                            v-model="expertAssessment"
                                            :fields="eaForm.bearingsBearingFields"
                                            :fieldCategory="eaType.value"
                                            :fieldsPerRow="2"
                                            :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                            :index="index"
                                            checkboxColor="grey"
                                            :title="`Roulement ${index}`"
                                            :categoryBorder="eaType.value !== 'ebc'"
                                        >
                                        </CustomForm>
                                    </div>
                                    <div class="add-bearing-button-div">
                                        <div class="add-bearing-button-border">
                                            <BaseButton @click="addBearing" buttonText="" title="Ajouter un roulement" class="add-bearing-button">
                                                <template v-slot:iconBefore>
                                                    <font-awesome-icon :icon="['fal', 'plus']" />
                                                </template>
                                            </BaseButton>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </ModalStepItem>
                        <!-- ROULEMENTS (ENTRETOISES / CANAUX DE GRAISSAGE) -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.bearingsStrutsFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Entretoises"
                                    :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.bearingsOilChannelsFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Canaux de graissage"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.bearingsStrutsAndOilChannelsFaultsCausesFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Causes des défauts"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- ROULEMENTS (LOGEMENTS) -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.bearingsCompartmentsPhotosFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                    formTitle="Photos"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.bearingsCompartmentsFrontCaseFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                    formTitle="Boitier avant"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.bearingsCompartmentsBackCaseFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Boitier arrière"
                                    :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.bearingsCompartmentsAirCurtainAndLabyrinthFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Rideau d'air / Labyrinthe"
                                    :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.bearingsCompartmentsRepairsFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Opérations de réparation"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- ROULEMENTS (CARTOUCHES) -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.bearingsBackCartridgesFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Cartouches de roulements avant"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.bearingsFrontCartridgesFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Cartouches de roulements arrière"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- CIRCUIT DE REFROIDISSEMENT -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.coolingSystemPhotosFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                    formTitle="Photos"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.coolingSystemWaterMantleAndSheathFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Manteau d'eau / fourreau"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.coolingSystemFaultsCausesFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Causes des défauts"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.coolingSystemRepairsFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Opérations de réparation"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- SYSTÈME DE SERRAGE ET D'ARROSAGE -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.tighteningAndDousingSystemFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- SYSTÈME DE SERRAGE ET D'ARROSAGE (KESSLER) -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.tighteningAndDousingSystemKesslerManualTestsFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                    formTitle="Tests manuels"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.tighteningAndDousingSystemKesslerMechanicalTestsFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Tests mécaniques"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.tighteningAndDousingSystemKesslerTighteningConeFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                    formTitle="Cône de serrage"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.tighteningAndDousingSystemKesslerControlKeelFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                    formTitle="Quille de contrôle"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- SYSTÈME DE SERRAGE ET D'ARROSAGE (PRECISE) -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.tighteningAndDousingSystemPreciseFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.tighteningAndDousingSystemPreciseComponentsFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Composants"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- SYSTÈME DE SERRAGE ET D'ARROSAGE (CAUSES/RÉPARATIONS) -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.tighteningAndDousingSystemFaultsCausesFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Causes des défauts"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.tighteningAndDousingSystemRepairsFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Opérations de réparation"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- MOTEUR (STATOR) -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.motorFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.motorResistanceTestsFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Tests de résistance"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.motorTemperatureFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Sonde"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.motorFaultsCausesFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Cause des défauts"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.motorRepairsFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Opérations de réparation"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- CAPTEURS -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.logicSensorsFields"
                                    :fieldsPerRow="4"
                                    :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                    :fieldCategory="eaType.value"
                                    formTitle="Capteurs"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.sensorsFaultsCausesFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Causes des défauts"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.sensorsRepairsFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Opérations de réparation"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.sensorsFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                    formTitle="Autre (Precise)"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- ENCODEUR -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.encoderEncoderReadingFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Relevé de l'encodeur"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                              <CustomForm
                                  v-model="expertAssessment"
                                  :fields="eaForm.encoderFields"
                                  :fieldCategory="eaType.value"
                                  :fieldsPerRow="4"
                                  :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                  formTitle="Test encodeur"
                                  :categoryBorder="eaType.value !== 'ebc'"
                                > 
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.encoderRotaryEncoderReadingFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Relevé de la roue codeuse"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
    
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.encoderFaultsCausesFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Causes des défauts"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.encoderRepairsFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Opérations de réparation"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.encoderHeidenhainMeasurementsFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    formTitle="Mesures Heidenhain"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- CONCLUSION (1/2) -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.conclusionFaultsFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    checkboxColor="orange"
                                    formTitle="Les défaillances rencontrées"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.conclusionFaultsCausesFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    checkboxColor="orange"
                                    formTitle="Les causes des défaillances"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- CONCLUSION (2/2) -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.conclusionReliabilityAdvicesFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    checkboxColor="orange"
                                    formTitle="Les conseils de fiabilisation"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.conclusionRepairsFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    checkboxColor="orange"
                                    formTitle="Les opérations de remise en état qui vont être effectuées"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="expertAssessment"
                                    :fields="eaForm.conclusionSummaryFields"
                                    :fieldCategory="eaType.value"
                                    :fieldsPerRow="4"
                                    checkboxColor="orange"
                                    :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                    formTitle="Résumé"
                                    :categoryBorder="eaType.value !== 'ebc'"
                                >
                                </CustomForm>
                                <div class="custom-form-title">Ordre de travaux</div>
                                <form id="form-subcontracting" class="form-column">
                                    <BaseSelect
                                        v-model="articlesEstablishmentFilter"
                                        label="Etablissement"
                                        :defaultOptions="establishments"
                                        name="searchArticleEstablishment"
                                        :display-tag="true"
                                        :disabled="affair?.nomenclature?.completed"
                                        fieldLabel="label"
                                        fieldValue="id"
                                    />
                                    <BaseSelect
                                        v-model="currentSubContractingArticle"
                                        label="Ajouter un article"
                                        name="searchArticle"
                                        @onChange="onSubContractingArticleAdd"
                                        api="article/search"
                                        :apiParams="{
                                            articleKindId: -1,
                                            establishment: articlesEstablishmentFilter?.value
                                        }"
                                        fieldValue="id"
                                        fieldLabel="label"
                                        :disabled="affair?.nomenclature?.completed"
                                        :searchable="true"
                                        formTitle="Ordre de travaux"
                                    />
                                    <ListWithDelete
                                        v-if="subContractingArticle.length > 0"
                                        v-model="subContractingArticle"
                                        :disabled="affair?.nomenclature?.completed"
                                        :flex-row="true"
                                    >
                                        <template v-slot:content="{ item, index }">
                                            <div class="article-detail-container">
                                                <div>{{ item.label }}<br/><span class="sublabel">{{ item.rewind ? 'Rebobinage' : item.externalMachining ? 'Usinage externe' : 'Sans type' }}</span></div>
                                                <BaseInput
                                                    v-model="item.quantity"
                                                    type="text"
                                                    :label="'Qté' + (item.unit ? ` (${item.unit})` : '')"
                                                    :name="'quantity_' + index"
                                                    validator="decimal_2"
                                                    :required="true"
                                                    :displayError="displayError"
                                                    :isSmall="true"
                                                    :disabled="affair?.nomenclature?.completed"
                                                />
                                            </div>
                                        </template>
                                    </ListWithDelete>
                                </form>
                            </template>
                        </ModalStepItem>
                        <!-- NOMENCLATURE -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <form id="form-nomenclature" class="form-column" v-if="affair.currentNomenclature">
                                    <div>
                                        <BaseInput
                                            v-model="affair.currentNomenclature.comment"
                                            label="Éléments à ne pas oublier"
                                            type="textarea"
                                            name="nomenclatureComment"
                                            @onChange="onNomenclatureFormInputChange"
                                            :disabled="!isNomenclatureEditable"
                                            :required="false"
                                            minHeight="285px"
                                        />
                                        <div class="articles-family-list">
                                            <div v-for="family in articlesFamily">{{ family.label }}</div>
                                        </div>
                                    </div>
                                    <div v-if="!($store.getters.isTechnician)">
                                        <BaseSelect
                                            v-model="form.disciplineCurrent"
                                            label="Ajouter une discipline"
                                            name="searchDiscipline"
                                            @onChange="onDisciplineAdd"
                                            api="discipline/search"
                                            fieldValue="id"
                                            fieldLabel="label"
                                            :searchable="true"
                                            :disabled="!isNomenclatureEditable"
                                            :minChars="1"
                                        />
                                        <ListWithDelete v-model="affair.currentNomenclature.disciplines" label="Liste des disciplines" :disabled="!isNomenclatureEditable">
                                            <template v-slot:content="{ item }">
                                                <div>{{ item.label }}</div>
                                            </template>
                                        </ListWithDelete>
                                    </div>
                                    <div>
                                        <BaseSelect
                                            v-model="form.articleCurrentEstablishmentFilter"
                                            label="Etablissement"
                                            :defaultOptions="establishments"
                                            name="searchArticleEstablishment"
                                            :display-tag="true"
                                            :disabled="!isNomenclatureEditable"
                                            fieldLabel="label"
                                            fieldValue="id"
                                        />
                                    </div>
                                    <div>
                                        <BaseSelect
                                            v-model="form.articleCurrent"
                                            label="Ajouter un article"
                                            name="searchArticle"
                                            @onChange="onArticleAdd"
                                            api="article/search"
                                            :apiParams="{
                                                isTechnician: $store.getters.isTechnician,
                                                establishment: form.articleCurrentEstablishmentFilter?.value
                                            }"
                                            fieldValue="id"
                                            fieldLabel="label"
                                            :searchable="true"
                                            :disabled="!isNomenclatureEditable"
                                        />
                                        <div v-for="(groupByFamily, index) in affair.currentNomenclature.articlesGroupByFamily" class="articles-group-by-family" :key="index">
                                            <ListWithDelete
                                                v-if="(groupByFamily.articles.length > 0) && (hasLabourArticle(groupByFamily.articles))"
                                                v-model="groupByFamily.articles"
                                                :label="groupByFamily.family"
                                                :disabled="!isNomenclatureEditable"
                                            >
                                                <template v-slot:content="{ item, index }">
                                                    <div class="article-detail-container" v-if="(!$store.getters.isTechnician) || ($store.getters.isTechnician && notLabour(item))">
                                                        <div :style="(!item.outOfNomenclatureType ? 'font-weight: bold;' : '')">{{ item.label }}</div>
                                                        <BaseInput
                                                            v-model="item.quantity"
                                                            type="text"
                                                            :label="'Qté' + (item.unit ? ` (${item.unit})` : '')"
                                                            :name="'quantity_' + index"
                                                            validator="decimal_2"
                                                            @onChange="onNomenclatureFormInputChange"
                                                            :required="true"
                                                            :displayError="displayError"
                                                            :disabled="!isNomenclatureEditable"
                                                            :isSmall="true"
                                                        />
                                                    </div>
                                                </template>
                                            </ListWithDelete>
                                        </div>
                                    </div>
                                </form>
                            </template>
                        </ModalStepItem>
                    </template>
                </ModalStep>
            </template>
        </Modal>

        <Dialog ref="dialog" />
    </div>
</template>

<script>
    import axios from 'axios';
    import Modal from "../../components/App/Modal";
    import BaseButton from "../../components/Base/BaseButton";
    import BaseInput from "../../components/Base/BaseInput";
    import BaseSelect from "../../components/Base/BaseSelect";
    import accountRoleEnum from "../../enums/accountRoleEnum";
    import ModalStep from '../../components/App/ModalStep';
    import ModalStepItem from '../../components/App/ModalStepItem';
    import CustomForm from '../../components/App/CustomForm';
    import ListWithDelete from '../../components/App/ListWithDelete';
    import config from "../../config";
    import Dialog from '../../components/App/Dialog'
    import * as eaForm from '../../formFields/expertAssessment';
    import $ from 'jquery';
    import store from "@/store";
    import { dataUrlToFile } from "../../utils/dataUrlToFile";
    import ArticleKindEnum from "@/enums/articleKindEnum";

    const MODAL_STEP_NOMENCLATURE = 21;

    export default {
        name: 'ExpertAssessmentModal',
        components: {
            config,
            BaseButton,
            BaseInput,
            BaseSelect,
            Modal,
            ModalStep,
            ModalStepItem,
            CustomForm,
            ListWithDelete,
            Dialog
        },
        emits: ['onExit', 'openMessagesModal', 'refresh', 'update:modelValue', 'refreshNomenclatureArticles'],
        props: {
            modelValue: {
                type: Object,
                default: null
            },
            eaType: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                config,
                displayError: false,
                nomenclatureFormErrors: {},
                expertAssessmentMenu: [
                    { label: 'Informations générales', value: 1 },
                    { label: 'Contrôles pré-démontage', value: 2 },
                    { label: 'Arbre-rotor', value: 3 },
                    { label: 'Arbre-rotor (Axe / Corps) (Kessler)', value: 4 },
                    { label: 'Arbre-rotor (Points de mesures) (Precise)', value: 5 },
                    { label: 'Arbre-rotor (Causes / Réparations)', value: 6 },
                    { label: 'Roulements (Liste)', value: 7 },
                    { label: 'Roulements (Entretoises / Canaux de graissage)', value: 8 },
                    { label: 'Roulements (Logements)', value: 9 },
                    { label: 'Roulements (Cartouches) (Precise)', value: 10 },
                    { label: 'Circuit de refroidissement', value: 11 },
                    { label: 'Système de serrage et d\'arrosage', value: 12 },
                    { label: 'Système de serrage et d\'arrosage (Kessler)', value: 13 },
                    { label: 'Système de serrage et d\'arrosage (Kessler)', value: 14 },
                    { label: 'Système de serrage et d\'arrosage (Causes / Réparations)', value: 15 },
                    { label: 'Moteur (stator)', value: 16 },
                    { label: 'Capteurs', value: 17 },
                    { label: 'Encodeur', value: 18 },
                    { label: 'Conclusion (1/2)', value: 19 },
                    { label: 'Conclusion (2/2)', value: 20 },
                ],
                eaForm: eaForm,
                affair: {
                },
                expertAssessment: {
                    id: null,
                    report: null,
                    completed: null
                },
                form: {
                    disciplineCurrent: null,
                    articleCurrent: null,
                    articleCurrentEstablishmentFilter: null
                },
                isNomenclatureEditable: false,
                isNomenclatureValidable: false,
                subContractingArticle: [],
                currentSubContractingArticle: null,
                establishments:[
                    { label: "EBC", value: "ebc" },
                    { label: "SMG", value: "smg" }
                ],
                articlesEstablishmentFilter: null,
                articlesFamily: null
            }
        },
        mounted() {
        },
        methods: {
            onNomenclatureFormInputChange(input) {
                if (input.error?.message !== null) {
                    this.nomenclatureFormErrors[input.name] = input;
                } else {
                    delete this.nomenclatureFormErrors[input.name];
                }
            },
            checkNomenclatureForm() {
                this.displayError = true;

                return Object.keys(this.nomenclatureFormErrors).length === 0;
            },
            loadExpertAssessment(affair) {
                this.affair = {
                    id: affair.id,
                    affairNumber: affair.affairNumber,
                    customer: affair.shipToCustomer,
                    spindle: affair.spindle,
                    messages: affair.messages,
                    currentNomenclature: affair.nomenclature.completed || affair.nomenclatureType == null ? JSON.parse(JSON.stringify(affair.nomenclature)) : JSON.parse(JSON.stringify(affair.nomenclatureType)),
                    nomenclature: affair.nomenclature
                };

                this.articlesFamily = affair.articlesFamily;

                const dropboxBrand = this.affair && this.affair.spindle?.spindleType?.spindleBrand?.name || 'other';
                const dropboxType = this.affair && this.affair.spindle?.spindleType?.name || 'other';
                const dropBoxSerialNumber = this.affair && this.affair.spindle?.serialNumber || 'other';
                const dropBoxCustomer = this.affair && this.affair.customer?.callKey || 'other';
                this.affair.dropboxPath = (`Historique_affaires/${dropboxBrand}/${dropboxType}/${dropBoxSerialNumber}/${this.affair.affairNumber}_${dropboxBrand}_${dropboxType}_${dropBoxSerialNumber}_${dropBoxCustomer}/Expertise/Photos`)?.replace(/ /g, '-');

                Object.assign(this.affair.currentNomenclature, {articlesGroupByFamily: affair.articlesGroupByFamily});

                this.subContractingArticle = affair.subContractingArticles;

                this.affair.currentNomenclature.id = affair.nomenclature.id;

                this.affair.currentNomenclature.disciplines = this.affair.currentNomenclature.disciplines.map((discipline) => {
                    return {
                        value: discipline.id,
                        label: discipline.label
                    }
                });

                this.prepareEditExpertAssessment(affair.expertAssessment);

                // La nomenclature est éditable uniquement si elle n'est pas complétée
                this.isNomenclatureEditable = this.$store.getters.hasRoleInList([accountRoleEnum.TECHNICIAN, accountRoleEnum.TECHNICIAN_MANAGER]) && this.affair.nomenclature !== null && (!this.affair.expertAssessment || !this.affair.expertAssessment.validated);

                // La nomenclature est validable uniquement pour le responsable agent
                this.isNomenclatureValidable = this.$store.getters.hasRoleInList([accountRoleEnum.TECHNICIAN_MANAGER, accountRoleEnum.ADMIN]);

                // On affiche le menu en fonction de l'état de l'expertise
                this.handleExpertAssessmentMenu();
            },
            prepareEditExpertAssessment(expertAssessment) {
                this.expertAssessment = JSON.parse(JSON.stringify(expertAssessment.report));
                for(let key of Object.keys(this.expertAssessment)) {
                    if(key === "bearingsCount") {
                        continue;
                    }

                    let formKey = key.lastIndexOf('_') !== -1 ? key.slice(0,key.lastIndexOf('_')) : key;
                    if(!eaForm.expertAssessmentFields[formKey] || (eaForm.expertAssessmentFields[formKey] && !eaForm.expertAssessmentFields[formKey].dynamic)) {
                        formKey = key;
                    }

                    if(eaForm.expertAssessmentFields[formKey]?.type === 'select') {
                        if(eaForm.expertAssessmentFields[key].multiple) {
                            let values = [];
                            for(let value of this.expertAssessment[key]) {
                                values.push({
                                    value: value,
                                    label: eaForm.expertAssessmentFields[formKey].defaultOptions[value]?.label.fr || value
                                });
                            }
                            this.expertAssessment[key] = values;
                        }
                        else {
                            this.expertAssessment[key] = {
                                value: this.expertAssessment[key],
                                label: eaForm.expertAssessmentFields[formKey].defaultOptions[this.expertAssessment[key]]?.label.fr || this.expertAssessment[key]
                            };
                        }
                    }
                }
                if(!this.expertAssessment["bearingsCount"]) {
                    this.expertAssessment["bearingsCount"] = 1;
                }
                this.expertAssessment.id = JSON.parse(JSON.stringify(expertAssessment.id));
                this.expertAssessment.completed = JSON.parse(JSON.stringify(expertAssessment.completed));
            },
            getWantedStep(wantedStep, increment) {
                let found = this.expertAssessmentMenu.find(expertAssessmentMenu => expertAssessmentMenu.value === wantedStep);

                while (!found) {
                    increment ? wantedStep++ : wantedStep--;
                    found = this.expertAssessmentMenu.find(expertAssessmentMenu => expertAssessmentMenu.value === wantedStep);
                }

                return wantedStep;
            },
            onExpertAssessmentModalPreviousStep(stepDetail) {
                this.$refs.expertAssessmentModalStep.showNextButton();

                if (!this.iAmTechnicianOnBrowser()){ // pas d'enregistrement quand on est agent sur PC
                    switch (stepDetail.currentStep) {
                        case MODAL_STEP_NOMENCLATURE:
                            // pas d'enregistrement quand on clique sur précédent à l'étape de nomenclature
                            //this.updateNomenclature();
                        break;
                        default:
                            this.updateExpertAssessment();
                        break;
                    }
                }

                let wantedStep = this.getWantedStep(stepDetail.wantedStep, false);

                this.$refs.expertAssessmentModalStep.previous(wantedStep, false);
                this.resetModalScroll();
            },
            onExpertAssessmentModalNextStep(stepDetail) {
                
                if(!this.iAmTechnicianOnBrowser()){ // pas d'enregistrement quand on est agent
                    switch (stepDetail.currentStep) {
                        case MODAL_STEP_NOMENCLATURE:
                            this.updateNomenclature();
                        break;
                        default:
                            this.updateExpertAssessment();
                        break;
                    }
                }

                let wantedStep = this.getWantedStep(stepDetail.wantedStep, true);

                if (this.expertAssessmentMenu[this.expertAssessmentMenu.length - 1].value === wantedStep) this.$refs.expertAssessmentModalStep.hideNextButton();

                switch (wantedStep) {
                    case (MODAL_STEP_NOMENCLATURE - 1):
                        if (!this.expertAssessment.completed || !this.$store.getters.hasRoleInList([accountRoleEnum.TECHNICIAN, accountRoleEnum.TECHNICIAN_MANAGER])) {
                            this.$refs.expertAssessmentModalStep.hideNextButton();
                        }
                        this.$refs.expertAssessmentModalStep.next(wantedStep, false);
                    break;
                    case MODAL_STEP_NOMENCLATURE: // Pour la nomenclature, on vérifie si l'utilisateur peut y accéder
                        if (this.expertAssessment.completed || this.$store.getters.isTechnician) {
                            this.$refs.expertAssessmentModalStep.next(wantedStep, false);
                        } else {
                            this.$refs.dialog.show({
                                type: 'alert',
                                title: 'Nomenclature',
                                message: `La nomenclature n'est disponible qu'une fois l'expertise complétée`,
                                okButton: 'OK',
                            });
                        }
                    break;
                    default:
                        this.$refs.expertAssessmentModalStep.next(wantedStep, false);
                    break;
                }

                this.resetModalScroll();
            },
            onSaveExpertAssessmentForm(stepDetail) {
                switch (stepDetail.currentStep) {
                    case MODAL_STEP_NOMENCLATURE:
                        this.updateNomenclature(true);
                    break;
                    default:
                        if (!this.iAmTechnicianOnBrowser()){
                            this.updateExpertAssessment(true);
                        }
                    break;
                }
            },
            onValidateExpertAssessmentForm() {
                if (this.expertAssessment.completed && (this.$store.getters.isTechnicianManager || this.$store.getters.isAdmin)) this.updateExpertAssessment(true, true);
            },
            addBearing() {
                this.expertAssessment.bearingsCount++;
            },
            deleteBearing(index) {
                for(let field of eaForm.bearingsBearingFields) {
                    delete this.expertAssessment[`${field.name}_${index}`];
                }
                for(let i=index+1; i<=this.expertAssessment.bearingsCount; i++) {
                    for(let field of eaForm.bearingsBearingFields) {
                        this.expertAssessment[`${field.name}_${i-1}`] = this.expertAssessment[`${field.name}_${i}`];
                        delete this.expertAssessment[`${field.name}_${i}`];
                    }
                }
                this.expertAssessment.bearingsCount--;
            },
            async formateExpertAssessment(expertAssessment, onCompleted = false, onValidated = false) {
                expertAssessment = JSON.parse(JSON.stringify(expertAssessment));

                let formData = new FormData();
                formData.append('id', expertAssessment.id);

                if (onCompleted && this.$refs.expertAssessmentModalStep.activeMenuItem.label === this.expertAssessmentMenu[this.expertAssessmentMenu.length - (this.$store.getters.isTechnician ? 2 : 1)]?.label) {
                    if (!expertAssessment.completed) {
                        formData.append('completed', true);
                    }
                }

                if (onValidated && (this.$store.getters.isTechnicianManager || this.$store.getters.isAdmin)) {
                    formData.append('completed', true);
                    formData.append('validated', true);
                }

                delete expertAssessment.id;
                delete expertAssessment.completed;
                delete expertAssessment.validated;

                for(let key of Object.keys(expertAssessment)) {
                    if(key === "bearingsCount") {
                        continue;
                    }

                    let formKey = key.lastIndexOf('_') !== -1 ? key.slice(0,key.lastIndexOf('_')) : key;
                    if(!eaForm.expertAssessmentFields[formKey] || (eaForm.expertAssessmentFields[formKey] && !eaForm.expertAssessmentFields[formKey].dynamic)) {
                        formKey = key;
                    }

                    if(expertAssessment[key] && eaForm.expertAssessmentFields[formKey]?.type === 'select') {
                        if(eaForm.expertAssessmentFields[key].multiple) {
                            let values = [];
                            for(let value of expertAssessment[key]) {
                                values.push(value.value);
                            }
                            expertAssessment[key] = values;
                        }
                        else {
                            expertAssessment[key] = expertAssessment[key].value;
                        }
                    }
                    else if(expertAssessment[key] && eaForm.expertAssessmentFields[formKey]?.type === 'photo') {
                        let files = [];
                        let filenames = [];
                        let currentFile = null;
                        for(let file of expertAssessment[key]) {
                            // si le fichier est en base64, on va l'envoyer à part dans le formData sous forme de fichier
                            if(file.startsWith('data:image/')) {
                               files.push(file);
                            }
                            // sinon, c'est un nom de fichier déjà existant et on va juste l'envoyer dans le json du rapport
                            else {
                                filenames.push(file);
                            }
                        }


                        await Promise.all(files.map(async (base64Image, index) => { formData.append(key, await dataUrlToFile(base64Image, `tmp-${index}`)); }));
                        expertAssessment[key] = filenames;
                    }
                    if(expertAssessment[key] == null || (typeof expertAssessment[key] === 'string' && expertAssessment[key]?.trim().length === 0) || (Array.isArray(expertAssessment[key]) && expertAssessment[key]?.length === 0)) {
                        delete expertAssessment[key];
                    }
                }

                formData.append('report', JSON.stringify(expertAssessment));

                return formData;
            },
            formateNomenclature(nomenclature) {
                nomenclature = JSON.parse(JSON.stringify(nomenclature));

                nomenclature.articles = [];
                if (!Array.isArray(nomenclature.articlesGroupByFamily)) {
                    Object.keys(nomenclature.articlesGroupByFamily).map((family) => {
                        Object.keys(nomenclature.articlesGroupByFamily[family].articles).map((article) => {
                            if (nomenclature.articles.find(art => art.id === nomenclature.articlesGroupByFamily[family].articles[article].id) == null) {
                                nomenclature.articles.push({
                                    id: nomenclature.articlesGroupByFamily[family].articles[article].value || nomenclature.articlesGroupByFamily[family].articles[article].id,
                                    label: nomenclature.articlesGroupByFamily[family].articles[article].label,
                                    quantity: parseFloat(nomenclature.articlesGroupByFamily[family].articles[article].quantity)
                                });
                            }
                        });
                    });
                } else {
                    nomenclature.articlesGroupByFamily.forEach((family) => {
                        Object.keys(family.articles).map((article) => {
                            if (nomenclature.articles.find(art => art.id === family.articles[article].id) == null) {
                                nomenclature.articles.push({
                                    id: family.articles[article].value || family.articles[article].id,
                                    label: family.articles[article].label,
                                    quantity: parseFloat(family.articles[article].quantity)
                                });
                            }
                        });
                    })
                }
                nomenclature.disciplines = nomenclature.disciplines.map((discipline) => discipline.value);
                delete nomenclature.articlesGroupByFamily;
                return nomenclature;
            },
            async updateExpertAssessment(onCompleted = false, onValidated = false) {
                if (this.iAmTechnicianOnBrowser()) return;
                axios
                .put('expertAssessment/update', await this.formateExpertAssessment(this.expertAssessment, onCompleted, onValidated), {
                    toastSuccessMessage: onCompleted && !onValidated ? `Expertise enregistrée` : onValidated ? 'Expertise validée' : null,
                    toastError: true,
                    showPreloader: true
                })
                .then((response) => {
                    let expertAssessmentUpdated = response.data;
                    this.$emit('refresh');

                    if (onCompleted) {

                        let newArticles = {
                            nomenclatureId: this.affair.currentNomenclature.id,
                            articles: []
                        }

                        for (let article of this.subContractingArticle) {
                            newArticles.articles.push({
                                id: article.value,
                                quantity: article.quantity,
                            });
                        }

                        axios
                        .put('nomenclature/addSubContractingArticles', newArticles)
                        .then((response) => {
                            this.refreshNomenclatureArticles();
                            this.$emit('refreshNomenclatureArticles')
                        });

                        if(this.$refs.expertAssessmentModalStep.activeMenuItem.label === this.expertAssessmentMenu[this.expertAssessmentMenu.length - (this.$store.getters.isTechnician ? 2 : 1)]?.label && !this.expertAssessment.completed && expertAssessmentUpdated.completed && this.$store.getters.hasRoleInList([accountRoleEnum.TECHNICIAN, accountRoleEnum.TECHNICIAN_MANAGER])) {

                            this.expertAssessment.completed = expertAssessmentUpdated.completed;
                            this.handleExpertAssessmentMenu();

                            // On affiche le menu en fonction de l'état de l'expertise
                            this.$nextTick(() => {
                                this.$refs.expertAssessmentModalStep.showNextButton();
                                this.$refs.expertAssessmentModalStep.next(null, false);

                                const spindleTypeId = this.affair.spindle.spindleType?.id != -1 ? this.affair.spindle.spindleType?.id : null;
                                const spindleBrandId = this.affair.spindle.spindleType.spindleBrand?.id != -1 ? this.affair.spindle.spindleType.spindleBrand?.id : null;

                                if (spindleTypeId && spindleBrandId) {
                                    axios
                                    .get(`nomenclatureType/get?spindleTypeId=${spindleTypeId}`)
                                    .then(async (result) => {
                                        if (result.data) {
                                            this.$refs.dialog.show({
                                                type: 'alert',
                                                title: 'Nomenclature',
                                                message: `À titre informatif, une nomenclature type existe déjà pour le couple ${this.affair.spindle.spindleType.spindleBrand.name} / ${this.affair.spindle.spindleType.name}.`
                                            });
                                        }
                                    });
                                }
                            });
                        }
                        else {
                            this.onExit();
                            this.$refs.expertAssessmentModalStep.exit();
                        }

                        this.resetModalScroll();
                    }

                    this.$emit('update:modelValue', expertAssessmentUpdated);
                    this.prepareEditExpertAssessment(expertAssessmentUpdated);
                })
                .catch(() => {
                });
            },
            updateNomenclature(onValidate = false) {
                if (!this.checkNomenclatureForm()) return;
                axios
                .put('nomenclature/update', this.formateNomenclature(this.affair.currentNomenclature), {
                    toastSuccessMessage: onValidate ? `Nomenclature enregistrée` : null,
                    toastError: true,
                    showPreloader: true
                })
                .then(() => {
                    this.$emit('refresh');

                    if (onValidate) {
                        this.onExit();
                        this.$refs.expertAssessmentModalStep.exit();
                    }
                })
                .catch(() => {});
            },
            onDisciplineAdd(discipline) {
                this.$nextTick(() => {
                    this.form.disciplineCurrent = null;
                });

                // On ajoute la discipline si elle n'est pas déjà présente
                if (discipline.value != null && this.affair.currentNomenclature.disciplines.filter(element => element.value === discipline.value.value).length === 0) {
                    this.affair.currentNomenclature.disciplines.push(discipline.value);
                }
            },
            onArticleAdd(article, incrementQuantity = true) {
                this.$nextTick(() => {
                    this.form.articleCurrent = null;
                    this.form.articleCurrentEstablishmentFilter = null;
                });

                // On ajoute l'article s'il n'est pas déjà présent
                let articleTab = this.affair.currentNomenclature.articlesGroupByFamily;
                if (article.value != null) {
                    let articleToAdd = {
                        //...article.value.data,
                        id: article.value.value,
                        label: article.value.label,
                        quantity: 1,
                        outOfNomenclatureType: true,
                        unit: article.value.data.unit,
                        family: article.value.data.articleFamily.label,
                        order: article.value.data.articleFamily.order
                    }
                    if (articleTab[articleToAdd.family] === undefined) {
                        articleTab[articleToAdd.family] = {
                            id: articleToAdd.value,
                            family: articleToAdd.family,
                            order: articleToAdd.order,
                            articles: [articleToAdd]
                        }
                    } else {
                        if (!articleTab[articleToAdd.family].articles.some(article => article.label === articleToAdd.label)) {
                            articleTab[articleToAdd.family].articles.push(articleToAdd);
                        }
                        else if (incrementQuantity) {
                            articleTab[articleToAdd.family].articles.find(article => article.label === articleToAdd.label).quantity++;
                        }
                    }
                }
                let finalResult = [];
                let count = 0;
                if (articleTab != null) {
                    for (let [key, value] of Object.entries(articleTab)) {
                        finalResult[count++] = value;
                    }
                }

                finalResult.sort(function(a, b) {
                    return a.order - b.order;
                })

                this.affair.currentNomenclature.articlesGroupByFamily = finalResult;
            },
            onSubContractingArticleAdd(article) {
                this.$nextTick(() => {
                    this.currentSubContractingArticle = null;
                });

                // On ajoute l'article s'il n'est pas déjà présent
                if (article.value != null) {
                    let articleToAdd = {
                        ...article.value,
                        id: article.value.value,
                        quantity: 1,
                        rewind: article.value.data.rewind,
                        externalMachining: article.value.data.externalMachining
                    }
                    this.subContractingArticle.push(articleToAdd);
                }
            },
            handleExpertAssessmentMenu() {
                let expertAssessmentMenu = [
                    { label: 'Informations générales', value: 1, types: ['ebc', 'kessler', 'precise', 'light'] },
                    { label: 'Contrôles pré-démontage', value: 2, types: ['ebc', 'kessler', 'precise'] },
                    { label: 'Arbre-rotor', value: 3, types: ['ebc', 'kessler'] },
                    { label: 'Arbre-rotor (Axe / Corps)', value: 4, types: ['kessler'] },
                    { label: 'Arbre-rotor (Points de mesures)', value: 5, types: ['precise', 'kessler'] },
                    { label: 'Arbre-rotor (Causes / Réparations)', value: 6, types: ['ebc'] },
                    { label: 'Roulements (Liste)', value: 7, types: ['ebc', 'kessler'] },
                    { label: 'Roulements (Entretoises / Canaux de graissage)', value: 8, types: ['ebc', 'kessler'] },
                    { label: 'Roulements (Logements)', value: 9, types: ['ebc', 'kessler'] },
                    { label: 'Roulements (Cartouches) (Precise)', value: 10, types: ['precise'] },
                    { label: 'Circuit de refroidissement', value: 11, types: ['ebc'] },
                    { label: 'Système de serrage et d\'arrosage', value: 12, types: ['ebc', 'kessler'] },
                    { label: 'Système de serrage et d\'arrosage (Kessler)', value: 13, types: ['kessler'] },
                    { label: 'Système de serrage et d\'arrosage (Kessler)', value: 14, types: ['precise', 'kessler'] },
                    { label: 'Système de serrage et d\'arrosage (Causes / Réparations)', value: 15, types: ['ebc'] },
                    { label: 'Moteur (stator)', value: 16, types: ['ebc', 'kessler', 'precise'] },
                    { label: 'Capteurs', value: 17, types: ['ebc', 'precise', 'kessler'] },
                    { label: 'Encodeur', value: 18, types: ['ebc', 'kessler', 'precise'] },
                    { label: 'Conclusion (1/2)', value: 19, types: ['ebc', 'light', 'kessler'] },
                    { label: 'Conclusion (2/2)', value: 20, types: ['ebc', 'light', 'kessler'] }
                ];

                this.expertAssessmentMenu = expertAssessmentMenu.filter(element => element.types?.includes(this.eaType.value));

                if (this.$store.getters.isTechnician) {
                    this.expertAssessmentMenu.push({
                        label: 'Nomenclature', value: MODAL_STEP_NOMENCLATURE
                    });
                }
                else if (this.expertAssessment.completed && this.$store.getters.hasRoleInList([accountRoleEnum.TECHNICIAN, accountRoleEnum.TECHNICIAN_MANAGER])) {
                    this.expertAssessmentMenu.push({
                        label: 'Nomenclature', value: MODAL_STEP_NOMENCLATURE
                    });
                }
            },
            resetModalScroll() {
                $('#expert-assessment-modal .modal-step-item-container.mode-menu').scrollTop(0);
            },
            onExit() {
                this.$emit('onExit');
                this.resetModalScroll();
                this.$refs.expertAssessmentModalStep.showNextButton();
            },
            openMessagesModal() {
                this.$emit('openMessagesModal');
            },
            refreshNomenclatureArticles() {
                axios
                    .get(`nomenclature/getArticles?nomenclatureId=${this.affair.nomenclature.id}&affairId=${this.affair.id}`)
                    .then((data) => {
                        this.affair.currentNomenclature.articlesGroupByFamily = data.data;
                    });
            },
            hasLabourArticle(articles) {
                if (!this.$store.getters.isTechnician) return true;

                let hideAll = true;
                for (let article of articles) {
                    hideAll = hideAll && (article.articleKindId === ArticleKindEnum.LABOUR)
                }
                if (hideAll) return false;
                return true;
            },
            notLabour(article) {
                return article.articleKindId !== ArticleKindEnum.LABOUR;
            },
            iAmTechnicianOnBrowser(){
                return (this.$store.getters.isTechnician && !this.$store.getters.isFromAppTablet);
            }
        }
    }
</script>