import fieldsParser from "../../fieldsParser"

export const _checklistGeometryFields = {
    checklist_geometry_postRunningInGeometryCheck: {
        label: {
            fr: "Contrôle de la géométrie post-rodage",
            en: "Post-running-in geometry check"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_geometry_cone: {
        label: {
            fr: "Cône (µ)",
            en: "Cone (µ)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_geometry_face: {
        label: {
            fr: "Face (µ)",
            en: "Face (µ)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_geometry_keelLength: {
        label: {
            fr: "Longueur quille (mm)",
            en: "Keel length (mm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_geometry_keelEndBeating: {
        label: {
            fr: "Battement bout de quille (µ)",
            en: "Keel end beating (µ)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_geometry_keelEdgeBeating: {
        label: {
            fr: "Battement bord de quille (µ)",
            en: "Keel edge beating (µ)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_geometry_axialSpindleStiffnessCheck: {
        label: {
            fr: "Contrôle rigidité de la broche axial",
            en: "Axial spindle stiffness check"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    checklist_geometry_axialSpindleStiffnessValue: {
        label: {
            fr: "Valeur rigidité de la broche axial (µ)",
            en: "Axial spindle stiffness value (µ)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        columnWeight: 2,
        keys: ["standard"],
        negative: false
    },
    checklist_geometry_radialSpindleStiffnessCheck: {
        label: {
            fr: "Contrôle rigidité de la broche radial",
            en: "Radial spindle stiffness check"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_geometry_radialSpindleStiffnessValue: {
        label: {
            fr: "Valeur rigidité de la broche radial (µ)",
            en: "Radial spindle stiffness value (µ)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        columnWeight: 2,
        keys: ["standard"],
        negative: false
    },
    checklist_geometry_hskNoseEjectionStroke: {
        label: {
            fr: "Contrôle de la course d'éjection du nez HSK / ISO et du blocage de ce dernier post rodage",
            en: "Control of the ejection stroke of the HSK / ISO nose and the blocking of the latter after running-in"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_geometry_hskNoseEjectionStrokeType: {
        label: {
            fr: "Type",
            en: "Type"
        },
		category: ['ebc'],
		type: "input",
        inputType: "text",
        keys: ["standard"]
    },
    checklist_geometry_hskNoseEjectionStrokeValue: {
        label: {
            fr: "Valeur (mm)",
            en: "Value (mm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_geometry_tractionForceControl: {
        label: {
            fr: "Contrôle de la force de traction",
            en: "Traction force control"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        columnWeight: 2,
        keys: ["standard"]
    },
    checklist_geometry_tractionForceControlValue: {
        label: {
            fr: "Valeur (kN)",
            en: "Value (kN)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_geometry_checkingAxialDisplacement: {
        label: {
            fr: "Contrôle du déplacement axial de l'arbre lors d'un cycle de changement d'outils",
            en: "Checking the axial displacement of the shaft during a tool change cycle"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        columnWeight: 2,
        keys: ["standard"]
    },
    checklist_geometry_checkingAxialDisplacementValue: {
        label: {
            fr: "Valeur (µ)",
            en: "Value (µ)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_geometry_tightnessChecks: {
        label: {
            fr: "Essais contrôle de l'étanchéité et vidange du circuit d'arrosage au centre",
            en: "Tightness checks and emptying of the sprinkler circuit in the center"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_geometry_comparatorSerialNumber: {
        label: {
            fr: "N° série comparateur",
            en: "Comparator serial number"
        },
        category: ['ebc'],
        type: "input"
    },
};

export const checkListGeometryFields = fieldsParser(_checklistGeometryFields);