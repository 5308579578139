/* INFORMATIONS GÉNÉRALES */
import { _generalInformationsFields } from "./generalInformations";
/* PRÉ-DÉMONTAGE */
import { _preDisassemblyFields } from "./preDisassembly";
/* ARBRE-ROTOR */
import { _rotorShaftFields } from "./rotorShaft/rotorShaft";
import { _rotorShaftSpindleAxisFields } from "./rotorShaft/spindleAxis";
import { _rotorShaftSpindleBodyFields } from "./rotorShaft/spindleBody";
import { _rotorShaftFaultsCausesFields } from "./rotorShaft/faultsCauses";
import { _rotorShaftRepairsFields } from "./rotorShaft/repairs";
import { _rotorShaftMeasurementsPointsRL1Fields } from "./rotorShaft/measurementsPoints/rl1";
import { _rotorShaftMeasurementsPointsRL2Fields } from "./rotorShaft/measurementsPoints/rl2";
import { _rotorShaftMeasurementsPointsRL3Fields } from "./rotorShaft/measurementsPoints/rl3";
import { _rotorShaftMeasurementsPointsPL1Fields } from "./rotorShaft/measurementsPoints/pl1";
import { _rotorShaftMeasurementsPointsPL2Fields } from "./rotorShaft/measurementsPoints/pl2";
import { _rotorShaftMeasurementsPointsPL3Fields } from "./rotorShaft/measurementsPoints/pl3";
/* ROULEMENTS */
import { _bearingsBearingFields } from "./bearings/bearing";
import { _bearingsStrutsFields } from "./bearings/strutsAndOilChannels/struts";
import { _bearingsOilChannelsFields } from "./bearings/strutsAndOilChannels/oilChannels";
import { _bearingsStrutsAndOilChannelsFaultsCausesFields } from "./bearings/strutsAndOilChannels/faultsCauses";
import { _bearingsCompartmentsFrontCaseFields } from "./bearings/compartments/frontCase";
import { _bearingsCompartmentsBackCaseFields } from "./bearings/compartments/backCase";
import { _bearingsCompartmentsAirCurtainAndLabyrinthFields } from "./bearings/compartments/airCurtainAndLabyrinth";
import { _bearingsCompartmentsRepairsFields } from "./bearings/compartments/repairs";
import { _bearingsCompartmentsPhotosFields } from "./bearings/compartments/photos";

import { _bearingsBackCartridgesFields } from "./bearings/cartridges/backCartridges";
import { _bearingsFrontCartridgesFields } from "./bearings/cartridges/frontCartridges";
/* CIRCUIT DE REFROIDISSEMENT */
import { _coolingSystemPhotosFields } from "./coolingSystem/photos";
import { _coolingSystemWaterMantleAndSheathFields } from "./coolingSystem/waterMantleAndSheath";
import { _coolingSystemFaultsCausesFields } from "./coolingSystem/faultsCauses";
import { _coolingSystemRepairsFields } from "./coolingSystem/repairs";
/* SYSTÈME DE SERRAGE ET D'ARROSAGE */
import { _tighteningAndDousingSystemFields } from "./tighteningAndDousingSystem/tighteningAndDousingSystem";
import { _tighteningAndDousingSystemKesslerManualTestsFields } from "./tighteningAndDousingSystem/kessler/manualTests";
import { _tighteningAndDousingSystemKesslerMechanicalTestsFields } from "./tighteningAndDousingSystem/kessler/mechanicalTests";
import { _tighteningAndDousingSystemKesslerTighteningConeFields } from "./tighteningAndDousingSystem/kessler/tighteningCone";
import { _tighteningAndDousingSystemKesslerControlKeelFields } from "./tighteningAndDousingSystem/kessler/controlKeel";
import { _tighteningAndDousingSystemPreciseFields } from "./tighteningAndDousingSystem/precise/precise";
import { _tighteningAndDousingSystemPreciseComponentsFields } from "./tighteningAndDousingSystem/precise/components";
import { _tighteningAndDousingSystemFaultsCausesFields } from "./tighteningAndDousingSystem/faultsCauses";
import { _tighteningAndDousingSystemRepairsFields } from "./tighteningAndDousingSystem/repairs";
/* MOTEUR (STATOR) */
import { _motorFields } from "./motor/motor";
import { _motorResistanceTestsFields } from "./motor/resistanceTests";
import { _motorTemperatureFields } from "./motor/temperature";
import { _motorFaultsCausesFields } from "./motor/faultsCauses";
import { _motorRepairsFields } from "./motor/repairs";
/* CAPTEURS */
import { _sensorsFields } from "./sensors/sensors";
import { _sensorsFaultsCausesFields } from "./sensors/faultsCauses";
import { _sensorsRepairsFields } from "./sensors/repairs";
import { _logicSensorsFields } from "./sensors/logicSensors";
/* ENCODEUR */
import { _encoderFields } from "./encoder/encoder";
import { _encoderRotaryEncoderReadingFields } from "./encoder/rotaryEncoderReading";
import { _encoderHeidenhainMeasurementsFields } from "./encoder/heidenhainMeasurements";
import { _encoderFaultsCausesFields } from "./encoder/faultsCauses";
import { _encoderRepairsFields } from "./encoder/repairs";
/* CONCLUSION */
import { _conclusionFaultsFields } from "./conclusion/faults";
import { _conclusionFaultsCausesFields } from "./conclusion/faultsCauses";
import { _conclusionReliabilityAdvicesFields } from "./conclusion/reliabilityAdvices";
import { _conclusionRepairsFields } from "./conclusion/repairs";
import { _conclusionSummaryFields } from "./conclusion/summary";

export const expertAssessmentFields = {
    /* INFORMATIONS GÉNÉRALES */
    ..._generalInformationsFields,
    /* PRÉ-DÉMONTAGE */
    ..._preDisassemblyFields,
    /* ARBRE-ROTOR */
    ..._rotorShaftFields,
    ..._rotorShaftSpindleAxisFields,
    ..._rotorShaftSpindleBodyFields,
    ..._rotorShaftFaultsCausesFields,
    ..._rotorShaftRepairsFields,
    ..._rotorShaftMeasurementsPointsPL1Fields,
    ..._rotorShaftMeasurementsPointsPL2Fields,
    ..._rotorShaftMeasurementsPointsPL3Fields,
    ..._rotorShaftMeasurementsPointsRL1Fields,
    ..._rotorShaftMeasurementsPointsRL2Fields,
    ..._rotorShaftMeasurementsPointsRL3Fields,
    /* ROULEMENTS */
    ..._bearingsBearingFields,
    ..._bearingsStrutsFields,
    ..._bearingsOilChannelsFields,
    ..._bearingsStrutsAndOilChannelsFaultsCausesFields,
    ..._bearingsCompartmentsFrontCaseFields,
    ..._bearingsCompartmentsBackCaseFields,
    ..._bearingsCompartmentsAirCurtainAndLabyrinthFields,
    ..._bearingsCompartmentsRepairsFields,
    ..._bearingsCompartmentsPhotosFields,
    ..._bearingsBackCartridgesFields,
    ..._bearingsFrontCartridgesFields,
    /* CIRCUIT DE REFROIDISSEMENT */
    ..._coolingSystemPhotosFields,
    ..._coolingSystemWaterMantleAndSheathFields,
    ..._coolingSystemFaultsCausesFields,
    ..._coolingSystemRepairsFields,
    /* SYSTÈME DE SERRAGE ET D'ARROSAGE */
    ..._tighteningAndDousingSystemFields,
    ..._tighteningAndDousingSystemKesslerManualTestsFields,
    ..._tighteningAndDousingSystemKesslerMechanicalTestsFields,
    ..._tighteningAndDousingSystemKesslerTighteningConeFields,
    ..._tighteningAndDousingSystemKesslerControlKeelFields,
    ..._tighteningAndDousingSystemPreciseFields,
    ..._tighteningAndDousingSystemPreciseComponentsFields,
    ..._tighteningAndDousingSystemFaultsCausesFields,
    ..._tighteningAndDousingSystemRepairsFields,
    /* MOTEUR (STATOR) */
    ..._motorFields,
    ..._motorResistanceTestsFields,
    ..._motorTemperatureFields,
    ..._motorFaultsCausesFields,
    ..._motorRepairsFields,
    /* CAPTEURS */
    ..._sensorsFields,
    ..._sensorsFaultsCausesFields,
    ..._sensorsRepairsFields,
    ..._logicSensorsFields,
    /* ENCODEUR */
    ..._encoderFields,
    ..._encoderRotaryEncoderReadingFields,
    ..._encoderHeidenhainMeasurementsFields,
    ..._encoderFaultsCausesFields,
    ..._encoderRepairsFields,
    /* CONCLUSION */
    ..._conclusionFaultsFields,
    ..._conclusionFaultsCausesFields,
    ..._conclusionReliabilityAdvicesFields,
    ..._conclusionRepairsFields,
    ..._conclusionSummaryFields,
};

/* INFORMATIONS GÉNÉRALES */
export { generalInformationsFields } from "./generalInformations";
/* PRÉ-DÉMONTAGE */
export { preDisassemblyFields } from "./preDisassembly";
/* ARBRE-ROTOR */
export { rotorShaftFields } from "./rotorShaft/rotorShaft";
export { rotorShaftSpindleAxisFields } from "./rotorShaft/spindleAxis";
export { rotorShaftSpindleBodyFields } from "./rotorShaft/spindleBody";
export { rotorShaftFaultsCausesFields } from "./rotorShaft/faultsCauses";
export { rotorShaftRepairsFields } from "./rotorShaft/repairs";
export { rotorShaftMeasurementsPointsRL1Fields } from "./rotorShaft/measurementsPoints/rl1";
export { rotorShaftMeasurementsPointsRL2Fields } from "./rotorShaft/measurementsPoints/rl2";
export { rotorShaftMeasurementsPointsRL3Fields } from "./rotorShaft/measurementsPoints/rl3";
export { rotorShaftMeasurementsPointsPL1Fields } from "./rotorShaft/measurementsPoints/pl1";
export { rotorShaftMeasurementsPointsPL2Fields } from "./rotorShaft/measurementsPoints/pl2";
export { rotorShaftMeasurementsPointsPL3Fields } from "./rotorShaft/measurementsPoints/pl3";
/* ROULEMENTS */
export { bearingsBearingFields } from "./bearings/bearing";
export { bearingsStrutsFields } from "./bearings/strutsAndOilChannels/struts";
export { bearingsOilChannelsFields } from "./bearings/strutsAndOilChannels/oilChannels";
export { bearingsStrutsAndOilChannelsFaultsCausesFields } from "./bearings/strutsAndOilChannels/faultsCauses";
export { bearingsCompartmentsPhotosFields } from "./bearings/compartments/photos";
export { bearingsCompartmentsFrontCaseFields } from "./bearings/compartments/frontCase";
export { bearingsCompartmentsBackCaseFields } from "./bearings/compartments/backCase";
export { bearingsCompartmentsAirCurtainAndLabyrinthFields } from "./bearings/compartments/airCurtainAndLabyrinth";
export { bearingsCompartmentsRepairsFields } from "./bearings/compartments/repairs";
export { bearingsBackCartridgesFields } from "./bearings/cartridges/backCartridges";
export { bearingsFrontCartridgesFields } from "./bearings/cartridges/frontCartridges";
/* CIRCUIT DE REFROIDISSEMENT */
export { coolingSystemPhotosFields } from "./coolingSystem/photos";
export { coolingSystemWaterMantleAndSheathFields } from "./coolingSystem/waterMantleAndSheath";
export { coolingSystemFaultsCausesFields } from "./coolingSystem/faultsCauses";
export { coolingSystemRepairsFields } from "./coolingSystem/repairs";
/* SYSTÈME DE SERRAGE ET D'ARROSAGE */
export { tighteningAndDousingSystemFields } from "./tighteningAndDousingSystem/tighteningAndDousingSystem";
export { tighteningAndDousingSystemKesslerManualTestsFields } from "./tighteningAndDousingSystem/kessler/manualTests";
export { tighteningAndDousingSystemKesslerMechanicalTestsFields } from "./tighteningAndDousingSystem/kessler/mechanicalTests";
export { tighteningAndDousingSystemKesslerTighteningConeFields } from "./tighteningAndDousingSystem/kessler/tighteningCone";
export { tighteningAndDousingSystemKesslerControlKeelFields } from "./tighteningAndDousingSystem/kessler/controlKeel";
export { tighteningAndDousingSystemPreciseFields } from "./tighteningAndDousingSystem/precise/precise";
export { tighteningAndDousingSystemPreciseComponentsFields } from "./tighteningAndDousingSystem/precise/components";
export { tighteningAndDousingSystemFaultsCausesFields } from "./tighteningAndDousingSystem/faultsCauses";
export { tighteningAndDousingSystemRepairsFields } from "./tighteningAndDousingSystem/repairs";
/* MOTEUR (STATOR) */
export { motorFields } from "./motor/motor";
export { motorResistanceTestsFields } from "./motor/resistanceTests";
export { motorTemperatureFields } from "./motor/temperature";
export { motorFaultsCausesFields } from "./motor/faultsCauses";
export { motorRepairsFields } from "./motor/repairs";
/* CAPTEURS */
export { sensorsFields } from "./sensors/sensors";
export { sensorsFaultsCausesFields } from "./sensors/faultsCauses";
export { sensorsRepairsFields } from "./sensors/repairs";
export { logicSensorsFields } from "./sensors/logicSensors";
/* ENCODEUR */
export { encoderFields } from "./encoder/encoder";
export { encoderRotaryEncoderReadingFields } from "./encoder/rotaryEncoderReading";
export { encoderHeidenhainMeasurementsFields } from "./encoder/heidenhainMeasurements";
export { encoderFaultsCausesFields } from "./encoder/faultsCauses";
export { encoderRepairsFields } from "./encoder/repairs";
/* CONCLUSION */
export { conclusionFaultsFields } from "./conclusion/faults";
export { conclusionFaultsCausesFields } from "./conclusion/faultsCauses";
export { conclusionReliabilityAdvicesFields } from "./conclusion/reliabilityAdvices";
export { conclusionRepairsFields } from "./conclusion/repairs";
export { conclusionSummaryFields } from "./conclusion/summary";