import fieldsParser from "../../fieldsParser";

export const _motorRepairsFields = {
    motor_repairs_cleaningSteaming: {
        label: {
            fr: "Nettoyage / Étuvage",
            en: "Cleaning / Steaming"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    motor_repairs_rewinding: {
        label: {
            fr: "Rebobinage",
            en: "Rewinding"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    motor_repairs_statorAndTemperatureProbesReplacement: {
        label: {
            fr: "Remplacement stator et sondes températures",
            en: "Stator and temperature probes replacement"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    motor_repairs_cablingAndIsolationReplacement: {
        label: {
            fr: "Remplacement câblage / isolement",
            en: "Cabling / isolation replacement"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
};

export const motorRepairsFields = fieldsParser(_motorRepairsFields);