import fieldsParser from "@/formFields/fieldsParser";

export const _lubrificationFields = {
    lubrifications_mano: {
        label: {
            fr: "Mano (bars)",
            en: "Mano (bars)"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        dynamic: true,
        keys: ["standard"]
    },
    lubrifications_airFlow: {
        label: {
            fr: "Débit air (l/min)",
            en: "Air flow (l/min)"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        dynamic: true,
        keys: ["standard"]
    }
}

export const lubrificationFields = fieldsParser(_lubrificationFields);