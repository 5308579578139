import fieldsParser from "../../fieldsParser";

export const _conclusionSummaryFields = {
    conclusion_summary_summary: {
        label: {
            fr: "Résumé",
            en: "Summary"
        },
		category: ['ebc', 'light', 'kessler'],
		type: "input",
        inputType: "textarea",
        columnWeight: 4
    },
    conclusion_summary_summaryPhotos: {
        label: {
            fr: "Photos associées au résumé",
            en: "Photos related to the summary"
        },
		category: ['ebc', 'light', 'kessler'],
		type: 'photo',
        directoryName: 'Conclusion',
        columnWeight: 2,
    },
};

export const conclusionSummaryFields = fieldsParser(_conclusionSummaryFields);