import fieldsParser from "../../fieldsParser";

export const _tighteningAndDousingSystemRepairsFields = {
    tighteningAndDousingSystem_repairs_strapChromePlatingRectification: {
        label: {
            fr: "Rectification chromage tirant",
            en: "Strap chrome plating rectification"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    tighteningAndDousingSystem_repairs_replacement: {
        label: {
            fr: "Remplacement pièces",
            en: "Replacement gears"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    tighteningAndDousingSystem_repairs_rotatingAndGrippingGasketsModification: {
        label: {
            fr: "Modification serreur + joint tournant",
            en: "Rotating and gripping gaskets modification"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
};

export const tighteningAndDousingSystemRepairsFields = fieldsParser(_tighteningAndDousingSystemRepairsFields);