import fieldsParser from "../../../fieldsParser";

export const _tighteningAndDousingSystemPreciseFields = {
    tighteningAndDousingSystem_precise_greasing: {
        label: {
            fr: "Graissage",
            en: "Greasing"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    tighteningAndDousingSystem_precise_cylinder: {
        label: {
            fr: "Cylindre",
            en: "Cylinder"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    tighteningAndDousingSystem_precise_hydraulic: {
        label: {
            fr: "Hydraulique",
            en: "Hydraulic"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    tighteningAndDousingSystem_precise_pneumatic: {
        label: {
            fr: "Pneumatique",
            en: "Pneumatic"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    tighteningAndDousingSystem_precise_wear: {
        label: {
            fr: "Usure",
            en: "Wear"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    tighteningAndDousingSystem_precise_gaskets: {
        label: {
            fr: "Joints",
            en: "Gaskets"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    tighteningAndDousingSystem_precise_mechanicalWear: {
        label: {
            fr: "Usure mécanique",
            en: "Mechanical wear"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    tighteningAndDousingSystem_precise_cylinderPossibleCauses: {
        label: {
            fr: "Causes possibles (Cylindre)",
            en: "Possible causes (Cylinder)"
        },
		category: ['precise'],
		type: "select",
        defaultOptions: {
            nad: { label: { fr: "R.A.S.", en: "N.A.D." } },
            wear: { label: { fr: "Usure", en: "Wear" } },
            vibration_machining: { label: { fr: "Vibration en usinage (fretting, trace)", en: "Vibration in machining (fretting, trace)" } },
            shock: { label: { fr: "Chocs", en: "Shocks" } },
            veiled_tie: { label: { fr: "Tirant voilé", en: "Veiled tie rod" } },
        },
        multiple: true
    },
    tighteningAndDousingSystem_precise_cylinderNecessaryMeasures: {
        label: {
            fr: "Mesures à prendre (Cylindre)",
            en: "Necessary measures (Cylinder)"
        },
		category: ['precise'],
		type: "select",
        defaultOptions: {
            nad: { label: { fr: "R.A.S.", en: "N.A.D." } },
            replacement_clamping_system: { label: { fr: "Remplacement du système de serrage", en: "Replacement of the clamping system" } },
            preventive_replacement_clamping_system: { label: { fr: "Remplacement du système de serrage préventif", en: "Preventive replacement of the clamping system" } },
            clamping_system_preserved: { label: { fr: "Système de serrage conservé après nettoyage et graissage", en: "Clamping system preserved after cleaning and lubrication" } },
        },
        multiple: true
    },
    tighteningAndDousingSystem_precise_rotatingGasket: {
        label: {
            fr: "Joint tournant",
            en: "Rotating gasket"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    tighteningAndDousingSystem_precise_rotatingGasketType: {
        label: {
            fr: "Type de joint tournant",
            en: "Rotating gasket type"
        },
		category: ['precise'],
		type: "select",
        defaultOptions: {
            jt_1_channel: { label: { fr: "J.T. 1 canal", en: "J.T. 1 channel" } },
            jt_with_mmks: { label: { fr: "J.T. avec MMKS", en: "J.T. with MMKS" } },
            jt_2_channel: { label: { fr: "J.T. 2 canaux", en: "J.T. 2 channel" } },
        }
    },
    tighteningAndDousingSystem_precise_rotatingGasketSerialNumber: {
        label: {
            fr: "Numéro de série du joint tournant",
            en: "Rotating gasket serial number"
        },
		category: ['precise'],
		type: "input"
    },
    tighteningAndDousingSystem_precise_bearingOutOfOrder: {
        label: {
            fr: "Roulement HS",
            en: "Bearing out of order"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    tighteningAndDousingSystem_precise_leak: {
        label: {
            fr: "Fuite",
            en: "Leak"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    tighteningAndDousingSystem_precise_dirtyLinings: {
        label: {
            fr: "Garnitures sales",
            en: "Dirty linings"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    tighteningAndDousingSystem_precise_rotatingGasketPossibleCauses: {
        label: {
            fr: "Causes possibles (Joint tournant)",
            en: "Possible causes (Rotating gasket)"
        },
		category: ['precise'],
		type: "input"
    },
    tighteningAndDousingSystem_precise_rotatingGasketNecessaryMeasures: {
        label: {
            fr: "Mesures à prendre (Joint tournant)",
            en: "Necessary measures (Rotating gasket)"
        },
		category: ['precise'],
		type: "input"
    },
};

export const tighteningAndDousingSystemPreciseFields = fieldsParser(_tighteningAndDousingSystemPreciseFields);