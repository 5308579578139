import fieldsParser from "../../fieldsParser";

export const _rotorShaftFields = {
    rotorShaft_voileFace: {
        label: {
            fr: "Voile face",
            en: "Voile face"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    rotorShaft_voileFaceValue: {
        label: {
            fr: "Voile face (µm)",
            en: "Voile face (µm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number"
    },
    rotorShaft_battementCone: {
        label: {
            fr: "Battement cône",
            en: "Battement cône"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    rotorShaft_battementConeValue: {
        label: {
            fr: "Battement cône (µm)",
            en: "Battement cône (µm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number"
    },
    rotorShaft_battementPorteeAV: {
        label: {
            fr: "Battement portée AV",
            en: "Battement portée AV"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    rotorShaft_battementPorteeAVValue: {
        label: {
            fr: "Battement portée AV (µm)",
            en: "Battement portée AV (µm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number"
    },
    rotorShaft_battementPorteeAR: {
        label: {
            fr: "Battement portée AR",
            en: "Battement portée AR"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    rotorShaft_battementPorteeARValue: {
        label: {
            fr: "Battement portée AR (µm)",
            en: "Battement portée AR (µm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number"
    },
    rotorShaft_battementPorteeIntermediaire: {
        label: {
            fr: "Battement portée intermédiaire",
            en: "Battement portée intermédiaire"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    rotorShaft_battementPorteeIntermediaireValue: {
        label: {
            fr: "Battement portée intermédiaire (µm)",
            en: "Battement portée intermédiaire (µm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number"
    },
    rotorShaft_frontOutsideDiameter: {
        label: {
            fr: "Diamètre extérieur avant",
            en: "Front outside diameter"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    rotorShaft_frontOutsideDiameterValue: {
        label: {
            fr: "Diamètre extérieur avant (mm)",
            en: "Front outside diameter (mm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number"
    },
    rotorShaft_battementFaceAppuiRoulementsAvant: {
        label: {
            fr: "Battement face d'appui roulements avant",
            en: "Battement face d'appui roulements avant"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    rotorShaft_battementFaceAppuiRoulementsAvantValue: {
        label: {
            fr: "Battement face d'appui roulements avant (µm)",
            en: "Battement face d'appui roulements avant (µm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number"
    },
    rotorShaft_backOutsideDiameter: {
        label: {
            fr: "Diamètre extérieur arrière",
            en: "Back outside diameter"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    rotorShaft_backOutsideDiameterValue: {
        label: {
            fr: "Diamètre extérieur arrière (mm)",
            en: "Back outside diameter (mm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number"
    },
    rotorShaft_battementFaceAppuiRoulementsArriere: {
        label: {
            fr: "Battement face d'appui roulements arrière",
            en: "Battement face d'appui roulements arrière"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    rotorShaft_battementFaceAppuiRoulementsArriereValue: {
        label: {
            fr: "Battement face d'appui roulements arrière (µm)",
            en: "Battement face d'appui roulements arrière (µm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number"
    },
    rotorShaft_coneState: {
        label: {
            fr: "État du cône/face",
            en: "Cone state"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    rotorShaft_coneStateValue: {
        label: {
            fr: "État du cône/face",
            en: "Cone state"
        },
        category: ['ebc'],
		type: "input",
        inputType: "text",
    },
    rotorShaft_shaftBoring: {
        label: {
            fr: "Alésage arbre",
            en: "Shaft boring"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    rotorShaft_shaftBoringValue: {
        label: {
            fr: "Alésage arbre",
            en: "Shaft boring"
        },
		category: ['ebc'],
		type: "input"
    },
    rotorShaft_tenonsState: {
        label: {
            fr: "État des tenons",
            en: "Tenons state"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    rotorShaft_tenonStateSelect: {
        label: {
            fr: "État des tenons",
            en: "Tenons state"
        },
        category: ['ebc'],
        type: "select",
        defaultOptions: {
            reparable: { label: { fr: "Réparable", en: "Reparable" } },
            irreparable: { label: { fr: "Irréparable", en: "Irreparable" } },
        }
    },
    rotorShaft_frontNutState: {
        label: {
            fr: "État de l'écrou avant",
            en: "Front nut state"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    rotorShaft_frontNutStateValue: {
        label: {
            fr: "État de l'écrou avant",
            en: "Front nut state"
        },
		category: ['ebc'],
		type: "select",
        defaultOptions: {
            crack: { label: { fr: "Fissuré", en: "Crack" } },
            deformed: { label: { fr: "Déformé", en: "Deformed" } },
            overheated: { label: { fr: "Surchauffe", en: "Overheated" } },
        }
    },
    rotorShaft_outsideFrontThreadState: {
        label: {
            fr: "État filet(s) avant sur l'arbre",
            en: "Outside front thread(s) state"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    rotorShaft_outsideFrontThreadStateValue: {
        label: {
            fr: "État filet(s) avant sur l'arbre",
            en: "Outside front thread(s) state"
        },
        category: ['ebc'],
        type: "select",
        defaultOptions: {
            reparable: { label: { fr: "Réparable", en: "Reparable" } },
            irreparable: { label: { fr: "Irréparable", en: "Irreparable" } },
        }
    },
    rotorShaft_backNutState: {
        label: {
            fr: "État de l'écrou arrière",
            en: "Back nut state"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    rotorShaft_backNutStateValue: {
        label: {
            fr: "État de l'écrou arrière",
            en: "Back nut state"
        },
		category: ['ebc'],
		type: "select",
        defaultOptions: {
            crack: { label: { fr: "Fissuré", en: "Crack" } },
            deformed: { label: { fr: "Déformé", en: "Deformed" } },
            overheated: { label: { fr: "Surchauffe", en: "Overheated" } },
        }
    },
    rotorShaft_outsideBackThreadState: {
        label: {
            fr: "État filet(s) arrière sur l'arbre",
            en: "Outside back thread(s) state"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    rotorShaft_outsideBackThreadStateValue: {
        label: {
            fr: "État filet(s) arrière sur l'arbre",
            en: "Outside back thread(s) state"
        },
        category: ['ebc'],
        type: "select",
        defaultOptions: {
            reparable: { label: { fr: "Réparable", en: "Reparable" } },
            irreparable: { label: { fr: "Irréparable", en: "Irreparable" } },
        }
    },
    rotorShaft_rotorGlobalState: {
        label: {
            fr: "État général du rotor",
            en: "Rotor global state"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    rotorShaft_rotorFailureCause: {
        label: {
            fr: "Rotor",
            en: "Rotor"
        },
		category: ['ebc'],
		type: "input",
        inputType: "text"
    },
    rotorShaft_insideBackThreadState: {
        label: {
            fr: "État du filet arrière intérieur de l'arbre",
            en: "Inside back thread state"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    rotorShaft_insideBackThreadStateValue: {
        label: {
            fr: "État du filet arrière intérieur de l'arbre",
            en: "Outside back thread state"
        },
        category: ['ebc'],
        type: "select",
        defaultOptions: {
            reparable: { label: { fr: "Réparable", en: "Reparable" } },
            irreparable: { label: { fr: "Irréparable", en: "Irreparable" } },
        }
    },
    rotorShaft_rotorShaftPhotos: {
        label: {
            fr: "Photos cône",
            en: "Cone photos"
        },
		category: ['ebc', 'kessler'],
		type: 'photo',
        directoryName: 'Arbre-rotor',
        columnWeight: 1,
    },
    rotorShaft_rotorShaftPhotos_2: {
        label: {
            fr: "Photos arbre",
            en: "Shaft photos"
        },
        category: ['ebc'],
        type: 'photo',
        directoryName: 'Arbre-rotor',
        columnWeight: 1,
    },
    rotorShaft_rotorShaftPhotos_3: {
        label: {
            fr: "Photos portée avant",
            en: "Front range photos"
        },
        category: ['ebc'],
        type: 'photo',
        directoryName: 'Arbre-rotor',
        columnWeight: 1,
    },
    rotorShaft_rotorShaftPhotos_4: {
        label: {
            fr: "Photos portée arrière",
            en: "Rear range photos"
        },
        category: ['ebc'],
        type: 'photo',
        directoryName: 'Arbre-rotor',
        columnWeight: 1,
    },
    rotorShaft_rotorShaftPhotos_5: {
        label: {
            fr: "Photos rotor",
            en: "Rotor photos"
        },
        category: ['ebc'],
        type: 'photo',
        directoryName: 'Arbre-rotor',
        columnWeight: 1,
    },
    rotorShaft_rotorShaftPhotos_6: {
        label: {
            fr: "Photo courbe rotor",
            en: "Curve rotor photos"
        },
        category: ['ebc'],
        type: 'photo',
        directoryName: 'Arbre-rotor',
        columnWeight: 1,
    },
    rotorShaft_remarks: {
        label: {
            fr: "Remarques",
            en: "Remarks"
        },
        category: ['ebc'],
        type: "input",
        inputType: "textarea",
        columnWeight: 2
    },
    rotorShaft_shaftState: {
        label: {
            fr: "État de l'arbre",
            en: "Shaft state"
        },
        category: ['kessler'],
        type: "input"
    }
};

export const rotorShaftFields = fieldsParser(_rotorShaftFields);