import fieldsParser from "../../../fieldsParser";

export const _rotorShaftMeasurementsPointsRL1Fields = {
    rotorShaft_measurementsPoints_RL1_value: {
        label: {
            fr: "Valeur",
            en: "Value"
        },
		category: [/*'precise', */'kessler'],
		type: "input",
        inputType: "number",
        medium: true
    },
    rotorShaft_measurementsPoints_RL1_visualFault: {
        label: {
            fr: "Défaut visuel",
            en: "Visual fault"
        },
		category: [/*'precise', */'kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        medium: true
    },
    rotorShaft_measurementsPoints_RL1_pressurePoints: {
        label: {
            fr: "Points de pression",
            en: "Pressure points"
        },
		category: [/*'precise', */'kessler'],
		type: "input",
        inputType: "number",
        medium: true
    },
    rotorShaft_measurementsPoints_RL1_angleConcentricity: {
        label: {
            fr: "Concentricité à l'angle",
            en: "Angle concentricity"
        },
		category: [/*'precise', */'kessler'],
		type: "input",
        inputType: "number",
        medium: true
    },
    rotorShaft_measurementsPoints_RL1_comparatorNumber: {
        label: {
            fr: "Numéro de comparateur",
            en: "Comparator number"
        },
		category: [/*'precise', */'kessler'],
		type: "input",
        inputType: "number",
        medium: true
    },
};

export const rotorShaftMeasurementsPointsRL1Fields = fieldsParser(_rotorShaftMeasurementsPointsRL1Fields);