import fieldsParser from "../../fieldsParser"
import accountRoleEnum from "../../../enums/accountRoleEnum";

export const _checklistCheckBeforeDeliveryFields = {
    checklist_checkBeforeDelivery_checkAssemblyOrientationPlug: {
        label: {
            fr: "Contrôle montage, orientation des raccords et prises comme à l'origine",
            en: "Check assembly, orientation of connections and plugs as at the origin"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_checkBeforeDelivery_blockingVarnish: {
        label: {
            fr: "Pose de vernis de blocage sur les raccords et prises à ne pas démonter",
            en: "Application of blocking varnish on fittings and sockets that must not be dismantled"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_checkBeforeDelivery_checkingTightnessScrewNut: {
        label: {
            fr: "Contrôle du serrage des vis apparentes et écrous",
            en: "Checking the tightness of visible screws and nuts"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_checkBeforeDelivery_checkingStateContactRigidityConnector: {
        label: {
            fr: "Contrôle de l'état des contacts, de leur rigidité sur les connecteurs",
            en: "Checking the state of the contacts and their rigidity on the connectors"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_checkBeforeDelivery_generalSpindleCleanliness: {
        label: {
            fr: "Propreté générale de la broche (copeaux, gras, traces de colle, repérage)",
            en: "General cleanliness of the spindle (chips, grease, traces of glue, marking)"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },

    checklist_checkBeforeDelivery_installationOfPlugOnConnectorAndFitting: {
        label: {
            fr: "Pose de bouchons sur connecteurs et raccords",
            en: "Installation of plugs on connectors and fittings"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_checkBeforeDelivery_takePictureSpindleNoise: {
        label: {
            fr: "Prendre une photo du nez de broche",
            en: "Take a picture of the spindle noise"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        columnWeight: 3,
        keys: ["standard"]
    },
    checklist_checkBeforeDelivery_spindlePhoto: {
        label: {
            fr: "Photo de l'ensemble de la broche",
            en: "Photo of the whole spindle"
        },
		category: ['ebc'],
		type: 'photo',
        directoryName: 'Ensemble_broche',
        keys: ["standard"]
    },
    checklist_checkBeforeDelivery_spindlePhotoNose: {
        label: {
            fr: "Photo du nez de la broche",
            en: "Photo of the spindle nose"
        },
		category: ['ebc'],
		type: 'photo',
        directoryName: 'Nez_broche',
        keys: ["standard"]
    },
    checklist_checkBeforeDelivery_otherPhoto: {
        label: {
            fr: "Autre photo",
            en: "Other photo"
        },
		category: ['ebc'],
		type: 'photo',
        directoryName: 'Autre_photo_broche',
        keys: ["standard"]
    },
    checklist_checkBeforeDelivery_serialNumber: {
        label: {
            fr: "Numéro de série inscrit sur la broche",
            en: "Serial number on the spindle"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_checkBeforeDelivery_customerDocument: {
        label: {
            fr: "Documents et / ou fournitures spécifiques du client retournés avec la broche",
            en: "Customer documents or supplies returned with the spindle"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_checkBeforeDelivery_greasyFilm: {
        label: {
            fr: "Film gras pour éviter l'oxydation de la broche",
            en: "Greasy film to prevent oxidation of the spindle"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_checkBeforeDelivery_wrappedSpindleDesiccantBag: {
        label: {
            fr: "Broche emballée et sachet déshydratant",
            en: "Wrapped spindle and desiccant bag"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_checkBeforeDelivery_ebcLabel: {
        label: {
            fr: "Étiquette EBC",
            en: "EBC label"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_checkBeforeDelivery_spindleCaseCleaned: {
        label: {
            fr: "Caisse de broche nettoyée et en parfait état",
            en: "Spindle case cleaned and in perfect condition"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_checkBeforeDelivery_spindleWedgedAndProtected: {
        label: {
            fr: "Broche parfaitement calée et protégée dans sa caisse",
            en: "Spindle perfectly wedged and protected in his case"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_checkBeforeDelivery_conformityReportCompleted: {
        label: {
            fr: "PV de conformité complet",
            en: "Conformity report completed"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_checkBeforeDelivery_perfectlySealedCase: {
        label: {
            fr: "Caisse parfaitement scellée",
            en: "Perfectly sealed case"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_checkBeforeDelivery_cleaningAndStorageTestBench: {
        label: {
            fr: "Nettoyage et rangement banc d'essai",
            en: "Cleaning and storage test bench"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_checkBeforeDelivery_exemptionRequestMade: {
        label: {
            fr: "Demande de dérogation faite",
            en: "Exemption request made"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_checkBeforeDelivery_customerAgreement: {
        label: {
            fr: "Accord client",
            en: "Customer agreement"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    conclusion_guarantee_observations: {
        label: {
            fr: "Observations",
            en: "Observations"
        },
		category: ['ebc'],
		type: "input",
        inputType: "textarea",
        columnWeight: 3,
        keys: ["standard"]
    },
    checklist_checkBeforeDelivery_date: {
        label: {
            fr: "Date",
            en: "Date"
        },
        type: "date",
        category: ['ebc'],
    },
    checklist_checkBeforeDelivery_technician: {
        label: {
            fr: "Technicien",
            en: "Technician"
        },
		category: ['ebc'],
		type: "select",
        api: 'account/search',
        apiParams: {
            role: accountRoleEnum.TECHNICIAN
        },
        fieldLabel: 'name',
        fieldValue: 'id',
        keys: ["standard"]
    },
    checklist_checkBeforeDelivery_controller: {
        label: {
            fr: "Contrôleur",
            en: "Controller"
        },
		category: ['ebc'],
		type: "select",
        api: 'account/search',
        apiParams: {
            role: accountRoleEnum.TECHNICIAN
        },
        fieldLabel: 'name',
        fieldValue: 'id',
        keys: ["standard"]
    },
};

export const checkListCheckBeforeDeliveryFields = fieldsParser(_checklistCheckBeforeDeliveryFields);