import fieldsParser from "../../fieldsParser";

export const _conclusionReliabilityAdvicesFields = {
    conclusion_reliabilityAdvices_avoidCollisions: {
        label: {
            fr: "Éviter les chocs/collisions",
            en: "Avoid impacts/collisions"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_reliabilityAdvices_reduceCuttingForces: {
        label: {
            fr: "Réduire les efforts de coupe",
            en: "Reduce cutting forces"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_reliabilityAdvices_doPeriodicMaintenance: {
        label: {
            fr: "Effectuer maintenance périodique",
            en: "Do periodic maintenance"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_reliabilityAdvices_checkToolHolder: {
        label: {
            fr: "Contrôler les porte-outils",
            en: "Check tool holders"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_reliabilityAdvices_replaceCuttingFluidFilters: {
        label: {
            fr: "Remplacer filtres liquide de coupe pour préserver le joint tournant",
            en: "Replace cutting fluid filters to preserve the rotating gasket"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_reliabilityAdvices_lubricatePliers: {
        label: {
            fr: "Graisser la pince tous les 15 jours avec graisse Métaflux en bombe",
            en: "Lubricate pliers every 15 days with Metaflux spray grease"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_reliabilityAdvices_ensureCorrectReassemblyOfTheSpindle: {
        label: {
            fr: "Assurer un remontage correct de la broche dans la machine",
            en: "Ensure correct reassembly of the spindle in the machine"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_reliabilityAdvices_checkLubricationSystem: {
        label: {
            fr: "Contrôler le système de lubrification",
            en: "Check lubrication system"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_reliabilityAdvices_checkAirQuality: {
        label: {
            fr: "Contrôler la qualité de l'air (filtration, séchage)",
            en: "Check air quality (filtration, drying)"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
};

export const conclusionReliabilityAdvicesFields = fieldsParser(_conclusionReliabilityAdvicesFields);