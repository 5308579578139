import fieldsParser from "../../fieldsParser"

export const _conformityReportAnnexFields = {
    conformityReport_annex_photoDynamicBalancingTestBench: {
        label: {
            fr: "Photo du relevé d'équilibrage dynamique sur banc d'essai",
            en: "Photo of the dynamic balancing reading on a test bench"
        },
		category: ['ebc'],
		type: 'photo',
        directoryName: 'Releve_equilibrage_dynamique',
        keys: ["standard"]
    },
    conformityReport_annex_photoAnnex: {
        label: {
            fr: "Annexe",
            en: "Annex"
        },
		category: ['ebc'],
		type: 'photo',
        directoryName: 'Annexe',
        keys: ["standard"]
    },
};

export const conformityReportAnnexFields = fieldsParser(_conformityReportAnnexFields);