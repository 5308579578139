import fieldsParser from "../../fieldsParser";

export const _rotorShaftFaultsCausesFields = {
    rotorShaft_faultsCauses_collision: {
        label: {
            fr: "Collision",
            en: "Collision"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } }
        }
    },
    rotorShaft_faultsCauses_machiningEfforts: {
        label: {
            fr: "Efforts d'usinage",
            en: "Machining efforts"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } }
        }
    },
    rotorShaft_faultsCauses_wear: {
        label: {
            fr: "Usure",
            en: "Wear"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } }
        }
    },
    rotorShaft_faultsCauses_pollution: {
        label: {
            fr: "Pollution",
            en: "Pollution"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } }
        }
    },
    rotorShaft_faultsCauses_badToolHolderUse: {
        label: {
            fr: "Utilisation de mauvais porte-outil",
            en: "Bad tool holder use"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } }
        }
    },
    rotorShaft_faultsCauses_vibrations: {
        label: {
            fr: "Vibrations",
            en: "Vibrations"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } }
        }
    },
    rotorShaft_faultsCauses_bearingsJamming: {
        label: {
            fr: "Détérioration des roulements",
            en: "Bearings jamming"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } }
        }
    },
    rotorShaft_faultsCauses_overloadOverintensity: {
        label: {
            fr: "Surcharge / Surintensité",
            en: "Overload / Overintensity"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } }
        }
    },
    rotorShaft_faultsCauses_lastRepairsNonCompliant: {
        label: {
            fr: "Réparation précédente non conforme",
            en: "Last repairs non-compliant"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } }
        }
    },
};

export const rotorShaftFaultsCausesFields = fieldsParser(_rotorShaftFaultsCausesFields);