import fieldsParser from "../../fieldsParser"

export const _checklistGeneralInformationsFields = {
    checklist_generalInformations_spindleBenchPhoto: {
        label: {
            fr: "Photo de l'ensemble de la broche sur banc",
            en: "Photo of spindle on bench"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_generalInformations_spindleBenchPhotos: {
        label: {
            fr: "Photos de la broche sur banc",
            en: "Spindle photos on bench"
        },
		category: ['ebc'],
		type: 'photo',
        directoryName: 'Broche_sur_banc',
        columnWeight: 2,
        keys: ["standard"]
    },
    checklist_generalInformations_brakeSealingNutScrew: {
        label: {
            fr: "Présence de produit de freinage / étanchéité sur écrou et vis",
            en: "Presence of brake / sealing product on nut and screw"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_generalInformations_tighteningScrewsThreadLock: {
        label: {
            fr: "Serrage des vis d'équilibrage au frein filet 243",
            en: "Tightening the balancing screws with thread lock 243"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_generalInformations_teflonIntravisSpindle: {
        label: {
            fr: "Mise en place d'intravis en téflon à l'avant de la broche",
            en: "Placement of teflon intravis at the front of the spindle"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_generalInformations_testBenchUsed: {
        label: {
            fr: "Banc d'essais utilisé",
            en: "Tests bench used"
        },
        category: ['ebc'],
        type: "select",
        defaultOptions: {
            bench_1: { label: { fr: "Banc N°1 Siemens 840D POWERLINE", en: "Bench N°1 Siemens 840D POWERLINE" } },
            bench_2: { label: { fr: "Banc N°2 Fanuc", en: "Bench N°2 Fanuc" } },
            bench_3: { label: { fr: "Banc N°3 a courroie", en: "Bench N°3 with belt" } },
            bench_4: { label: { fr: "Banc N°4 Siemens 611D", en: "Bench N°4 Siemens 611D" } },
            bench_5: { label: { fr: "Banc petites broches", en: "Bench small spindles" } },
        },
        keys: ["standard"]
    },
    checklist_generalInformations_angularSpindlePosition: {
        label: {
            fr: "Position broche angulaire",
            en: "Angular spindle position"
        },
		category: ['ebc'],
        type: "select",
        defaultOptions: {
            zero: { label: { fr: "0", en: "0" } },
            horizontal: { label: { fr: "Horizontal", en: "Horizontal" } },
            vertical: { label: { fr: "Vertical", en: "Vertical" } },
            less90: { label: { fr: "-90", en: "-90" } },
            plus90: { label: { fr: "+90", en: "+90" } }
        },
        keys: ["standard"]
    },
    checklist_checkBeforeDelivery_exemptionRequestMade: {
        label: {
            fr: "Demande de dérogation faite",
            en: "Exemption request made"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_checkBeforeDelivery_customerAgreement: {
        label: {
            fr: "Accord client",
            en: "Customer agreement"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
};

export const checkListGeneralInformationsFields = fieldsParser(_checklistGeneralInformationsFields);