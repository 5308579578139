<!-- TODO: cacher certains boutons selon le statut et/ou la disponibilité de la fiche ou du rapport -->
<style>
    .badge-messages {
        position: absolute;
        background-color: #DD0000;
        font-size: 11px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        right: -5px;
        bottom: 0px;
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>

<template>
    <div class="container-action-button">
        <BaseButton @click="onEditExpertAssessmentReport" buttonText="" :title="`${affairStatusEnum.PENDING_EXPERT_ASSESSMENT === params?.data?.status ? 'Créer' : 'Compléter'} le rapport d'expertise`" buttonClass="orange-button" v-if="params?.data?.status !== affairStatusEnum.PENDING_ADMIN_VALIDATION && params?.data?.status !== affairStatusEnum.PENDING_EXPERT_ASSESSMENT_PLANNING">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'file-check']" />
            </template>
        </BaseButton>
        <BaseButton @click="onEditConformityReport" buttonText="" :title="`${affairStatusEnum.PENDING_REPAIRS === params?.data?.status ? 'Créer' : 'Compléter'} le PV de conformité`" buttonClass="blue-button" v-if="[affairStatusEnum.BILLED, affairStatusEnum.TO_BILL, affairStatusEnum.TO_SHIP, affairStatusEnum.PENDING_TESTS, affairStatusEnum.ONGOING_TESTS, affairStatusEnum.PENDING_REPAIRS, affairStatusEnum.ONGOING_REPAIRS].includes(params?.data?.status)">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'file-check']" />
            </template>
        </BaseButton>
        <BaseButton @click="onDownloadExpertAssessmentPDF" buttonText="" title="Télécharger le rapport d'expertise" buttonClass="dark-blue-button" v-if="params?.data?.expertAssessment?.validated">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'file-download']" />
            </template>
        </BaseButton>
        <BaseButton @click="onOpenDocumentation" buttonText="" title="Voir la documentation" buttonClass="blue-button" v-if="params?.data?.status !== affairStatusEnum.PENDING_ADMIN_VALIDATION && !params.context.componentParent.$store.getters.isTechnicianManager">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'search']" />
            </template>
        </BaseButton>
        <BaseButton @click="onOpenMessages" buttonText="" title="Accéder au fil de discussion" buttonClass="green-button">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'comment-check']" />
            </template>
            <template v-slot:badge v-if="params?.data?.newMessages > 0">
                <div class="badge-messages">{{ params?.data?.newMessages }}</div>
            </template>
        </BaseButton>
    </div>
</template>

<script>
    import BaseButton from '../../components/Base/BaseButton.vue';
    import * as Vue from "vue";
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
    import affairStatusEnum from "../../enums/affairStatusEnum";

    export default {
        name: 'ListTabletAffairsActionButtonsRenderer',
        components: {
            BaseButton: Vue.shallowRef(BaseButton),
            FontAwesomeIcon: Vue.shallowRef(FontAwesomeIcon)
        },
        data() {
            return {
                affairStatusEnum
            }
        },
        beforeMount() {

        },
        methods: {
            onEditExpertAssessmentReport() {
                this.params.context.componentParent.openExpertAssessmentModal(this.params?.data?.id);
            },
            onEditConformityReport() {
                this.params.context.componentParent.openConformityReportModal(this.params?.data?.id);
            },
            onOpenDocumentation() {
                this.params.context.componentParent.openDocumentation(this.params?.data?.nomenclature?.technicalDocumentationLink);
            },
            onOpenMessages() {
                this.params.context.componentParent.getMessagesAndOpenModal(this.params?.data?.id);
            },
            onDownloadExpertAssessmentPDF() {
                this.params.context.componentParent.displayExpertAssessmentTypeModalAccordingToContext(this.params?.data?.id, 'download');
            }
        }
    }        
</script>