import fieldsParser from "@/formFields/fieldsParser"

export const _photoFields = {
    photo: {
        label: {
        },
        category: ['ebc'],
        type: "photo",
        directoryName: 'Annexe_dynamique',
        limit: 1,
        dynamic: true,
        keys: ["standard"]
    },
    photo_comment: {
        label: {
            fr: "Commentaire",
            en: "Comment"
        },
        category: ['ebc'],
        type: "input",
        inputType: "textarea",
        dynamic: true,
        keys: ["standard"]
    }
}

export const photoFields = fieldsParser(_photoFields)