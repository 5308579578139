<style scoped>
    .two-column-div {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .three-column-div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .four-column-div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .table-row {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-shrink: 2;
        margin-top: 15px;
        border: 1px solid rgb(220, 225, 230);
    }

    .table-row-header {
        display: flex;
        flex-direction: row;
        padding: 40px 0 0 0;
        width: 100%;
    }

    .table-row form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        padding: 60px 5px 15px 5px;
    }

    .table-row :deep(.inputeo) {
        margin: 3px 0;
    }

    .container-list {
        width: calc(100% - 40px);
        margin: 0 20px;
        table-layout: fixed;
        border-collapse: collapse;
    }
    .container-list td, .container-list th {
        width: 100%;
    }

    .inside-container-list form {
        padding: 40px 10px 0px 5px;
        border: 1px solid rgb(220, 225, 230);
        margin: 10px
    }

    .inside-container-list :deep(form) {
        margin-top: 30px;
    }

    .inside-container-list :deep(.checkbox-button-container) {
        margin-bottom: 13px;
    }

    .inside-container-list > div {
        position: relative;
        min-height: 215px;
        max-height: 400px;
    }

    .delete-air-oil-lubrification-button, .delete-check-in-button, .delete-photo-with-comment-button {
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 1;
        background-color: #FFF !important;
        color: #646e78 !important;
        border: 1px solid #646e78 !important;
    }

    .delete-balancing-analysis-button {
        background-color: #FFF !important;
        color: #646e78 !important;
        border: 1px solid #646e78 !important;
    }

    .add-check-in-button-div {
        display: flex;
        justify-content: center;
        height: 75px;
    }
    .add-check-in-button {
        background-color: rgb(220, 225, 230);
        width: calc(100% - 40px);
        margin: 10px 20px;
        color: #646e78 !important;
        font-size: 40px !important;
    }

    .add-air-oil-lubrification-button-div, .add-photo-with-comment-button-div {
        display: flex;
        justify-content: center;
    }

    .add-air-oil-lubrification-button, .add-photo-with-comment-button {
        background-color: #FFFFFF;
        color: #646e78 !important;
        font-size: 80px !important;
    }

    .add-air-oil-lubrification-button svg, .add-check-in-button svg {
        margin: auto;
    }

    .add-balancing-analysis-div {
        display: flex;
        justify-content: center;
        height: 60px;
    }

    .add-balancing-analysis {
        background-color: rgb(220, 225, 230);
        width: 100%;
        color: #646e78 !important;
        font-size: 30px !important;
    }

    .custom-form-title {
        background-color: #0d3b70;
        color: white;
        height: 32px;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 13px;
        position: relative;
        margin-top: 20px;
    }

    .custom-form-title:after {
        position: absolute;
        right: -20px;
        top: 0;
        content: '';
        width: 0;
        height: 0;
        border-top: 0 solid transparent;
        border-bottom: 32px solid transparent;
        border-left: 20px solid #0d3b70;
    }

    .item-title-header {
        flex: 1;
        text-align: center;
        font-weight: 600;
        color: rgb(50, 60, 70);
    }

    .item-title {
        position: absolute;
        top: 20px;
        left: 30px;
        font-weight: 600;
        color: rgb(50, 60, 70);
    }


    .balancing-analysis-container {
        display: flex;
        flex-direction: column;
        margin: 15px 25px;
        width: calc(100% - 50px);
        justify-content: center;
    }

    .balancing-analysis-table {
        table-layout: fixed;
        border-collapse: collapse;
        margin: auto;
    }

    .balancing-analysis-row {
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }

    .balancing-analysis-table td form {
        display: flex;
        flex-direction: row;
        padding: 2px;
    }

    .balancing-analysis-table td form :deep(.inputeo) {
        margin: 0;
    }

    #intervention-report-modal :deep(.inputeo textarea) {
        min-height: 100px;
    }

    @media (max-width: 1200px) {
        .modal :deep(.button-text) {
            font-size: 10px;
        }

        .modal-bottom-title {
            font-size: 10px;
        }
    }
</style>

<template>
    <div>
        <Modal id="intervention-report-modal" mode="step" :fullscreen="true">
            <template v-slot:modalStep>
                <ModalStep
                    ref="interventionReportModalStep"
                    step="1"
                    @onExit="onExit"
                    @onPreviousStep="onInterventionReportModalPreviousStep"
                    @onNextStep="onInterventionReportModalNextStep"
                    @onValidate="onSaveInterventionReportForm"
                    validate-text-button="Enregistrer"
                    validate-icon-button="save"
                    :auto-previous="false"
                    :auto-next="false"
                    mode="menu"
                    :menu="interventionReportMenu"
                >
                    <template v-slot:modalStepMenuExtraButtons>

                    </template>
                    <template v-slot:modalStepMenuTitle>
                        <div class="modal-bottom-title">
                            <div>Rapport d'intervention</div>
                            <div class="interventionReportTitle">{{ `${affair.affairNumber} ${affair.spindle ? `${affair.spindle?.spindleType.spindleBrand.name}` : ''}` }}</div>
                        </div>
                    </template>
                    <template v-slot:modalStepItem>
                        <!-- INFORMATIONS GÉNÉRALES -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="interventionReport"
                                    :fields="itForm.generalInformationsFields"
                                    :fields-per-row="2"
                                ></CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- DÉPOSE BROCHE -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="interventionReport"
                                    :fields="itForm.spindleRemovalFields"
                                    :fields-per-row="2"
                                ></CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- REPOSE BROCHE (1/2) -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="interventionReport"
                                    :fields="itForm.spindleRestFields"
                                    :fields-per-row="2"
                                ></CustomForm>

                                <div class="custom-form-title">Mise en service</div>
                                <CustomForm
                                    v-model="interventionReport"
                                    :fields="itForm.spindleRestCommissioning"
                                    :fields-per-row="2"
                                ></CustomForm>

                                <div class="custom-form-title">Points de graissage</div>
                                <div class="four-column-div inside-container-list">
                                    <div v-for='index in interventionReport.airOilLubrificationsCount' :key="index">
                                        <div class="item-title">Point de graissage {{ index }}</div>
                                        <BaseButton @click="function() { deleteAirOilLubrification(index) }" buttonText="" title="Supprimer le graissage air / huile" class="delete-air-oil-lubrification-button">
                                            <template v-slot:iconBefore>
                                                <font-awesome-icon :icon="['fas', 'times']" />
                                            </template>
                                        </BaseButton>
                                        <CustomForm
                                            v-model="interventionReport"
                                            :fields="itForm.lubrificationFields"
                                            :fieldsPerRow="1"
                                            :index="index"
                                            checkboxColor="grey"
                                            :keys="interventionReportKeys"
                                        >
                                        </CustomForm>
                                    </div>
                                    <div class="add-air-oil-lubrification-button-div">
                                        <BaseButton @click="addAirOilLubrification" buttonText="" title="Ajouter un graissage air / huile" class="add-air-oil-lubrification-button">
                                            <template v-slot:iconBefore>
                                                <font-awesome-icon :icon="['fal', 'plus']" />
                                            </template>
                                        </BaseButton>
                                    </div>
                                </div>
                            </template>
                        </ModalStepItem>
                        <!-- REPOSE BROCHE (2/2) -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <div class="custom-form-title">Contrôle géométriques</div>
                                <CustomForm
                                    v-model="interventionReport"
                                    :fields="itForm.spindleRestGeometry"
                                    :fields-per-row="2"
                                ></CustomForm>

                                <div class="custom-form-title">Quille adaptée</div>
                                <CustomForm
                                    v-model="interventionReport"
                                    :fields="itForm.spindleRestKeel"
                                    :fields-per-row="2"
                                ></CustomForm>

                                <div class="custom-form-title">Observations</div>
                                <CustomForm
                                    v-model="interventionReport"
                                    :fields="itForm.observationFields"
                                    :fields-per-row="1"
                                ></CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- POINTAGE -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <table class="container-list">
                                    <thead>
                                        <tr class="table-row-header">
                                            <th class="item-title-header">Pointage</th>
                                            <th class="item-title-header">Heures aller</th>
                                            <th class="item-title-header">Heures travail</th>
                                            <th class="item-title-header">Pause repas</th>
                                            <th class="item-title-header">Heures retour</th>
                                            <th class="item-title-header">Frais</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="table-row" v-for='index in interventionReport.checkInCount' :key="index">
                                            <BaseButton @click="function() { deleteCheckInRow(index) }" buttonText="" title="Supprimer la ligne" class="delete-check-in-button">
                                                <template v-slot:iconBefore>
                                                    <font-awesome-icon :icon="['fas', 'times']" />
                                                </template>
                                            </BaseButton>
                                            <td>
                                                <CustomForm
                                                    v-model="interventionReport"
                                                    :fields="itForm.reportTableCheckInFields"
                                                    :fieldsPerRow="2"
                                                    :index="index"
                                                    checkboxColor="grey"
                                                    :keys="interventionReportKeys"
                                                ></CustomForm>
                                            </td>
                                            <td>
                                                <CustomForm
                                                    v-model="interventionReport"
                                                    :fields="itForm.reportTableGoToFields"
                                                    :fieldsPerRow="1"
                                                    :index="index"
                                                    checkboxColor="grey"
                                                    :keys="interventionReportKeys"
                                                ></CustomForm>
                                            </td>
                                            <td>
                                                <CustomForm
                                                    v-model="interventionReport"
                                                    :fields="itForm.reportTableWorkHourFields"
                                                    :fieldsPerRow="1"
                                                    :index="index"
                                                    checkboxColor="grey"
                                                    :keys="interventionReportKeys"
                                                ></CustomForm>
                                            </td>
                                            <td>
                                                <CustomForm
                                                    v-model="interventionReport"
                                                    :fields="itForm.reportTableWorkMealFields"
                                                    :fieldsPerRow="1"
                                                    :index="index"
                                                    checkboxColor="grey"
                                                    :keys="interventionReportKeys"
                                                ></CustomForm>
                                            </td>
                                            <td>
                                                <CustomForm
                                                    v-model="interventionReport"
                                                    :fields="itForm.reportTableReturnFields"
                                                    :fieldsPerRow="1"
                                                    :index="index"
                                                    checkboxColor="grey"
                                                    :keys="interventionReportKeys"
                                                ></CustomForm>
                                            </td>
                                            <td>
                                                <CustomForm
                                                    v-model="interventionReport"
                                                    :fields="itForm.reportTableFeesFields"
                                                    :fieldsPerRow="1"
                                                    :index="index"
                                                    checkboxColor="grey"
                                                    :keys="interventionReportKeys"
                                                ></CustomForm>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="add-check-in-button-div">
                                    <BaseButton @click="addCheckInRow" buttonText="" title="Ajouter une ligne" class="add-check-in-button">
                                        <template v-slot:iconBefore>
                                            <font-awesome-icon :icon="['fal', 'plus']" />
                                        </template>
                                    </BaseButton>
                                </div>
                            </template>
                        </ModalStepItem>
                        <!-- SIGNATURES -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="interventionReport"
                                    :fields="itForm.signaturesFields"
                                    :fields-per-row="2"
                                ></CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- ANALYSE D'ÉQUILIBRAGE -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <div class="balancing-analysis-container">
                                    <div v-for="table in Math.floor(interventionReport.balancingAnalysisCount / 10)+1"><br>

                                        <table class="balancing-analysis-table">
                                            <tr v-for='index in ((interventionReport.balancingAnalysisCount - (10 * (table - 1))) < 10 ? (interventionReport.balancingAnalysisCount - (10 * (table - 1))) : 10)' :key="index">
                                                <td>
                                                    <div class="balancing-analysis-row">
                                                        <CustomForm
                                                            v-model="interventionReport"
                                                            :fields="itForm.balancingAnalysisFields"
                                                            :fields-per-row="3"
                                                            :index="index + (table > 1 ? (table - 1) * 10 : 0)"
                                                            checkboxColor="grey"
                                                            :keys="interventionReportKeys"
                                                        ></CustomForm>
                                                        <BaseButton @click="function() { deleteBalancingAnalysisRow(index) }" buttonText="" title="Supprimer la ligne" class="delete-balancing-analysis-button">
                                                            <template v-slot:iconBefore>
                                                                <font-awesome-icon :icon="['fas', 'times']" />
                                                            </template>
                                                        </BaseButton>
                                                    </div>

                                                    <div v-if="index === (interventionReport.balancingAnalysisCount - (10 * (table - 1))) || (index + (table > 1 ? (table - 1) * 10 : 0) === 10 && interventionReport.balancingAnalysisCount <= 10)" class="add-balancing-analysis-div">
                                                        <BaseButton @click="addBalancingAnalysisRow" buttonText="" title="Ajouter une ligne" class="add-balancing-analysis">
                                                            <template v-slot:iconBefore>
                                                                <font-awesome-icon :icon="['fal', 'plus']" />
                                                            </template>
                                                        </BaseButton>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table><br>
                                    </div>
                                </div>
                            </template>
                        </ModalStepItem>
                        <!-- RELEVÉS SUR SITE -->
                        <ModalStepItem>
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="interventionReport"
                                    :fields="itForm.onSiteSurveysFields"
                                    :fields-per-row="2"
                                ></CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- PHOTO -->
                        <ModalStepItem>
                            <template v-slot:modalContent>

                                <div class="two-column-div inside-container-list">
                                    <div v-for='index in interventionReport.photoWithCommentCount' :key="index">
                                        <div class="item-title">Photo {{ index }}</div>
                                        <BaseButton @click="function() { deletePhotoWithComment(index) }" buttonText="" title="Supprimer la photo" class="delete-photo-with-comment-button">
                                            <template v-slot:iconBefore>
                                                <font-awesome-icon :icon="['fas', 'times']" />
                                            </template>
                                        </BaseButton>
                                        <CustomForm
                                            v-model="interventionReport"
                                            :fields="itForm.photoFields"
                                            :index="index"
                                            checkboxColor="grey"
                                            :fields-per-row="1"
                                            :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                            :keys="interventionReportKeys"
                                        ></CustomForm>
                                    </div>
                                    <div class="add-photo-with-comment-button-div">
                                        <BaseButton @click="addPhotoWithComment" buttonText="" title="Ajouter une photo" class="add-photo-with-comment-button">
                                            <template v-slot:iconBefore>
                                                <font-awesome-icon :icon="['fal', 'plus']" />
                                            </template>
                                        </BaseButton>
                                    </div>
                                </div>

                            </template>
                        </ModalStepItem>
                        <!-- ANNEXE -->
                        <ModalStepItem>
                            <template v-slot:modalContent>

                            </template>
                        </ModalStepItem>
                    </template>
                </ModalStep>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from "@/components/App/Modal";
import ModalStep from "@/components/App/ModalStep";
import config from "@/config";
import ModalStepItem from "@/components/App/ModalStepItem";
import $ from "jquery";
import CustomForm from "@/components/App/CustomForm";
import * as itForm from '@/formFields/interventionReport'
import BaseButton from "@/components/Base/BaseButton";
import axios from "axios";
import accountRoleEnum from "@/enums/accountRoleEnum";
import * as eaForm from "@/formFields/expertAssessment";
import {dataUrlToFile} from "@/utils/dataUrlToFile";


export default {
    name: "InterventionReportModal",
    components: {BaseButton, CustomForm, ModalStepItem, ModalStep, Modal},
    emits: ['onExit', 'refresh', 'update:modelValue'],
    props: {
        modelValue: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            config,
            displayError: false,
            interventionReportMenu: [
                { label: 'Informations générales', value: 1 },
                { label: 'Dépose broche', value: 2 },
                { label: 'Repose broche (1/2)', value: 3 },
                { label: 'Repose broche (2/2)', value: 4 },
                { label: 'Pointage', value: 5 },
                { label: 'Signatures', value: 6 },
                { label: 'Analyse d\'équilibrage', value: 7 },
                { label: 'Relevés sur site', value: 8},
                { label: 'Photos', value: 9 },
                { label: 'Annexes', value: 10 }
            ],
            affair: {
            },
            itForm: itForm,
            interventionReport: {
                id: null,
                report: null,
                completed: null,
                airOilLubrificationsCount: 1,
                checkInCount: 1,
                balancingAnalysisCount: 1,
                photoWithCommentCount: 1
            },
            interventionReportKeys: [],
            step: "1"
        }
    },
    methods: {
        loadInterventionReport(affair) {
            this.affair = {
                id: affair.id,
                affairNumber: affair.affairNumber,
                customer: affair.shipToCustomer,
                spindle: affair.spindle
            };

            const dropboxBrand = this.affair && this.affair.spindle?.spindleType?.spindleBrand?.name || 'other';
            const dropboxType = this.affair && this.affair.spindle?.spindleType?.name || 'other';
            const dropBoxSerialNumber = this.affair && this.affair.spindle?.serialNumber || 'other';
            const dropBoxCustomer = this.affair && this.affair.customer?.callKey || 'other';
            this.affair.dropboxPath = (`Historique_affaires/${dropboxBrand}/${dropboxType}/${dropBoxSerialNumber}/${this.affair.affairNumber}_${dropboxBrand}_${dropboxType}_${dropBoxSerialNumber}_${dropBoxCustomer}/Intervention/Photos`)?.replace(/ /g, '-');

            this.prepareEditInterventionReport(affair.interventionReport)

            this.interventionReportKeys = ['standard']
        },
        prepareEditInterventionReport(interventionReport) {
            this.interventionReport = JSON.parse(JSON.stringify(interventionReport.report));

            for (let key of Object.keys(this.interventionReport)) {
                if (key === "airOilLubrificationsCount" || key === "checkInCount" || key === "balancingAnalysisCount" || key === "photoWithCommentCount")
                    continue;

                let formKey = key.lastIndexOf('_') !== -1 ? key.slice(0,key.lastIndexOf('_')) : key;
                if(!itForm.interventionReportFields[formKey] || (itForm.interventionReportFields[formKey] && !itForm.interventionReportFields[formKey].dynamic)) {
                    formKey = key;
                }

                if(itForm.interventionReportFields[formKey]?.type === 'select') {
                    if(itForm.interventionReportFields[key].multiple) {
                        let values = [];
                        for(let value of this.interventionReport[key]) {
                            values.push({
                                value: value,
                                label: itForm.interventionReportFields[formKey].defaultOptions[value]?.label.fr || value
                            });
                        }
                        this.interventionReport[key] = values;
                    }
                    else {
                        this.interventionReport[key] = {
                            value: this.interventionReport[key],
                            label: itForm.interventionReportFields[formKey].defaultOptions[this.interventionReport[key]]?.label.fr || this.interventionReport[key]
                        };
                    }
                }

            }
            if (!this.interventionReport["airOilLubrificationsCount"]) this.interventionReport["airOilLubrificationsCount"] = 1
            if (!this.interventionReport["checkInCount"]) this.interventionReport["checkInCount"] = 1
            if (!this.interventionReport["balancingAnalysisCount"]) this.interventionReport["balancingAnalysisCount"] = 1
            if (!this.interventionReport["photoWithCommentCount"]) this.interventionReport["photoWithCommentCount"] = 1

            this.interventionReport.id = JSON.parse(JSON.stringify(interventionReport.id))
        },
        onInterventionReportModalPreviousStep(stepDetail) {
            this.updateInterventionReport(true);
            this.$refs.interventionReportModalStep.showNextButton();

            this.$refs.interventionReportModalStep.previous(stepDetail.wantedStep, false);
            this.resetModalScroll();
        },
        onInterventionReportModalNextStep(stepDetail) {
            this.updateInterventionReport(true);
            this.$refs.interventionReportModalStep.next(stepDetail.wantedStep, false);
            this.resetModalScroll();
        },
        onSaveInterventionReportForm(stepDetail) {
            this.updateInterventionReport();
        },
        async formateInterventionReport(interventionReport) {
            interventionReport = JSON.parse(JSON.stringify(interventionReport))

            let formData = new FormData();
            formData.append('id', interventionReport.id);

            delete interventionReport.id;

            for (let key of Object.keys(interventionReport)) {
                if (key === "airOilLubrificationsCount" || key === "checkInCount" || key === "balancingAnalysisCount" || key === "photoWithCommentCount")
                    continue;

                let formKey = key.lastIndexOf('_') !== -1 ? key.slice(0, key.lastIndexOf('_')) : key;
                if(!itForm.interventionReportFields[formKey] || (itForm.interventionReportFields[formKey] && !itForm.interventionReportFields[formKey].dynamic)) {
                    formKey = key;
                }

                if(interventionReport[key] && itForm.interventionReportFields[formKey].type === 'select') {
                    if(itForm.interventionReportFields[key].multiple) {
                        let values = [];
                        for(let value of interventionReport[key]) {
                            values.push(value.value);
                        }
                        interventionReport[key] = values;
                    }
                    else {
                        interventionReport[key] = interventionReport[key].value;
                    }
                }
                else if(interventionReport[key] && itForm.interventionReportFields[formKey].type === 'photo') {
                    let files = [];
                    let filenames = [];
                    for(let file of interventionReport[key]) {
                        // si le fichier est en base64, on va l'envoyer à part dans le formData sous forme de fichier
                        if(file.startsWith('data:image/')) {
                            files.push(file);
                        }
                        // sinon, c'est un nom de fichier déjà existant et on va juste l'envoyer dans le json du rapport
                        else {
                            filenames.push(file);
                        }
                    }
                    await Promise.all(files.map(async (base64Image, index) => { formData.append(key, await dataUrlToFile(base64Image, `tmp-${index}.png`)); }));
                    interventionReport[key] = filenames;
                }
                if(interventionReport[key] == null || (typeof interventionReport[key] === 'string' && interventionReport[key]?.trim().length === 0) || (Array.isArray(interventionReport[key]) && interventionReport[key]?.length === 0)) {
                    delete interventionReport[key];
                }
            }
            formData.append('report', JSON.stringify(interventionReport))

            return formData;
        },
        async updateInterventionReport(silentMode = false) {
            axios.put('interventionReport/update', await this.formateInterventionReport(this.interventionReport), {
                toastSuccessMessage: !silentMode ? `Intervention enregistrée` : null,
                toastError: true,
                showPreloader: true
            })
            .then((response) => {
                let interventionReportUpdated = response.data;
                this.$emit('refresh');

                this.$emit('update:modelValue', interventionReportUpdated);
                this.prepareEditInterventionReport(interventionReportUpdated);
                if (!silentMode) this.onExit();
            })
        },
        onExit() {
            this.$emit('onExit');
            this.resetModalScroll();
            this.$refs.interventionReportModalStep.showNextButton();
        },
        resetModalScroll() {
            $('#intervention-report-modal .modal-step-item-container.mode-menu').scrollTop(0);
        },
        addAirOilLubrification() {
            this.interventionReport.airOilLubrificationsCount++;
        },
        deleteAirOilLubrification(index) {
            for(let field of this.itForm.lubrificationFields) {
                delete this.interventionReport[`${field.name}_${index}`];
            }
            for(let i=index+1; i<=this.interventionReport.airOilLubrificationsCount; i++) {
                for(let field of this.itForm.lubrificationFields) {
                    this.interventionReport[`${field.name}_${i-1}`] = this.interventionReport[`${field.name}_${i}`];
                    delete this.interventionReport[`${field.name}_${i}`];
                }
            }
            this.interventionReport.airOilLubrificationsCount--;
        },
        addCheckInRow() {
            this.interventionReport.checkInCount++;
        },
        deleteCheckInRow(index) {
            for(let field of this.itForm.reportTableFields) {
                delete this.interventionReport[`${field.name}_${index}`];
            }
            for(let i=index+1; i<=this.interventionReport.checkInCount; i++) {
                for(let field of this.itForm.reportTableFields) {
                    this.interventionReport[`${field.name}_${i-1}`] = this.interventionReport[`${field.name}_${i}`];
                    delete this.interventionReport[`${field.name}_${i}`];
                }
            }
            this.interventionReport.checkInCount--;
        },
        addBalancingAnalysisRow() {
            this.interventionReport.balancingAnalysisCount++;
        },
        deleteBalancingAnalysisRow(index) {
            for(let field of this.itForm.balancingAnalysisFields) {
                delete this.interventionReport[`${field.name}_${index}`];
            }
            for(let i=index+1; i<=this.interventionReport.balancingAnalysisCount; i++) {
                for(let field of this.itForm.balancingAnalysisFields) {
                    this.interventionReport[`${field.name}_${i-1}`] = this.interventionReport[`${field.name}_${i}`];
                    delete this.interventionReport[`${field.name}_${i}`];
                }
            }
            this.interventionReport.balancingAnalysisCount--;
        },
        addPhotoWithComment() {
            this.interventionReport.photoWithCommentCount++;
        },
        deletePhotoWithComment(index) {
            for(let field of this.itForm.photoFields) {
                delete this.interventionReport[`${field.name}_${index}`];
            }
            for(let i=index+1; i<=this.interventionReport.photoWithCommentCount; i++) {
                for(let field of this.itForm.photoFields) {
                    this.interventionReport[`${field.name}_${i-1}`] = this.interventionReport[`${field.name}_${i}`];
                    delete this.interventionReport[`${field.name}_${i}`];
                }
            }
            this.interventionReport.photoWithCommentCount--;
        }
    }
}
</script>