import fieldsParser from "@/formFields/fieldsParser"

export const _reportTableCheckInFields = {
    reportTable_date: {
        label: {
            fr: "Date",
            en: "Date"
        },
        category: ['ebc'],
        type: "date",
        dynamic: true,
        keys: ["standard"],
    },
    reportTable_techNumber: {
        label: {
            fr: "Nombre tech",
            en: "Number of technicians"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        dynamic: true,
        keys: ["standard"]
    }
}

export const reportTableCheckInFields = fieldsParser(_reportTableCheckInFields)