import fieldsParser from "../../fieldsParser";

export const _encoderHeidenhainMeasurementsFields = {
    encoder_heidenhainMeasurements_ratedSignalA: {
        label: {
            fr: "Signal A nominal",
            en: "Rated signal A"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    encoder_heidenhainMeasurements_measuredSignalA: {
        label: {
            fr: "Signal A mesuré",
            en: "Measured signal A"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    encoder_heidenhainMeasurements_ratedSignalB: {
        label: {
            fr: "Signal B nominal",
            en: "Rated signal B"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    encoder_heidenhainMeasurements_measuredSignalB: {
        label: {
            fr: "Signal B mesuré",
            en: "Measured signal B"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    encoder_heidenhainMeasurements_ratedPhaseError: {
        label: {
            fr: "Erreur de phase nominale",
            en: "Rated phase error"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    encoder_heidenhainMeasurements_measuredPhaseError: {
        label: {
            fr: "Erreur de phase mesurée",
            en: "Measured phase error"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    encoder_heidenhainMeasurements_ratedAmplitudeFault: {
        label: {
            fr: "Défaut d'amplitude nominal",
            en: "Rated amplitude fault"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    encoder_heidenhainMeasurements_measuredAmplitudeFault: {
        label: {
            fr: "Défaut d'amplitude mesuré",
            en: "Measured amplitude fault"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    encoder_heidenhainMeasurements_ratedOffsetA: {
        label: {
            fr: "Offset A nominal",
            en: "Rated offset A"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    encoder_heidenhainMeasurements_measuredOffsetA: {
        label: {
            fr: "Offset A mesuré",
            en: "Measured offset A"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    encoder_heidenhainMeasurements_ratedOffsetB: {
        label: {
            fr: "Offset B nominal",
            en: "Rated offset B"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    encoder_heidenhainMeasurements_measuredOffsetB: {
        label: {
            fr: "Offset B mesuré",
            en: "Measured offset B"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
};

export const encoderHeidenhainMeasurementsFields = fieldsParser(_encoderHeidenhainMeasurementsFields);