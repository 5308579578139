import fieldsParser from "../../../fieldsParser";

export const _tighteningAndDousingSystemKesslerControlKeelFields = {
    tighteningAndDousingSystem_kessler_controlKeel_measuredMP1deg0Keel: {
        label: {
            fr: "Quille MP1 20mm 0° mesurée",
            en: "Keel MP1 20mm 0° measured"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_controlKeel_maxMP1deg0Keel: {
        label: {
            fr: "Quille MP1 20mm 0° max",
            en: "Keel MP1 20mm 0° max"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_controlKeel_measuredMP1deg90Keel: {
        label: {
            fr: "Quille MP1 20mm 90° mesurée",
            en: "Keel MP1 20mm 90° measured"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_controlKeel_maxMP1deg90Keel: {
        label: {
            fr: "Quille MP1 20mm 90° max",
            en: "Keel MP1 20mm 90° max"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_controlKeel_measuredMP1deg180Keel: {
        label: {
            fr: "Quille MP1 20mm 180° mesurée",
            en: "Keel MP1 20mm 0° measured"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_controlKeel_maxMP1deg180Keel: {
        label: {
            fr: "Quille MP1 20mm 180° max",
            en: "Keel MP1 20mm 180° max"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_controlKeel_measuredMP1deg270Keel: {
        label: {
            fr: "Quille MP1 20mm 270° mesurée",
            en: "Keel MP1 20mm 270° measured"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_controlKeel_maxMP1deg270Keel: {
        label: {
            fr: "Quille MP1 20mm 270° max",
            en: "Keel MP1 20mm 270° max"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_controlKeel_measuredMP2deg0Keel: {
        label: {
            fr: "Quille MP2 280mm 0° mesurée",
            en: "Keel MP2 280mm 0° measured"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_controlKeel_maxMP2deg0Keel: {
        label: {
            fr: "Quille MP2 280mm 0° max",
            en: "Keel MP2 280mm 0° max"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_controlKeel_measuredMP2deg90Keel: {
        label: {
            fr: "Quille MP2 280mm 90° mesurée",
            en: "Keel MP2 280mm 90° measured"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_controlKeel_maxMP2deg90Keel: {
        label: {
            fr: "Quille MP2 280mm 90° max",
            en: "Keel MP2 280mm 90° max"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_controlKeel_measuredMP2deg180Keel: {
        label: {
            fr: "Quille MP2 280mm 180° mesurée",
            en: "Keel MP2 280mm 0° measured"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_controlKeel_maxMP2deg180Keel: {
        label: {
            fr: "Quille MP2 280mm 180° max",
            en: "Keel MP2 280mm 180° max"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_controlKeel_measuredMP2deg270Keel: {
        label: {
            fr: "Quille MP2 280mm 270° mesurée",
            en: "Keel MP2 280mm 270° measured"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_controlKeel_maxMP2deg270Keel: {
        label: {
            fr: "Quille MP2 280mm 270° max",
            en: "Keel MP2 280mm 270° max"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_controlKeel_comparatorMeasurementsPhotos: {
        label: {
            fr: "Photos des mesures au comparateur",
            en: "Comparator measurements photos"
        },
		category: ['kessler'],
		type: 'photo',
        directoryName: 'Systeme_de_serrage_et_arrosage',
        columnWeight: 2,
    },
};

export const tighteningAndDousingSystemKesslerControlKeelFields = fieldsParser(_tighteningAndDousingSystemKesslerControlKeelFields);