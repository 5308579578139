import fieldsParser from "../../fieldsParser";

export const _coolingSystemFaultsCausesFields = {
    coolingSystem_faultsCauses_corrosiveProductUse: {
        label: {
            fr: "Utilisation d'un produit corrosif",
            en: "Corrosive product use"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    coolingSystem_faultsCauses_lackOfCircuitMaintenance: {
        label: {
            fr: "Manque d'entretien du circuit",
            en: "Lack of circuit maintenance"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    coolingSystem_faultsCauses_pollution: {
        label: {
            fr: "Pollution",
            en: "Pollution"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    coolingSystem_faultsCauses_overheating: {
        label: {
            fr: "Surchauffe",
            en: "Overheating"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    coolingSystem_faultsCauses_gasketsDeterioration: {
        label: {
            fr: "Détérioration des joints",
            en: "Gaskets deterioration"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    coolingSystem_faultsCauses_lastRepairsNonCompliant: {
        label: {
            fr: "Réparation précédente non conforme",
            en: "Last repairs non-compliant"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
};

export const coolingSystemFaultsCausesFields = fieldsParser(_coolingSystemFaultsCausesFields);