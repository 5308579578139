<style scoped>
    .chooser-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 500px;
        height: 300px;
        padding: 0 20px;
    }

    .choice {
        width: 175px;
        height: 175px;
        background-color: #d6dbe1;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
    }

    .choice-title {
        text-align: center;
        margin-top: 15px;
        font-size: 20px;
        margin-bottom: -25px;
    }

    .chooser-wrapper .choice svg {
        font-size: 80px;
    }

    .source-input {
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        cursor: pointer;
    }

    .choice :deep(a) {
        width: 100%;
        height: 100%;
        padding: 0;
        position: absolute;
        opacity: 0;
    }

    .dropbox-results-container {
        flex-direction: column;
    }

    .dropbox-result-container {
        margin: 15px;
        display: flex;
        align-items: center;
    }

    .dropbox-result-container > div {
        display: flex;
        flex-direction: column;
    }

    .dropbox-result-name {
        font-weight: bold;
        margin-bottom: 5px;
    }
</style>

<template>
    <Modal v-show="displayTabletSourceChooser" :hide-title="true" :hide-icon="true" @click="closeModal">
        <template v-slot:modalContent>
            <div class="chooser-wrapper">
                <div class="choice-wrapper">
                    <div class="choice">
                        <font-awesome-icon :icon="['fas', 'tablet-alt']" style="color: #959595"/>
                        <input type="file" class="source-input" id="source-tablet" accept="image/png, image/gif, image/jpeg" capture @change="inputTablet"/>
                    </div>
                    <div class="choice-title">Tablette</div>
                </div>

                <div class="choice-wrapper">
                    <div class="choice" :id="id" @click="dropboxClicked" style="background-color: #0061fe">
                        <font-awesome-icon :icon="['fab', 'dropbox']" style="color: white"/>
                    </div>
                    <div class="choice-title">Dropbox</div>
                </div>
            </div>
        </template>
    </Modal>
    <Modal v-show="displayDropboxExplorer" :fullscreen="true">
        <template v-slot:modalTitle>
            <div>IMPORTER DEPUIS DROPBOX</div>
        </template>
        <template v-slot:modalIcon>
            <font-awesome-icon :icon="['fab', 'dropbox']" />
        </template>
        <template v-slot:modalContent>
            <form>
                <div>
                    <BaseInput 
                        v-model="dropboxSearch"
                        label="Rechercher"
                        @onChange="onDropboxSearchChange"
                    />
                </div>
                <div class="dropbox-results-container" v-if="dropboxSearchResults != null">
                    <template v-for="(dropboxSearchResult, dropboxSearchIndex) in dropboxSearchResults" :key="dropboxSearchIndex">
                        <div class="dropbox-result-container">
                            <BaseButton class="orange-button" buttonText="Sélectionner" @click="downloadFromDropbox(dropboxSearchResult.id)">
                                <template v-slot:iconBefore>
                                    <font-awesome-icon :icon="['fas', 'file-download']"/>
                                </template>
                            </BaseButton>
                            <div>
                                <div class="dropbox-result-name">{{ dropboxSearchResult.name }}</div>
                                <div>{{ dropboxSearchResult.path }}</div>
                            </div>
                        </div>
                    </template>
                    <div class="dropbox-result-container" v-if="dropboxSearchResults.length == 0">Aucun résultat</div>
                </div>
            </form>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeDropboxModal" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
        </template>
    </Modal>
</template>

<script>
import Modal from "@/components/App/Modal.vue";
import BaseInput from '../../components/Base/BaseInput';
import BaseButton from '../../components/Base/BaseButton';
import axios from "axios";
import $ from 'jquery';
import config from "@/config";

export default {
    name: "PhotoSourceChooser",
    components: {
        Modal,
        BaseInput,
        BaseButton
    },
    props: {
        displayTabletSourceChooser: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            id: null,
            displayDropboxExplorer: false,
            dropboxSearch: '',
            dropboxSearchInProgress: false,
            dropboxSearchResults: null
        }
    },
    mounted() {
        this.id = Math.floor(Math.random() * Date.now()) + '_dropbox';
    },
    methods: {
        inputTablet(event) {
            this.$emit('inputFile', event);
        },
        closeModal(event) {
            if ($(event.target).hasClass('modal')) {
                this.$emit('closeModal');
            }
        },
        dropboxClicked() {
            this.displayDropboxExplorer = true;
            this.dropboxSearch = '';
            this.dropboxSearchInProgress = false;
            this.dropboxSearchResults = null;
        },
        closeDropboxModal() {
            this.displayDropboxExplorer = false;
        },
        onDropboxSearchChange() {
            if (this.dropboxSearch.length < 3 || this.dropboxSearchInProgress) {
                this.dropboxSearchResults = this.dropboxSearch.length === 0 ? null : [];
                return;
            }

            this.dropboxSearchInProgress = true;
            let dropBoxAccessToken = String(localStorage.getItem("dropboxAccessToken"));

            axios({
                url: 'https://api.dropboxapi.com/2/files/search_v2',
                method: 'POST',
                showPreloader: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${dropBoxAccessToken}`,
                    'Dropbox-API-Select-User': `${config.DROPBOX_TEAM_MEMBER_ID}`,
                },
                data: {
                    query: this.dropboxSearch,
                    options: {
                        file_categories: ['image'],
                        max_results: 15
                    }
                }
            })
            .then((result) => {
                if (result?.data?.matches?.length > 0) {
                    this.dropboxSearchResults = result.data.matches.map(match => {
                        return {
                            id: match?.metadata?.metadata?.id ?? null,
                            name: match?.metadata?.metadata?.name ?? '/',
                            path: match?.metadata?.metadata?.path_display ?? '/'
                        }
                    }).sort((file1, file2) => file1.name < file2.name ? -1 : file1.name > file2.name ? 1 : 0);
                } else {
                    this.dropboxSearchResults = [];
                }

                this.dropboxSearchInProgress = false;
            })
            .catch((error) => {
                this.dropboxSearchResults = [];
                this.dropboxSearchInProgress = false;
            });
        },
        downloadFromDropbox(fileId) {
            let that = this;

          let dropBoxAccessToken = String(localStorage.getItem("dropboxAccessToken"));

            axios({
                url: 'https://content.dropboxapi.com/2/files/download',
                method: 'POST',
                showPreloader: true,
                headers: {
                    'Authorization': `Bearer ${dropBoxAccessToken}`,
                    'Dropbox-API-Select-User': `${config.DROPBOX_TEAM_MEMBER_ID}`,
                    'Dropbox-API-Arg': JSON.stringify({
                        path: fileId
                    })
                },
                responseType: 'blob'
            })
            .then((result) => {
                that.$emit('inputFile', result.data);
                that.closeDropboxModal();
            })
            .catch((error) => {
                console.log('error', error)
            });
        }
    }
}
</script>