import fieldsParser from "../../fieldsParser"

export const _checklistBalancingCustomerBearingFields = {
    checklist_balancing_customerRotationalSpeed: {
        label: {
            fr: "Vitesse de rotation (client, rpm)",
            en: "Rotational speed (customer, rpm)"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_balancing_customerBreakInTime: {
        label: {
            fr: "Temps de rodage (client, h)",
            en: "Break-in time (customer, h)"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_balancing_customer_bearing_customerFrontBearing1: {
        label: {
            fr: "Palier avant (client, mm/s)",
            en: "Front bearing (customer, mm/s)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_balancing_customer_bearing_customerRearBearing1: {
        label: {
            fr: "Palier arrière (client, mm/s)",
            en: "Rear bearing (customer, mm/s)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    }
};

export const checkListBalancingCustomerBearingFields = fieldsParser(_checklistBalancingCustomerBearingFields);