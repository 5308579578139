import fieldsParser from "@/formFields/fieldsParser"

export const _spindleRestKeel = {
    spindleRest_keelLength: {
        label: {
            fr: "Longueur",
            en: "Length"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        unit: "mm"
    },
    spindleRest_keelCone: {
        label: {
            fr: "Cône",
            en: "Cone"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        unit: "µ"
    },
    spindleRest_keelEndBeat: {
        label: {
            fr: "Battement bout de quille",
            en: "Keel end beat"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        unit: "µ"
    },
    spindleRest_keelFace: {
        label: {
            fr: "Face",
            en: "Face"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        unit: "µ"
    },
    spindleRest_keelEdgeFlapping: {
        label: {
            fr: "Battement bord de quille",
            en: "Keel edge flapping"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        unit: "µ"
    },
    spindleRest_backlashControl: {
        label: {
            fr: "Contrôle du jeu (rigidité de la broche)",
            en: "Backlash control (spindle rigidity)"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } }
        }
    },
    spindleRest_axialDisplacementControl: {
        label: {
            fr: "Contrôle du déplacement axial de l'arbre lors d'un cycle de changements d'outils",
            en: "Control of the axial displacement of the shaft during a tool change cycle"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        unit: "µ"
    }
}

export const spindleRestKeel = fieldsParser(_spindleRestKeel)