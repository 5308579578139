import fieldsParser from "../../fieldsParser";

export const _conclusionFaultsCausesFields = {
    conclusion_faultsCauses_excessiveMachiningEffortsCollision: {
        label: {
            fr: "Collision efforts d'usinage trop élevés",
            en: "Excessive machining efforts collision"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_faultsCauses_flood: {
        label: {
            fr: "Inondation",
            en: "Flood"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_faultsCauses_normalWear: {
        label: {
            fr: "Usure normale",
            en: "Normal wear"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_faultsCauses_variatorOverloadOverintensity: {
        label: {
            fr: "Surcharge / Surintensité variateur",
            en: "Variator overload / overintensity"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_faultsCauses_badToolHolderUse: {
        label: {
            fr: "Utilisation de mauvais porte-outil",
            en: "Bad tool holder use"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_faultsCauses_machiningVibrations: {
        label: {
            fr: "Vibrations en usinage",
            en: "Machining vibrations"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_faultsCauses_lubricationProblem: {
        label: {
            fr: "Problème de lubrification",
            en: "Lubrication problem"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_faultsCauses_lastRepairsNonCompliant: {
        label: {
            fr: "Réparation précédente non conforme",
            en: "Last repairs non-compliant"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_faultsCauses_other: {
        label: {
            fr: "Autres",
            en: "Other"
        },
		category: ['ebc', 'light'],
		type: "input",
        inputType: "textarea",
        columnWeight: 4
    },
};

export const conclusionFaultsCausesFields = fieldsParser(_conclusionFaultsCausesFields);