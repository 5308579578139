import fieldsParser from "../../../fieldsParser";

export const _bearingsCompartmentsRepairsFields = {
    bearings_compartments_repairs_boringRectificationAndChromePlating: {
        label: {
            fr: "Chromage alésage + Rectification",
            en: "Boring chrome plating + rectification"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    bearings_compartments_repairs_supportsRectification: {
        label: {
            fr: "Rectification appuis",
            en: "Supports rectification"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    bearings_compartments_repairs_superFinishingHousings: {
        label: {
            fr: "Superfinition des boîtiers",
            en: "Superfinishing of housings"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        },
        extraColumnSpace: 1
    },
    bearings_compartments_repairs_repairOfFretOrOther: {
        label: {
            fr: "Réparation (frette ou autre)",
            en: "Repair (fret or other)"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    bearings_compartments_repairs_caseReplacement: {
        label: {
            fr: "Remplacement du boitier",
            en: "Case replacement"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    bearings_compartments_repairs_labyrinthFlaskReplacement: {
        label: {
            fr: "Remplacement flasque labyrinthe",
            en: "Labyrinth flask replacement"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    bearings_compartments_repairs_pieceReplacement: {
        label: {
            fr: "Remplacement pièce",
            en: "Piece replacement"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } }
        }
    },
};

export const bearingsCompartmentsRepairsFields = fieldsParser(_bearingsCompartmentsRepairsFields);