import fieldsParser from "../../fieldsParser";

export const _bearingsBearingFields = {
    bearings_bearing_bearingPhoto: {
        label: {
            fr: "",
            en: ""
        },
		category: ['ebc', 'kessler'],
		type: 'photo',
        directoryName: 'Roulements',
        columnWeight: 2,
        photoButtons: 'right',
        dynamic: true,
        color: false
    },
    bearings_bearing_ok_ko: {
        type: "radio",
        options: {
            OK: { label: { fr: "Ok", en: "Ok" } },
            KO: { label: { fr: "Non OK", en: "No OK" } },
        },
        hasNA : false,
		category: ['ebc', 'kessler'],
    },
    bearings_bearing_noisy: {
        label: {
            fr: "Bruyant",
            en: "Noisy"
        },
		category: ['ebc', 'kessler'],
		type: "checkbox",
        dynamic: true,
        color: false
    },
    bearings_bearing_dry: {
        label: {
            fr: "Sec",
            en: "Dry"
        },
		category: ['ebc', 'kessler'],
		type: "checkbox",
        dynamic: true,
        color: false
    },
    bearings_bearing_lubricationFailure: {
        label: {
            fr: "Défaut de lubrification",
            en: "lubrication Failure"
        },
		category: ['ebc', 'kessler'],
		type: "checkbox",
        dynamic: true,
        color: false
    },
    bearings_bearing_trace_of_heating: {
        label: {
            fr: "Traces de chauffe",
            en: "Traces of heating"
        },
		category: ['ebc', 'kessler'],
		type: "checkbox",
        dynamic: true,
        color: false
    },
    bearings_bearing_worn: {
        label: {
            fr: "Usure",
            en: "Worn"
        },
		category: ['ebc', 'kessler'],
		type: "checkbox",
        dynamic: true,
        color: false
    },
    bearings_bearing_corroded: {
        label: {
            fr: "Corrosion",
            en: "Corroded"
        },
		category: ['ebc', 'kessler'],
		type: "checkbox",
        dynamic: true,
        color: false
    },
    bearings_bearing_damagedCage: {
        label: {
            fr: "Cage endommagée",
            en: "Damaged cage"
        },
		category: ['ebc', 'kessler'],
		type: "checkbox",
        dynamic: true,
        color: false
    },
    bearings_bearing_flakyTrack: {
        label: {
            fr: "Piste écaillée",
            en: "Flaky track"
        },
		category: ['ebc', 'kessler'],
		type: "checkbox",
        dynamic: true,
        color: false
    },
    bearings_bearing_burntTrack: {
        label: {
            fr: "Piste brûlée",
            en: "Burnt track"
        },
		category: ['ebc', 'kessler'],
		type: "checkbox",
        dynamic: true,
        color: false
    },
    bearings_bearing_markedTrack: {
        label: {
            fr: "Piste marquée",
            en: "Marked track"
        },
        category: ['ebc', 'kessler'],
        type: "checkbox",
        dynamic: true,
        color: false
    },
    bearings_bearing_collision: {
        label: {
            fr: "Collision / efforts d'usinage",
            en: "Collision / machining efforts"
        },
        category: ['ebc', 'kessler'],
        type: "checkbox",
        dynamic: true,
        color: false
    },
    bearings_bearing_comments: {
        label: {
            fr: "Commentaires",
            en: "Comments"
        },
        category: ['ebc', 'kessler'],
        type: "input",
        inputType: "textarea",
        dynamic: true,
        columnWeight: 2,
        color: false
    }
};

export const bearingsBearingFields = fieldsParser(_bearingsBearingFields);