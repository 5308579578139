import fieldsParser from "../../fieldsParser"

export const _checklistBalancingMaxBearingFields = {
    checklist_balancing_maxRotationalSpeed: {
        label: {
            fr: "Vitesse de rotation (max, rpm)",
            en: "Rotational speed (max, rpm)"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_balancing_maxBreakInTime: {
        label: {
            fr: "Temps de rodage (max, h)",
            en: "Break-in time (max, h)"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_balancing_max_bearing_maxFrontBearing1: {
        label: {
            fr: "Palier avant (max, mm/s)",
            en: "Front bearing (max, mm/s)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_balancing_max_bearing_maxRearBearing1: {
        label: {
            fr: "Palier arrière (max, mm/s)",
            en: "Rear bearing (max, mm/s)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    }
};

export const checkListBalancingMaxBearingFields = fieldsParser(_checklistBalancingMaxBearingFields);