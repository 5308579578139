import fieldsParser from "../../fieldsParser"

export const _checklistLubrificationFields = {
    checklist_lubrification_airOilLubrification: {
        label: {
            fr: "Lubrification",
            en: "Lubrification"
        },
		category: ['ebc'],
		type: "select",
        defaultOptions: {
            lubrification_1: { label: { fr: "Air / huile", en: "Air / oil" } },
            lubrification_2: { label: { fr: "Graisse", en: "Grease" } },
            lubrification_3: { label: { fr: "Brouillard d'huile", en: "Oil mist" } },
        },
        keys: ["standard"]
    },
    checklist_lubrification_hskFaceTemperature: {
        label: {
            fr: "Température de broche (°C)",
            en: "Spindle temperature (°C)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_lubrification_volume: {
        label: {
            fr: "Volume (mm3/h)",
            en: "Volume (mm3/h)"
        },
		category: ['ebc'],
		type: "select",
        defaultOptions: {
            volume_1: { label: { fr: "200 mm3/h", en: "200 mm3/h" } },
            volume_2: { label: { fr: "250 mm3/h", en: "250 mm3/h" } },
            volume_3: { label: { fr: "300 mm3/h", en: "300 mm3/h" } },
            volume_4: { label: { fr: "350 mm3/h", en: "350 mm3/h" } },
            volume_5: { label: { fr: "400 mm3/h", en: "400 mm3/h" } },
        },
        keys: ["standard"]
    },
    checklist_lubrification_pressurizationAirCurtain: {
        label: {
            fr: "Pressurisation / rideau d'air (Bars)",
            en: "Pressurization / air curtain (Bars)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_lubrification_hydraulic_minimal: {
        label: {
            fr: "Hydraulique minimal (Bars)",
            en: "Hydraulic minimal (Bars)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_lubrification_hydraulic_effective: {
        label: {
            fr: "Hydraulique effective (Bars)",
            en: "Hydraulic effective (Bars)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_lubrification_cooling_minimal: {
        label: {
            fr: "Refroidissement minimal (Bars)",
            en: "Cooling minimal (Bars)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_lubrification_cooling_effective: {
        label: {
            fr: "Refroidissement effective (Bars)",
            en: "Cooling effective (Bars)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_lubrification_cooling_minimal2: {
        label: {
            fr: "Refroidissement minimal (Litres)",
            en: "Cooling minimal (Liters)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_lubrification_cooling_effective2: {
        label: {
            fr: "Refroidissement effective (Litres)",
            en: "Cooling effective (Liters)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_lubrification_wasteOilFlowControl: {
        label: {
            fr: "Contrôle écoulement suffisant huiles usées",
            en: "Sufficient waste oil flow control"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_lubrification_pressureControl: {
        label: {
            fr: "Contrôle des pressions",
            en: "Pressure control"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_lubrification_blowingCone: {
        label: {
            fr: "Soufflage cône (bars)",
            en: "Blowing cone (bars)"
        },
        category: ['ebc'],
        type: "input",
        inputType: "text",
        keys: ["standard"],
        negative: false
    },
    checklist_lubrification_blowingConeCycles: {
        label: {
            fr: "Soufflage cône (nombre de cycles)",
            en: "Blowing cone (number of cycles)"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_lubrification_sprinklerPressure: {
        label: {
            fr: "Pression d'arrosage (bars)",
            en: "Sprinkler pressure (bars)"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_lubrification_rotaryJointTests: {
        label: {
            fr: "Tests joint tournant",
            en: "Rotary joint tests"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    
    checklist_lubrification_liquidFlow: {
        label: {
            fr: "Débit de liquide (L/min)",
            en: "Liquid flow (L/min)"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_lubrification_testTime: {
        label: {
            fr: "Temps des essais (min)",
            en: "Test time (min)"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_lubrification_drainingAndDryingCoolingCircuit: {
        label: {
            fr: "Vidange et séchage du circuit de refroidissement",
            en: "Draining and drying the cooling circuit"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    }
};

export const checkListLubrificationFields = fieldsParser(_checklistLubrificationFields);