import fieldsParser from "../../../fieldsParser";

export const _tighteningAndDousingSystemKesslerManualTestsFields = {
    tighteningAndDousingSystem_kessler_manualTests_rotatingGasketSerialNumber: {
        label: {
            fr: "Numéro de série du joint tournant",
            en: "Rotating gasket serial number"
        },
		category: ['kessler'],
		type: "input"
    },
    tighteningAndDousingSystem_kessler_manualTests_looseningUnitSerialNumber: {
        label: {
            fr: "Numéro de série de l'unité de desserrage",
            en: "Loosening unit serial number"
        },
		category: ['kessler'],
		type: "input"
    },
    tighteningAndDousingSystem_kessler_manualTests_unitWatertightnessTest: {
        label: {
            fr: "Test d'étanchéité de l'unité",
            en: "Unit watertightness test"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        columnWeight: 2
    },
    tighteningAndDousingSystem_kessler_manualTests_rotatingGasketAndActuatorMacroPhoto: {
        label: {
            fr: "Photo joint tournant et vérin en macro",
            en: "Rotating gasket and actuator macro photo"
        },
		category: ['kessler'],
		type: 'photo',
        directoryName: 'Systeme_de_serrage_et_arrosage'
    },
    tighteningAndDousingSystem_kessler_manualTests_disassembledRotatingGasketMacroPhoto: {
        label: {
            fr: "Photo en macro joint tournant démonté",
            en: "Disassembled rotating gasket macro photo"
        },
		category: ['kessler'],
		type: 'photo',
        directoryName: 'Systeme_de_serrage_et_arrosage'
    },
};

export const tighteningAndDousingSystemKesslerManualTestsFields = fieldsParser(_tighteningAndDousingSystemKesslerManualTestsFields);