import fieldsParser from "@/formFields/fieldsParser";

export const _generalInformationsFields = {
    machine: {
        label: {
            fr: "Machine",
            en: "Machine"
        },
        category: ['ebc'],
        type: "input",
        inputType: "text"
    },
    interventionType: {
        label: {
            fr: "Type d'intervention",
            en: "Intervention type"
        },
        category: ['ebc'],
        type: "select",
        defaultOptions: {
            it_1: { label: { fr: "Dépose", en: "Removal" } },
            it_2: { label: { fr: "Repose", en: "Resting" } },
            it_3: { label: { fr: "Analyse vibratoire", en: "Vibration analysis" } },
            it_4: { label: { fr: "Diagnostic", en: "Diagnosis" } },
            it_5: { label: { fr: "Autre", en: "Other" } },
        }
    },
    otherInterventionType: {
        label: {
            fr: "Autre",
            en: "Other"
        },
        type: "input",
        vif: "return form?.['interventionType']?.value === 'it_5'",
        category: ['ebc'],
    }
}

export const generalInformationsFields = fieldsParser(_generalInformationsFields);