import fieldsParser from "../../fieldsParser";

export const _conclusionFaultsFields = {
    conclusion_faults_rotationallyBlockedSpindle: {
        label: {
            fr: "Broche bloquée en rotation",
            en: "Rotationally blocked spindle"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_faults_vibrations: {
        label: {
            fr: "Vibrations",
            en: "Vibrations"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_faults_geometricFaults: {
        label: {
            fr: "Défauts géométriques",
            en: "Geometric faults"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_faults_damagedBearings: {
        label: {
            fr: "Roulements endommagés",
            en: "Damaged bearings"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_faults_toolTighteningProblem: {
        label: {
            fr: "Problème de serrage outil",
            en: "Tool tightening problem"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_faults_spindleBlockedTool: {
        label: {
            fr: "Outil bloqué en broche",
            en: "Spindle blocked tool"
        },
		category: ['ebc', 'light', 'kessler'],
		type: "checkbox"
    },
    conclusion_faults_bearingsNoise: {
        label: {
            fr: "Bruit de roulements",
            en: "Bearings noise"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_faults_probeProblem: {
        label: {
            fr: "Problème de sonde",
            en: "Probe problem"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_faults_sensorProblem: {
        label: {
            fr: "Problème de capteur",
            en: "Sensor problem"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_faults_burnedOutMotor: {
        label: {
            fr: "Moteur grillé",
            en: "Burned-out motor"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_faults_flood: {
        label: {
            fr: "Inondation",
            en: "Flood"
        },
		category: ['ebc', 'light'],
		type: "checkbox"
    },
    conclusion_faults_other: {
        label: {
            fr: "Autres",
            en: "Other"
        },
		category: ['ebc', 'light'],
		type: "input",
        inputType: "textarea",
        columnWeight: 4
    },
};

export const conclusionFaultsFields = fieldsParser(_conclusionFaultsFields);