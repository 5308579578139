import fieldsParser from "../../../fieldsParser";

export const _tighteningAndDousingSystemPreciseComponentsFields = {
    tighteningAndDousingSystem_precise_components_bolt: {
        label: {
            fr: "Boulon",
            en: "Bolt"
        },
		category: ['precise'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    tighteningAndDousingSystem_precise_components_boltState: {
        label: {
            fr: "État boulon",
            en: "Bolt state"
        },
		category: ['precise'],
		type: "select",
        defaultOptions: {
            worn: { label: { fr: "Usé", en: "Worn" } },
            broken: { label: { fr: "Cassé", en: "Broken" } },
            rusty: { label: { fr: "Rouillé", en: "Rusty" } },
            twisted: { label: { fr: "Tordu", en: "Twisted" } },
            vibrations: { label: { fr: "Vibrations", en: "Vibrations" } },
        },
        multiple: true
    },
    tighteningAndDousingSystem_precise_components_tighteningPliers: {
        label: {
            fr: "Pince de serrage",
            en: "Tightening pliers"
        },
		category: ['precise'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    tighteningAndDousingSystem_precise_components_tighteningPliersState: {
        label: {
            fr: "État pince de serrage",
            en: "Tightening pliers state"
        },
		category: [/*'precise', */'kessler'],
		type: "select",
        defaultOptions: {
            worn: { label: { fr: "Usé", en: "Worn" } },
            broken: { label: { fr: "Cassé", en: "Broken" } },
            rusty: { label: { fr: "Rouillé", en: "Rusty" } },
            twisted: { label: { fr: "Tordu", en: "Twisted" } },
            vibrations: { label: { fr: "Vibrations", en: "Vibrations" } },
        },
        multiple: true
    },
    tighteningAndDousingSystem_precise_components_watertightnessValve: {
        label: {
            fr: "Valve d'étanchéité",
            en: "Watertightness valve"
        },
		category: ['precise'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    tighteningAndDousingSystem_precise_components_watertightnessValveState: {
        label: {
            fr: "État valve d'étanchéité",
            en: "Watertightness valve state"
        },
		category: ['precise'],
		type: "select",
        defaultOptions: {
            worn: { label: { fr: "Usé", en: "Worn" } },
            broken: { label: { fr: "Cassé", en: "Broken" } },
            rusty: { label: { fr: "Rouillé", en: "Rusty" } },
            twisted: { label: { fr: "Tordu", en: "Twisted" } },
            vibrations: { label: { fr: "Vibrations", en: "Vibrations" } },
        },
        multiple: true
    },
    tighteningAndDousingSystem_precise_components_tighteningSystem: {
        label: {
            fr: "Système de serrage",
            en: "Tightening system"
        },
		category: ['precise'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    tighteningAndDousingSystem_precise_components_tighteningSystemState: {
        label: {
            fr: "État système de serrage",
            en: "Tightening system state"
        },
		category: ['precise'],
		type: "select",
        defaultOptions: {
            worn: { label: { fr: "Usé", en: "Worn" } },
            broken: { label: { fr: "Cassé", en: "Broken" } },
            rusty: { label: { fr: "Rouillé", en: "Rusty" } },
            twisted: { label: { fr: "Tordu", en: "Twisted" } },
            vibrations: { label: { fr: "Vibrations", en: "Vibrations" } },
        },
        multiple: true
    },
    tighteningAndDousingSystem_precise_components_springs: {
        label: {
            fr: "Ressorts",
            en: "Springs"
        },
		category: ['precise'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    tighteningAndDousingSystem_precise_components_springsState: {
        label: {
            fr: "État ressorts",
            en: "Springs state"
        },
		category: ['precise'],
		type: "select",
        defaultOptions: {
            worn: { label: { fr: "Usé", en: "Worn" } },
            broken: { label: { fr: "Cassé", en: "Broken" } },
            rusty: { label: { fr: "Rouillé", en: "Rusty" } },
            twisted: { label: { fr: "Tordu", en: "Twisted" } },
            vibrations: { label: { fr: "Vibrations", en: "Vibrations" } },
        },
        multiple: true
    },
    tighteningAndDousingSystem_precise_components_bellevilleWashers: {
        label: {
            fr: "Rondelles Belleville",
            en: "Belleville washers"
        },
		category: ['precise'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    tighteningAndDousingSystem_precise_components_bellevilleWashersState: {
        label: {
            fr: "État rondelles Belleville",
            en: "Belleville washers state"
        },
		category: ['precise'],
		type: "select",
        defaultOptions: {
            worn: { label: { fr: "Usé", en: "Worn" } },
            broken: { label: { fr: "Cassé", en: "Broken" } },
            rusty: { label: { fr: "Rouillé", en: "Rusty" } },
            twisted: { label: { fr: "Tordu", en: "Twisted" } },
            vibrations: { label: { fr: "Vibrations", en: "Vibrations" } },
        },
        multiple: true
    },
    tighteningAndDousingSystem_precise_components_rotatingGasketShaft: {
        label: {
            fr: "Arbre joint tournant",
            en: "Rotating gasket shaft"
        },
		category: ['precise'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    tighteningAndDousingSystem_precise_components_rotatingGasketShaftState: {
        label: {
            fr: "État arbre joint tournant",
            en: "Rotating gasket shaft state"
        },
		category: ['precise'],
		type: "select",
        defaultOptions: {
            worn: { label: { fr: "Usé", en: "Worn" } },
            broken: { label: { fr: "Cassé", en: "Broken" } },
            rusty: { label: { fr: "Rouillé", en: "Rusty" } },
            twisted: { label: { fr: "Tordu", en: "Twisted" } },
            vibrations: { label: { fr: "Vibrations", en: "Vibrations" } },
        },
        multiple: true
    },
    tighteningAndDousingSystem_precise_components_pullBar: {
        label: {
            fr: "Barre de tirage",
            en: "Pull bar"
        },
		category: ['precise'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    tighteningAndDousingSystem_precise_components_pullBarState: {
        label: {
            fr: "État barre de tirage",
            en: "Pull bar state"
        },
		category: ['precise'],
		type: "select",
        defaultOptions: {
            worn: { label: { fr: "Usé", en: "Worn" } },
            broken: { label: { fr: "Cassé", en: "Broken" } },
            rusty: { label: { fr: "Rouillé", en: "Rusty" } },
            twisted: { label: { fr: "Tordu", en: "Twisted" } },
            vibrations: { label: { fr: "Vibrations", en: "Vibrations" } },
        },
        multiple: true
    },
    tighteningAndDousingSystem_precise_components_tighteningAxis: {
        label: {
            fr: "Axe de serrage",
            en: "Tightening axis"
        },
		category: ['precise'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    tighteningAndDousingSystem_precise_components_tighteningAxisState: {
        label: {
            fr: "État axe de serrage",
            en: "Tightening axis state"
        },
		category: ['precise'],
		type: "select",
        defaultOptions: {
            worn: { label: { fr: "Usé", en: "Worn" } },
            broken: { label: { fr: "Cassé", en: "Broken" } },
            rusty: { label: { fr: "Rouillé", en: "Rusty" } },
            twisted: { label: { fr: "Tordu", en: "Twisted" } },
            vibrations: { label: { fr: "Vibrations", en: "Vibrations" } },
        },
        multiple: true
    },
    tighteningAndDousingSystem_precise_components_cylinder: {
        label: {
            fr: "Cylindre",
            en: "Cylinder"
        },
		category: ['precise'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    tighteningAndDousingSystem_precise_components_cylinderState: {
        label: {
            fr: "État cylindre",
            en: "Cylinder state"
        },
		category: ['precise'],
		type: "select",
        defaultOptions: {
            worn: { label: { fr: "Usé", en: "Worn" } },
            broken: { label: { fr: "Cassé", en: "Broken" } },
            rusty: { label: { fr: "Rouillé", en: "Rusty" } },
            twisted: { label: { fr: "Tordu", en: "Twisted" } },
            vibrations: { label: { fr: "Vibrations", en: "Vibrations" } },
        },
        multiple: true
    },
};

export const tighteningAndDousingSystemPreciseComponentsFields = fieldsParser(_tighteningAndDousingSystemPreciseComponentsFields);