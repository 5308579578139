import fieldsParser from "../../../fieldsParser";

export const _tighteningAndDousingSystemKesslerTighteningConeFields = {
    tighteningAndDousingSystem_kessler_tighteningCone_ratedRunout: {
        label: {
            fr: "Faux rond nominal (mm)",
            en: "Rated runout (mm)"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_tighteningCone_measuredRunout: {
        label: {
            fr: "Faux rond mesuré (mm)",
            en: "Measured runout (mm)"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_tighteningCone_ratedBattementFace: {
        label: {
            fr: "Battement face nominal (mm)",
            en: "Battement face nominal (mm)"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_tighteningCone_measuredBattementFace: {
        label: {
            fr: "Battement face mesuré (mm)",
            en: "Battement face mesuré (mm)"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_tighteningCone_coneLeftViewMacroPhoto: {
        label: {
            fr: "Photo macro du cône vue de gauche",
            en: "Cone left view macro photo"
        },
		category: ['kessler'],
		type: 'photo',
        directoryName: 'Systeme_de_serrage_et_arrosage'
    },
    tighteningAndDousingSystem_kessler_tighteningCone_coneRightViewMacroPhoto: {
        label: {
            fr: "Photo macro du cône vue de droite",
            en: "Cone right view macro photo"
        },
		category: ['kessler'],
		type: 'photo',
        directoryName: 'Systeme_de_serrage_et_arrosage'
    },
    tighteningAndDousingSystem_kessler_tighteningCone_coneTopViewMacroPhoto: {
        label: {
            fr: "Photo macro du cône vue d'en haut",
            en: "Cone top view macro photo"
        },
		category: ['kessler'],
		type: 'photo',
        directoryName: 'Systeme_de_serrage_et_arrosage'
    },
    tighteningAndDousingSystem_kessler_tighteningCone_coneBottomViewMacroPhoto: {
        label: {
            fr: "Photo macro du cône vue d'en bas",
            en: "Cone bottom view macro photo"
        },
		category: ['kessler'],
		type: 'photo',
        directoryName: 'Systeme_de_serrage_et_arrosage'
    },
};

export const tighteningAndDousingSystemKesslerTighteningConeFields = fieldsParser(_tighteningAndDousingSystemKesslerTighteningConeFields);