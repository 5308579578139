import fieldsParser from "../../fieldsParser";

export const _tighteningAndDousingSystemFaultsCausesFields = {
    tighteningAndDousingSystem_faultsCauses_wear: {
        label: {
            fr: "Usure",
            en: "Wear"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    tighteningAndDousingSystem_faultsCauses_impact: {
        label: {
            fr: "Choc",
            en: "Impact"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    tighteningAndDousingSystem_faultsCauses_flood: {
        label: {
            fr: "Inondation",
            en: "Flood"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    tighteningAndDousingSystem_faultsCauses_rotatingGasketProblem: {
        label: {
            fr: "Problème joint tournant",
            en: "Rotating gasket problem"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    tighteningAndDousingSystem_faultsCauses_dousingPipette: {
        label: {
            fr: "Pipette arrosage",
            en: "Dousing pipette"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    tighteningAndDousingSystem_faultsCauses_nonDrilledTool: {
        label: {
            fr: "Outil non percé",
            en: "Non-drilled tool"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    tighteningAndDousingSystem_faultsCauses_vibrations: {
        label: {
            fr: "Vibrations",
            en: "Vibrations"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    tighteningAndDousingSystem_faultsCauses_lackOfMaintenance: {
        label: {
            fr: "Manque d'entretien (graissage)",
            en: "Lack of maintenance (greasing)"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    tighteningAndDousingSystem_faultsCauses_lastRepairsNonCompliant: {
        label: {
            fr: "Réparation précédente non conforme",
            en: "Last repairs non-compliant"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
};

export const tighteningAndDousingSystemFaultsCausesFields = fieldsParser(_tighteningAndDousingSystemFaultsCausesFields);