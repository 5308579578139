import repairTypeEnum from "@/enums/repairTypeEnum";
import RepairTypeEnum from "@/enums/repairTypeEnum";

export const repairTypesFields = {
    label: {
        required: [repairTypeEnum.FOUR, repairTypeEnum.REEA, repairTypeEnum.ISSM, repairTypeEnum.MAIM, repairTypeEnum.ISSB, repairTypeEnum.RETR, repairTypeEnum.FORM],
        show: [repairTypeEnum.FOUR, repairTypeEnum.REEA, repairTypeEnum.ISSM, repairTypeEnum.MAIM, repairTypeEnum.ISSB, repairTypeEnum.RETR, repairTypeEnum.FORM],
        defaultValue: {
            FOUR: "Fourniture de :",
            REEA: "Remise en état arbre"
        }
    },
    machine: {
        required: [repairTypeEnum.ISSM, repairTypeEnum.MAIM, repairTypeEnum.RETR],
        show: [repairTypeEnum.ISSM, repairTypeEnum.MAIM, repairTypeEnum.RETR]
    },
    machineMaintenance: {
        required: [repairTypeEnum.MAIM],
        show: [repairTypeEnum.MAIM]
    },
    spindleBrandId: {
        required: not([repairTypeEnum.FOUR, repairTypeEnum.REEA, repairTypeEnum.ISSM, repairTypeEnum.MAIM, repairTypeEnum.RETR, repairTypeEnum.FORM, repairTypeEnum.ISSB]),
        show: not([repairTypeEnum.FOUR, repairTypeEnum.ISSM, repairTypeEnum.MAIM, repairTypeEnum.RETR, repairTypeEnum.FORM])
    },
    spindleTypeId: {
        required: not([repairTypeEnum.FOUR, repairTypeEnum.REEA, repairTypeEnum.ISSM, repairTypeEnum.MAIM, repairTypeEnum.RETR, repairTypeEnum.FORM, repairTypeEnum.ISSB]),
        show: not([repairTypeEnum.FOUR, repairTypeEnum.ISSM, repairTypeEnum.MAIM, repairTypeEnum.RETR, repairTypeEnum.FORM])
    },
    serialNumber: {
        required: not([repairTypeEnum.FOUR, repairTypeEnum.ISSM, repairTypeEnum.MAIM, repairTypeEnum.RETR, repairTypeEnum.FORM, repairTypeEnum.ISSB]),
        show: not([repairTypeEnum.FOUR, repairTypeEnum.ISSM, repairTypeEnum.MAIM, repairTypeEnum.RETR, repairTypeEnum.FORM])
    },
    weight: {
        required: not([repairTypeEnum.FOUR, repairTypeEnum.ISSM, repairTypeEnum.MAIM, repairTypeEnum.ISSB, repairTypeEnum.RETR, repairTypeEnum.FORM]),
        show: not([repairTypeEnum.FOUR, repairTypeEnum.ISSM, repairTypeEnum.MAIM, repairTypeEnum.ISSB, repairTypeEnum.RETR, repairTypeEnum.FORM])
    },
    notes: {
        required: [repairTypeEnum.MAIM],
        show: [repairTypeEnum.MAIM]
    },
    guarantee: {
        show: not([RepairTypeEnum.REEA, RepairTypeEnum.FOUR, RepairTypeEnum.MAIM, repairTypeEnum.FORM])
    },
    customsTransit: {
        show: not([RepairTypeEnum.ISSM, RepairTypeEnum.MAIM, RepairTypeEnum.RETR, RepairTypeEnum.ISSB,  RepairTypeEnum.FORM])
    },
    model: {
        required: [RepairTypeEnum.REEB, RepairTypeEnum.REEA],
        show: [RepairTypeEnum.REEB, RepairTypeEnum.REEA]
    },
    customerInformations: {
        show: not([])
    }
}

function not(repairTypes) {
    return Object.values(repairTypeEnum).filter(type => !(repairTypes.includes(type)))
}