export default function(fields) {
    let formFields = [];

    fields = JSON.parse(JSON.stringify(fields));

    for(let key of Object.keys(fields)) {
        let field = fields[key];
        field.name = key;
        field.label = field.label?.fr || '';
        if(field.type === 'select') {
            let defaultOptions = [];
            let defaultValue = null;
            if (field.defaultOptions) {
                for(let key of Object.keys(field.defaultOptions)) {
                    if (field.defaultOptions[key].default) {
                        defaultValue = { value: key, label: field.defaultOptions[key].label.fr }
                    }
                    defaultOptions.push({ value: key, label: field.defaultOptions[key].label.fr });
                }
            }
            field.defaultOptions = defaultOptions;
            field.defaultValue = defaultValue;
        }
        else if(field.type === 'radio') {
            let options = [];
            for(let key of Object.keys(field.options)) {
                options.push({ value: key, label: field.options[key].label.fr });
            }
            field.options = options;
        }

        formFields.push(field);
    }

    return formFields;
}