import fieldsParser from "../fieldsParser";

export const _generalInformationsFields = {
    cone: {
        label: {
            fr: "Cone",
            en: "Cone"
        },
		category: ['ebc', 'light'],
		type: "select",
        defaultOptions: {
            hsk_e40: { label: { fr: "HSK E40", en: "HSK E40" } },
            hsk_a40: { label: { fr: "HSK A40", en: "HSK A40" } },
            hsk_a50: { label: { fr: "HSK A50", en: "HSK A50" } },
            hsk_a63: { label: { fr: "HSK A63", en: "HSK A63" } },
            hsk_a80: { label: { fr: "HSK A80", en: "HSK A80" } },
            hsk_a100: { label: { fr: "HSK A100", en: "HSK A100" } },
            bt_40: { label: { fr: "BT 40", en: "BT 40" } },
            bbt_40: { label: { fr: "BBT 40", en: "BBT 40" } },
            bt_50: { label: { fr: "BT 50", en: "BT 50" } },
            bbt_50: { label: { fr: "BBT 50", en: "BBT 50" } },
            capto_c4: { label: { fr: "CAPTO C4", en: "CAPTO C4" } },
            capto_c5: { label: { fr: "CAPTO C5", en: "CAPTO C5" } },
            capto_c6: { label: { fr: "CAPTO C6", en: "CAPTO C6" } },
            capto_c8: { label: { fr: "CAPTO C8", en: "CAPTO C8" } }
        }
    },
    zipper: {
        label: {
            fr: "Tirette",
            en: "Zipper"
        },
		category: ['ebc', 'light'],
		type: "select",
        defaultOptions: {
            cat_90: { label: { fr: "CAT 90", en: "CAT 90" } },
            din: { label: { fr: "DIN", en: "DIN" } },
            mas_30: { label: { fr: "MAS 30", en: "MAS 30" } },
            mas_45: { label: { fr: "MAS 45", en: "MAS 45" } }
        }
    },
    lubrication: {
        label: {
            fr: "Lubrification",
            en: "Lubrication"
        },
		category: ['ebc', 'light'],
		type: "select",
        defaultOptions: {
            grease: { label: { fr: "Graisse", en: "Grease" } },
            oil_mist: { label: { fr: "Brouillard d'huile", en: "Oil mist" } },
            air_oil: { label: { fr: "Air/huile", en: "Air/oil" } },
        }
    },
    toolCenterDousing: {
        label: {
            fr: "Arrosage centre-outil",
            en: "Tool-center dousing"
        },
		category: ['ebc', 'light'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    speed: {
        label: {
            fr: "Vitesse (tr/min)",
            en: "Speed (RPM)"
        },
		category: ['ebc', 'light'],
		type: "input",
        inputType: "number"
    },
    frequency: {
        label: {
            fr: "Fréquence (Hz)",
            en: "Frequency (Hz)"
        },
		category: ['ebc', 'light'],
		type: "input",
        inputType: "number"
    },
    voltage: {
        label: {
            fr: "Tension (V)",
            en: "Voltage (V)"
        },
		category: ['ebc', 'light'],
		type: "input",
        inputType: "number"
    },
    intensity: {
        label: {
            fr: "Intensité (A)",
            en: "Intensity (A)"
        },
		category: ['ebc', 'light'],
		type: "input",
        inputType: "number"
    },
    power: {
        label: {
            fr: "Puissance (kW)",
            en: "Power (kW)"
        },
		category: ['ebc', 'light'],
		type: "input",
        inputType: "number"
    },
    spindleState: {
        label: {
            fr: "État broche à l'arrivée",
            en: "Spindle state at receipt"
        },
		category: ['ebc', 'light'],
		type: "select",
        defaultOptions: {
            whole: { label: { fr: "Entière", en: "Whole" } },
            missing_pieces: { label: { fr: "Pièces manquantes", en: "Missing pieces" } },
            disassembled_loose_parts: { label: { fr: "Démontée - pièces en vrac", en: "Disassembled - loose parts" } },
            disassembled_missing_parts: { label: { fr: "Démontée - pièces manquantes", en: "Disassembled - missing parts" } }
        }
    },
    disassemblyDate: {
        label: {
            fr: "Date du démontage",
            en: "Dissassembly date"
        },
		category: ['ebc', 'light'],
		type: "date"
    },
    afterSalesService: {
        label: {
            fr: "Demande garantie",
            en: "After-sales service"
        },
		category: ['ebc', 'light'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    site: {
        label: {
            fr: "Site",
            en: "Site"
        },
		category: ['ebc', 'light'],
		type: "input",
        inputType: "text",
        columnWeight: 1
    },
    problemMentioned: {
        label: {
            fr: "Problème évoqué",
            en: "Problem mentioned"
        },
		category: ['ebc', 'light'],
		type: "input",
        inputType: "textarea",
        columnWeight: 4
    },
    observations: {
        label: {
            fr: "Observations",
            en: "Observations"
        },
		category: ['ebc', 'light'],
		type: "input",
        inputType: "textarea",
        columnWeight: 4
    },
    articleNumber: {
        label: {
            fr: "Numéro article",
            en: "Article number"
        },
		category: ['kessler'],
		type: "input",
        inputType: "text"
    },
    planNumber: {
        label: {
            fr: "Numéro plan",
            en: "Plan number"
        },
		category: ['kessler'],
		type: "input",
        inputType: "text"
    },
    customerNumber: {
        label: {
            fr: "Numéro client",
            en: "Customer number"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    orderNumber: {
        label: {
            fr: "Numéro commande",
            en: "Order number"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    deliveryNoteNumber: {
        label: {
            fr: "Numéro BL",
            en: "Delivery note number"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    repairNumber: {
        label: {
            fr: "Numéro réparation",
            en: "Repair number"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    deliveredEquipment: {
        label: {
            fr: "Équipement livré",
            en: "Delivered equipment"
        },
		category: ['kessler'],
		type: "input"
    },
    informations: {
        label: {
            fr: "Informations",
            en: "Informations"
        },
		category: ['kessler'],
		type: "input",
        inputType: "textarea",
        columnWeight: 4
    },
    repairType: {
        label: {
            fr: "Type de réparation",
            en: "Repair type"
        },
		category: ['precise'],
		type: "select",
        defaultOptions: {
            patch: { label: { fr: "Correctif", en: "Patch" } },
            preventive: { label: { fr: "Préventif", en: "Preventive" } },
        }
    },
};

export const generalInformationsFields = fieldsParser(_generalInformationsFields);