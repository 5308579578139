import fieldsParser from "../../fieldsParser";

export const _tighteningAndDousingSystemFields = {
    tighteningAndDousingSystem_bolt: {
        label: {
            fr: "Boulon",
            en: "Bolt"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    tighteningAndDousingSystem_boltValue: {
        label: {
            fr: "Boulon (valeur)",
            en: "Bolt (value)"
        },
		category: ['ebc'],
		type: "select",
        defaultOptions: {
            worn: { label: { fr: "Usé", en: "Worn" } },
            broken: { label: { fr: "Cassé", en: "Broken" } },
            rust: { label: { fr: "Rouillé", en: "Rust" } },
            twisted: { label: { fr: "Tordu", en: "twisted" } },
            vibes: { label: { fr: "Vibrations", en: "Vibes" } },
        }
    },
    tighteningAndDousingSystem_pliersSegments: {
        label: {
            fr: "Segments de pince",
            en: "Pliers segments"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    tighteningAndDousingSystem_pliersSegmentsValue: {
        label: {
            fr: "Segments de pince (valeur)",
            en: "Pliers segments (value)"
        },
		category: ['ebc'],
		type: "select",
        defaultOptions: {
            worn: { label: { fr: "Usé", en: "Worn" } },
            broken: { label: { fr: "Cassé", en: "Broken" } },
            rust: { label: { fr: "Rouillé", en: "Rust" } },
            twisted: { label: { fr: "Tordu", en: "twisted" } },
            vibes: { label: { fr: "Vibrations", en: "Vibes" } },
        }
    },
    tighteningAndDousingSystem_pliersSupport: {
        label: {
            fr: "Support de pince",
            en: "Pliers support"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    tighteningAndDousingSystem_pliersSupportValue: {
        label: {
            fr: "Support de pince (valeur)",
            en: "Pliers support (value)"
        },
		category: ['ebc'],
		type: "select",
        defaultOptions: {
            worn: { label: { fr: "Usé", en: "Worn" } },
            broken: { label: { fr: "Cassé", en: "Broken" } },
            rust: { label: { fr: "Rouillé", en: "Rust" } },
            twisted: { label: { fr: "Tordu", en: "twisted" } },
            vibes: { label: { fr: "Vibrations", en: "Vibes" } },
        }
    },
    tighteningAndDousingSystem_tighteningStrap: {
        label: {
            fr: "Tirant de serrage",
            en: "Tightening strap"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    tighteningAndDousingSystem_tighteningStrapValue: {
        label: {
            fr: "Tirant de serrage (valeur)",
            en: "Tightening strap (value)"
        },
		category: ['ebc', 'keesler'],
		type: "select",
        defaultOptions: {
            worn: { label: { fr: "Usé", en: "Worn" } },
            broken: { label: { fr: "Cassé", en: "Broken" } },
            rust: { label: { fr: "Rouillé", en: "Rust" } },
            twisted: { label: { fr: "Tordu", en: "twisted" } },
            vibes: { label: { fr: "Vibrations", en: "Vibes" } },
            insufficient: { label: { fr: "Force de serrage insuffisante", en: "Insufficient tightening strap" } },
        }
    },
    tighteningAndDousingSystem_springsWashersOrSprings: {
        label: {
            fr: "Rondelles ou ressorts",
            en: "Springs washers or springs"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    tighteningAndDousingSystem_springsWashersOrSpringsValue: {
        label: {
            fr: "Rondelles ou ressorts (valeur)",
            en: "Springs washers or springs (value)"
        },
		category: ['ebc'],
		type: "select",
        defaultOptions: {
            worn: { label: { fr: "Usé", en: "Worn" } },
            broken: { label: { fr: "Cassé", en: "Broken" } },
            rust: { label: { fr: "Rouillé", en: "Rust" } },
            twisted: { label: { fr: "Tordu", en: "twisted" } },
            vibes: { label: { fr: "Vibrations", en: "Vibes" } },
        }
    },
    tighteningAndDousingSystem_looseningUnit: {
        label: {
            fr: "Unité de desserage (vérin)",
            en: "Loosening unit (actuator)"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    tighteningAndDousingSystem_looseningUnitValue: {
        label: {
            fr: "Unité de desserage (vérin) (valeur)",
            en: "Loosening unit (actuator) (value)"
        },
		category: ['ebc', 'kessler'],
		type: "select",
        defaultOptions: {
            worn: { label: { fr: "Usé", en: "Worn" } },
            broken: { label: { fr: "Cassé", en: "Broken" } },
            rust: { label: { fr: "Rouillé", en: "Rust" } },
            twisted: { label: { fr: "Tordu", en: "twisted" } },
            vibes: { label: { fr: "Vibrations", en: "Vibes" } },
        }
    },
    tighteningAndDousingSystem_looseningUnitUnit: {
        label: {
            fr: "Unité de desserage (vérin) (unité)",
            en: "Loosening unit (actuator) (unit)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "text"
    },
    tighteningAndDousingSystem_watertightnessConnector: {
        label: {
            fr: "Douille d'étanchéité",
            en: "Watertightness connector"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    tighteningAndDousingSystem_watertightnessConnectorValue: {
        label: {
            fr: "Douille d'étanchéité (valeur)",
            en: "Watertightness connector (value)"
        },
		category: ['ebc'],
		type: "select",
        defaultOptions: {
            worn: { label: { fr: "Usé", en: "Worn" } },
            broken: { label: { fr: "Cassé", en: "Broken" } },
            rust: { label: { fr: "Rouillé", en: "Rust" } },
            twisted: { label: { fr: "Tordu", en: "twisted" } },
            vibes: { label: { fr: "Vibrations", en: "Vibes" } },
        },
        extraColumnSpace: 2
    },
    tighteningAndDousingSystem_blowingPipe: {
        label: {
            fr: "Buse de soufflage",
            en: "Blowing pipe"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    tighteningAndDousingSystem_blowingPipeValue: {
        label: {
            fr: "Buse de soufflage (valeur)",
            en: "Blowing pipe (value)"
        },
		category: ['ebc'],
		type: "select",
        defaultOptions: {
            worn: { label: { fr: "Usé", en: "Worn" } },
            broken: { label: { fr: "Cassé", en: "Broken" } },
            rust: { label: { fr: "Rouillé", en: "Rust" } },
            twisted: { label: { fr: "Tordu", en: "twisted" } },
            vibes: { label: { fr: "Vibrations", en: "Vibes" } },
        }
    },
    tighteningAndDousingSystem_dousingPipe: {
        label: {
            fr: "Buse d'arrosage",
            en: "Dousing pipe"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    tighteningAndDousingSystem_dousingPipeValue: {
        label: {
            fr: "Buse d'arrosage (valeur)",
            en: "Dousing pipe (value)"
        },
		category: ['ebc'],
		type: "select",
        defaultOptions: {
            worn: { label: { fr: "Usé", en: "Worn" } },
            broken: { label: { fr: "Cassé", en: "Broken" } },
            rust: { label: { fr: "Rouillé", en: "Rust" } },
            twisted: { label: { fr: "Tordu", en: "twisted" } },
            vibes: { label: { fr: "Vibrations", en: "Vibes" } },
        }
    },
    tighteningAndDousingSystem_rotatingGasket: {
        label: {
            fr: "Joint tournant",
            en: "Rotating gasket"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    tighteningAndDousingSystem_rotatingGasketValue: {
        label: {
            fr: "Joint tournant (valeur)",
            en: "Rotating gasket (value)"
        },
		category: ['ebc', 'kessler'],
		type: "select",
        defaultOptions: {
            worn: { label: { fr: "Usé", en: "Worn" } },
            broken: { label: { fr: "Cassé", en: "Broken" } },
            rust: { label: { fr: "Rouillé", en: "Rust" } },
            twisted: { label: { fr: "Tordu", en: "twisted" } },
            vibes: { label: { fr: "Vibrations", en: "Vibes" } },
        }
    },
    tighteningAndDousingSystem_dousingRod: {
        label: {
            fr: "Tige d'arrosage",
            en: "Dousing rod"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    tighteningAndDousingSystem_dousingRodValue: {
        label: {
            fr: "Tige d'arrosage (valeur)",
            en: "Dousing rod (value)"
        },
		category: ['ebc', 'kessler'],
		type: "select",
        defaultOptions: {
            worn: { label: { fr: "Usé", en: "Worn" } },
            broken: { label: { fr: "Cassé", en: "Broken" } },
            rust: { label: { fr: "Rouillé", en: "Rust" } },
            twisted: { label: { fr: "Tordu", en: "twisted" } },
            vibes: { label: { fr: "Vibrations", en: "Vibes" } },
        }
    },
    tighteningAndDousingSystem_bimetallicConnector: {
        label: {
            fr: "Douille bimétal",
            en: "Bimetallic connector"
        },
		category: ['ebc', 'kessler'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    tighteningAndDousingSystem_bimetallicConnectorValue: {
        label: {
            fr: "Douille bimétal (valeur)",
            en: "Bimetallic connector (value)"
        },
		category: ['ebc', 'kessler'],
		type: "select",
        defaultOptions: {
            worn: { label: { fr: "Usé", en: "Worn" } },
            broken: { label: { fr: "Cassé", en: "Broken" } },
            rust: { label: { fr: "Rouillé", en: "Rust" } },
            twisted: { label: { fr: "Tordu", en: "twisted" } },
            vibes: { label: { fr: "Vibrations", en: "Vibes" } },
        },
        extraColumnSpace: 2
    },
    tighteningAndDousingSystem_grippingPieces: {
        label: {
            fr: "Pions serreurs",
            en: "Gripping pieces"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    tighteningAndDousingSystem_grippingPiecesValue: {
        label: {
            fr: "Pions serreurs (valeur)",
            en: "Gripping pieces (value)"
        },
		category: ['ebc'],
		type: "select",
        defaultOptions: {
            worn: { label: { fr: "Usé", en: "Worn" } },
            broken: { label: { fr: "Cassé", en: "Broken" } },
            rust: { label: { fr: "Rouillé", en: "Rust" } },
            twisted: { label: { fr: "Tordu", en: "twisted" } },
            vibes: { label: { fr: "Vibrations", en: "Vibes" } },
        },
        extraColumnSpace: 2
    },
    tighteningAndDousingSystem_pliersPhotos: {
        label: {
            fr: "Photos de pince",
            en: "Pliers photos"
        },
		category: ['ebc', 'kessler'],
		type: 'photo',
        directoryName: 'Systeme_de_serrage_et_arrosage',
    },
    tighteningAndDousingSystem_tighteningStrapPhoto: {
        label: {
            fr: "Photo tirant de serrage",
            en: "Tightening strap photo"
        },
		category: ['ebc'],
		type: 'photo',
        directoryName: 'Systeme_de_serrage_et_arrosage'
    },
    tighteningAndDousingSystem_dousingRodPhoto: {
        label: {
            fr: "Photo tige d'arrosage",
            en: "Dousing rod photo"
        },
		category: ['ebc', 'kessler'],
		type: 'photo',
        directoryName: 'Systeme_de_serrage_et_arrosage',
        extraColumnSpace: 1
    },
    tighteningAndDousingSystem_rotatingGasketPhoto: {
        label: {
            fr: "Photo joint tournant",
            en: "Rotating gasket photo"
        },
		category: ['ebc', 'kessler'],
		type: 'photo',
        directoryName: 'Systeme_de_serrage_et_arrosage'
    },
    tighteningAndDousingSystem_looseningUnitPhoto: {
        label: {
            fr: "Photo unité de desserrage",
            en: "Loosening unit photo"
        },
		category: ['ebc', 'kessler'],
		type: 'photo',
        directoryName: 'Systeme_de_serrage_et_arrosage'
    },
    tighteningAndDousingSystem_remarks: {
        label: {
            fr: "Remarques",
            en: "Remarks"
        },
        category: ['ebc', 'kessler'],
        type: "input",
        inputType: "textarea",
        columnWeight: 2
    },
    tighteningAndDousingSystem_nonexistent: {
        label: {
            fr: "Inexistant",
            en: "Nonexistent"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    tighteningAndDousingSystem_nonDisassembled: {
        label: {
            fr: "Non démonté",
            en: "Non-disassembled"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
};

export const tighteningAndDousingSystemFields = fieldsParser(_tighteningAndDousingSystemFields);