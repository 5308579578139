import fieldsParser from "../../fieldsParser"

export const _checklistDynamicTestsFields = {
    checklist_dynamicTests_bearingTemperature12: {
        label: {
            fr: "Température roulement 1",
            en: "Bearing temperature 1"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        unit: "°C",
        keys: ["standard"]
    },
    checklist_dynamicTests_bearingTemperature3: {
        label: {
            fr: "Température roulement 2",
            en: "Bearing temperature 2"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        unit: "°C",
        keys: ["standard"]
    },
    checklist_dynamicTests_bearingTemperature45: {
        label: {
            fr: "Température roulement 3",
            en: "Bearing temperature 3"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        unit: "°C",
        keys: ["standard"]
    }
};

export const checklistDynamicTestsFields = fieldsParser(_checklistDynamicTestsFields);