import fieldsParser from "@/formFields/fieldsParser"

export const _reportTableFeesFields = {
    reportTable_mealNumber: {
        label: {
            fr: "Nb repas",
            en: "Meal number"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        dynamic: true,
        keys: ["standard"]
    },
    reportTable_nightNumber: {
        label: {
            fr: "Nb nuit",
            en: "Night number"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        dynamic: true,
        keys: ["standard"]
    }
}

export const reportTableFeesFields = fieldsParser(_reportTableFeesFields)