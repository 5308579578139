import fieldsParser from "../../fieldsParser"

export const _conformityReportToolTighteningCheckFields = {
    conformityReport_toolTighteningCheck_minimumReleasePressure: {
        label: {
            fr: "Pression de desserage minimale",
            en: "Minimum release pressure"
        },
		category: ['ebc'],
		type: "input",
        inputType: "text",
        keys: ["standard"]
    },
    conformityReport_toolTighteningCheck_measuredReleasePressure: {
        label: {
            fr: "Pression de desserage mesurée",
            en: "Measured release pressure"
        },
		category: ['ebc'],
		type: "input",
        inputType: "text",
        keys: ["standard"]
    },
    conformityReport_toolTighteningCheck_comments: {
        label: {
            fr: "Commentaire",
            en: "Comments"
        },
		category: ['ebc'],
		type: "input",
        inputType: "textarea",
        columnWeight: 3,
        keys: ["standard"]
    },
};

export const conformityReportToolTighteningCheckFields = fieldsParser(_conformityReportToolTighteningCheckFields);