<style scoped>
    .conformity-report-title {
        font-weight: 600;
    }

    .two-column-div {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .three-column-div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .inside-container-list form {
        padding: 40px 10px 0px 5px;
        border: 1px solid rgb(220, 225, 230);
        margin: 10px;
    }

    .inside-container-list :deep(.checkbox-button-container) {
        margin-bottom: 13px;
    }

    .inside-container-list > div {
        position: relative;
        height: 215px;
    }

    .delete-air-oil-lubrification-button {
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 1;
        background-color: #FFF !important;
        color: #646e78 !important;
        border: 1px solid #646e78 !important;
    }

    .add-air-oil-lubrification-button-div {
        display: flex;
        justify-content: center;
    }

    .add-air-oil-lubrification-button {
        background-color: #FFFFFF;
        color: #646e78 !important;
        font-size: 80px !important;
    }

    .add-air-oil-lubrification-button svg {
        margin: auto;
    }

    #conformity-report-modal :deep(.modal-content) {
        overflow: hidden !important;
    }

    #conformity-report-modal :deep(.inputeo) {
        min-width: unset;
    }

    .item-title {
        position: absolute;
        top: 5px;
        left: 30px;
        font-weight: 600;
        color: rgb(50, 60, 70);
    }

    @media (max-width: 1200px) {
        .modal :deep(.button-text) {
            font-size: 10px;
        }

        .modal-bottom-title {
            font-size: 10px;
        }
    }
</style>

<template>
    <div>
        <Modal id="conformity-report-modal" mode="step" :fullscreen="true">
            <template v-slot:modalStep>
                <ModalStep 
                    ref="conformityReportModalStep"
                    step=""
                    @onExit="onExit"
                    @onPreviousStep="onConformityReportModalPreviousStep" 
                    @onNextStep="onConformityReportModalNextStep" 
                    @onValidate="onValidateConformityReportForm" 
                    @onSave="onSaveConformityReportForm" 
                    :autoPrevious="false" 
                    :autoNext="false" 
                    :displaySaveButton="displaySaveButton" 
                    mode="menu"
                    :menu="conformityReportMenu"
                    validateTextButton="Valider"
                    validateButtonClass="green-button"
                    saveTextButton="Enregistrer"
                >
                    <template v-slot:modalStepMenuTitle>
                        <div class="modal-bottom-title">
                            <div class="conformity-report-title">{{ `${affair.affairNumber} ${affair.spindle ? `${affair.spindle?.spindleType.spindleBrand.name} ${affair.spindle?.spindleType.name}` :''}` }}</div>
                        </div>
                    </template>
                    <template v-slot:modalStepItem>
                        <!-- CHECKLIST INFORMATIONS GÉNÉRALES -->
                        <ModalStepItem v-if="hasField('checkListGeneralInformationsFields')">
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="conformityReport"
                                    :fields="crForm.checkListGeneralInformationsFields"
                                    :fieldsPerRow="3"
                                    :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                    :keys="conformityReportKeys"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- CHECKLIST CONTRÔLE PRESSION -->
                        <ModalStepItem v-if="hasField('checkListLubrificationFields')">
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="conformityReport"
                                    :fields="crForm.checkListLubrificationFields"
                                    :fieldsPerRow="3"
                                    :keys="conformityReportKeys"
                                >
                                </CustomForm>
                                <div class="three-column-div inside-container-list">
                                    <div v-for='index in conformityReport.airOilLubrificationsCount' :key="index">
                                        <div class="item-title">Point de graissage {{ index }}</div>
                                        <BaseButton @click="function() { deleteAirOilLubrification(index) }" buttonText="" title="Supprimer le graissage air / huile" class="delete-air-oil-lubrification-button">
                                            <template v-slot:iconBefore>
                                                <font-awesome-icon :icon="['fas', 'times']" />
                                            </template>
                                        </BaseButton>
                                        <CustomForm
                                            v-model="conformityReport"
                                            :fields="crForm.checkListLubrificationsLubrificationFields"
                                            :fieldsPerRow="2"
                                            :index="index"
                                            checkboxColor="grey"
                                            :keys="conformityReportKeys"
                                        >
                                        </CustomForm>
                                    </div>
                                    <div class="add-air-oil-lubrification-button-div">
                                        <BaseButton @click="addAirOilLubrification" buttonText="" title="Ajouter un graissage air / huile" class="add-air-oil-lubrification-button">
                                            <template v-slot:iconBefore>
                                                <font-awesome-icon :icon="['fal', 'plus']" />
                                            </template>
                                        </BaseButton>
                                    </div>
                                </div>
                            </template>
                        </ModalStepItem>
                        <!-- CHECKLIST ÉQUILIBRAGE -->
                        <ModalStepItem v-if="hasField('checkListBalancingFields') || hasField('checklistDynamicTests')">
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="conformityReport"
                                    :fields="crForm.checkListBalancingFields"
                                    :fieldsPerRow="3"
                                    :keys="conformityReportKeys"
                                    :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                >
                                </CustomForm>
                                <div class="two-column-div inside-container-list" style="margin-bottom: 40px;">
                                    <div>
                                        <div class="item-title">Palier max</div>
                                        <CustomForm
                                            v-model="conformityReport"
                                            :fields="crForm.checkListBalancingMaxBearingFields"
                                            :fieldsPerRow="2"
                                            :keys="conformityReportKeys"
                                        >
                                        </CustomForm>
                                    </div>
                                    <div>
                                        <div class="item-title">Palier client</div>
                                        <CustomForm
                                            v-model="conformityReport"
                                            :fields="crForm.checkListBalancingCustomerBearingFields"
                                            :fieldsPerRow="2"
                                            :keys="conformityReportKeys"
                                        >
                                        </CustomForm>
                                    </div>
                                </div>
                                <CustomForm
                                    v-model="conformityReport"
                                    :fields="crForm.checklistDynamicTestsFields"
                                    :fields-per-row="2"
                                    :keys="conformityReportKeys"
                                    form-title="Tests dynamiques"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- CHECKLIST GÉOMÉTRIE -->
                        <ModalStepItem v-if="hasField('checkListGeometryFields')">
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="conformityReport"
                                    :fields="crForm.checkListGeometryFields"
                                    :fieldsPerRow="3"
                                    :keys="conformityReportKeys"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- CHECKLIST CAPTEUR -->
                        <ModalStepItem v-if="hasField('checkListSensorFields')">
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="conformityReport"
                                    :fields="crForm.checkListSensorFields"
                                    :fieldsPerRow="4"
                                    :keys="conformityReportKeys"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- CHECKLIST CAPTEUR -->
                        <ModalStepItem v-if="hasField('checkListEncoderMotorControlFields')">
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="conformityReport"
                                    :fields="crForm.checkListEncoderMotorControlFields"
                                    :fieldsPerRow="3"
                                    :keys="conformityReportKeys"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- CHECKLIST CONTRÔLE AVANT LIVRAISON -->
                        <ModalStepItem v-if="hasField('checkListCheckBeforeDeliveryFields')">
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="conformityReport"
                                    :fields="crForm.checkListCheckBeforeDeliveryFields"
                                    :fieldsPerRow="3"
                                    :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                    :keys="conformityReportKeys"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- PV DE CONFORMITÉ SPÉCIFICATIONS MOTEUR -->
                        <ModalStepItem v-if="hasField('conformityReportMotorSpecificationsFields') || hasField('conformityReportGeometryFields')">
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="conformityReport"
                                    :fields="crForm.conformityReportMotorSpecificationsFields"
                                    :fieldsPerRow="2"
                                    :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                    :keys="conformityReportKeys"
                                >
                                </CustomForm>
                                <CustomForm
                                    v-model="conformityReport"
                                    :fields="crForm.conformityReportGeometryFields"
                                    :fieldsPerRow="2"
                                    :keys="conformityReportKeys"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- PV DE CONFORMITE AUTRE -->
                        <ModalStepItem v-if="hasField('conformityReportToolTighteningCheckFields')">
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="conformityReport"
                                    :fields="crForm.conformityReportToolTighteningCheckFields"
                                    :fieldsPerRow="2"
                                    :keys="conformityReportKeys"
                                    formTitle="Contrôle serrage outil"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                        <!-- PV DE CONFORMITÉ ANNEXE -->
                        <ModalStepItem v-if="hasField('conformityReportAnnexFields')">
                            <template v-slot:modalContent>
                                <CustomForm
                                    v-model="conformityReport"
                                    :fields="crForm.conformityReportAnnexFields"
                                    :fieldsPerRow="2"
                                    :directoryPath="`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${affair.dropboxPath}`"
                                    :keys="conformityReportKeys"
                                >
                                </CustomForm>
                            </template>
                        </ModalStepItem>
                    </template>
                </ModalStep>
            </template>
        </Modal>

        <Dialog ref="dialog" />
    </div>
</template>

<script>
    import axios from 'axios';
    import Modal from "../../components/App/Modal";
    import BaseButton from "../../components/Base/BaseButton";
    import BaseInput from "../../components/Base/BaseInput";
    import BaseSelect from "../../components/Base/BaseSelect";
    import ModalStep from '../../components/App/ModalStep';
    import ModalStepItem from '../../components/App/ModalStepItem';
    import CustomForm from '../../components/App/CustomForm';
    import ListWithDelete from '../../components/App/ListWithDelete';
    import config from "../../config";
    import Dialog from '../../components/App/Dialog'
    import * as crForm from '../../formFields/conformityReport';
    import $ from 'jquery';
    import { dataUrlToFile } from "../../utils/dataUrlToFile";

    export default {
        name: 'ConformityReportModal',
        components: {
            config,
            BaseButton,
            BaseInput,
            BaseSelect,
            Modal,
            ModalStep,
            ModalStepItem,
            CustomForm,
            ListWithDelete,
            Dialog
        },
        emits: ['onExit', 'refresh', 'update:modelValue'],
        props: {
            modelValue: {
                type: Object,
                default: null
            },
        },
        data() {
            return {
                config,
                displayError: false,
                conformityReportMenu: [],
                crForm: crForm,
                affair: {},
                conformityReport: {
                    id: null,
                    report: null
                },
                displaySaveButton: false,
                conformityReportKeys: ['standard'],
                step: "1"
            }
        },
        methods: {
            loadConformityReport(affair) {
                this.affair = {
                    id: affair.id,
                    affairNumber: affair.affairNumber,
                    customer: affair.shipToCustomer,
                    spindle: affair.spindle
                };

                const dropboxBrand = this.affair && this.affair.spindle?.spindleType?.spindleBrand?.name || 'other';
                const dropboxType = this.affair && this.affair.spindle?.spindleType?.name || 'other';
                const dropBoxSerialNumber = this.affair && this.affair.spindle?.serialNumber || 'other';
                const dropBoxCustomer = this.affair && this.affair.customer?.callKey || 'other';
                this.affair.dropboxPath = (`Historique_affaires/${dropboxBrand}/${dropboxType}/${dropBoxSerialNumber}/${this.affair.affairNumber}_${dropboxBrand}_${dropboxType}_${dropBoxSerialNumber}_${dropBoxCustomer}/PV-conformite/Photos`)?.replace(/ /g, '-');

                this.conformityReportMenu = this.getMenuItems();

                this.$nextTick(() => {
                    this.$refs.conformityReportModalStep.initializeSteps("1");
                });

                this.displaySaveButton = !affair.conformityReport || !affair.conformityReport.validated;

                this.prepareEditConformityReport(affair.conformityReport);
            },
            prepareEditConformityReport(conformityReport) {
                this.conformityReport = JSON.parse(JSON.stringify(conformityReport.report));
                for(let key of Object.keys(this.conformityReport)) {
                    if(key === "airOilLubrificationsCount") {
                        continue;
                    }

                    let formKey = key.lastIndexOf('_') !== -1 ? key.slice(0,key.lastIndexOf('_')) : key;
                    if(!crForm.conformityReportFields[formKey] || (crForm.conformityReportFields[formKey] && !crForm.conformityReportFields[formKey].dynamic)) {
                        formKey = key;
                    }
                    
                    if (crForm.conformityReportFields[formKey] == null) continue;


                    if(crForm.conformityReportFields[formKey].type === 'select') {
                        if(crForm.conformityReportFields[key].multiple) {
                            let values = [];
                            for(let value of this.conformityReport[key]) {
                                values.push({
                                    value: value,
                                    label: crForm.conformityReportFields[formKey]?.defaultOptions?.[value]?.label.fr || value
                                });
                            }
                            this.conformityReport[key] = values;
                        }
                        else {
                            this.conformityReport[key] = {
                                value: this.conformityReport[key],
                                label: crForm.conformityReportFields[formKey]?.defaultOptions?.[this.conformityReport[key]]?.label.fr || this.conformityReport[key]
                            };
                        }
                    }
                }
                if(!this.conformityReport["airOilLubrificationsCount"]) {
                    this.conformityReport["airOilLubrificationsCount"] = 1;
                }
                this.conformityReport.id = JSON.parse(JSON.stringify(conformityReport.id));
            },
            onConformityReportModalPreviousStep(stepDetail) {
                this.$refs.conformityReportModalStep.showNextButton();
                this.updateConformityReport();
                this.$refs.conformityReportModalStep.previous(stepDetail.wantedStep, false);
                this.resetModalScroll();
            },
            onConformityReportModalNextStep(stepDetail) {
                this.updateConformityReport();
                this.$refs.conformityReportModalStep.next(stepDetail.wantedStep, false);
                this.resetModalScroll();
            },
            onValidateConformityReportForm() {
                this.$refs.dialog.show({
                    type: 'confirm',
                    title: 'Confirmation',
                    message: `Êtes-vous sûr de vouloir valider ce PV de conformité ?`
                }).then(() => {
                    this.updateConformityReport(true);
                })
                .catch(() => {});
            },
            onSaveConformityReportForm() {
                this.updateConformityReport();
            },
            addAirOilLubrification() {
                this.conformityReport.airOilLubrificationsCount++;
            },
            deleteAirOilLubrification(index) {
                for(let field of this.crForm.checkListLubrificationsLubrificationFields) {
                    delete this.conformityReport[`${field.name}_${index}`];
                }
                for(let i=index+1; i<=this.conformityReport.airOilLubrificationsCount; i++) {
                    for(let field of this.crForm.checkListLubrificationsLubrificationFields) {
                        this.conformityReport[`${field.name}_${i-1}`] = this.conformityReport[`${field.name}_${i}`];
                        delete this.conformityReport[`${field.name}_${i}`];
                    }
                }
                this.conformityReport.airOilLubrificationsCount--;
            },
            async formateConformityReport(conformityReport, onValidate = false) {
                let me = this;
                conformityReport = JSON.parse(JSON.stringify(conformityReport));

                let formData = new FormData();
                formData.append('id', conformityReport.id);

                if(onValidate) {
                    formData.append('validated', true);
                }
                
                delete conformityReport.id;
                delete conformityReport.validated;

                for(let key of Object.keys(conformityReport)) {
                    if(key === "airOilLubrificationsCount") {
                        continue;
                    }
                    
                    let formKey = key.lastIndexOf('_') !== -1 ? key.slice(0,key.lastIndexOf('_')) : key;
                    
                    if(!me.crForm.conformityReportFields[formKey] || (me.crForm.conformityReportFields[formKey] && !me.crForm.conformityReportFields[formKey].dynamic)) {
                        formKey = key;
                    }
                    
                    if(conformityReport[key] && me.crForm.conformityReportFields[formKey] && me.crForm.conformityReportFields[formKey].type === 'select') {
                        if(me.crForm.conformityReportFields[key].multiple) {
                            let values = [];
                            for(let value of conformityReport[key]) {
                                values.push(value.value);
                            }
                            conformityReport[key] = values;
                        }
                        else {
                            conformityReport[key] = conformityReport[key].value;
                        }
                    }
                    else if(conformityReport[key] && me.crForm.conformityReportFields[formKey].type === 'photo') {
                        let files = [];
                        let filenames = [];
                        for(let file of conformityReport[key]) {
                            // si le fichier est en base64, on va l'envoyer à part dans le formData sous forme de fichier
                            if(file.startsWith('data:image/')) {
                               files.push(file);
                            }
                            // sinon, c'est un nom de fichier déjà existant et on va juste l'envoyer dans le json du rapport
                            else {
                                filenames.push(file);
                            }
                        }
                        await Promise.all(files.map(async (base64Image, index) => { formData.append(key, await dataUrlToFile(base64Image, `tmp-${index}`)); }));
                        conformityReport[key] = filenames;
                    }
                    if(conformityReport[key] == null || (typeof conformityReport[key] === 'string' && conformityReport[key]?.trim().length === 0) || (Array.isArray(conformityReport[key]) && conformityReport[key]?.length === 0)) {
                        delete conformityReport[key];
                    }
                }

                formData.append('report', JSON.stringify(conformityReport));

                return formData;
            },
            async updateConformityReport(onValidate = false) {
                let me = this;
                axios
                .put('conformityReport/update', await me.formateConformityReport(this.conformityReport, onValidate), { 
                    toastSuccessMessage: onValidate ? `PV de conformité enregistré` : null, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then((response) => {
                    let conformityReportUpdated = response.data;

                    this.$emit('refresh');

                    if(onValidate) {
                        this.onExit();
                            this.$refs.conformityReportModalStep.exit();
                        this.resetModalScroll();
                    }

                    this.$emit('update:modelValue', conformityReportUpdated);
                    this.prepareEditConformityReport(conformityReportUpdated);
                })
                .catch(() => {
                });
            },
            resetModalScroll() {
                $('#conformity-report-modal .modal-step-item-container.mode-menu').scrollTop(0);
            },
            onExit() {
                this.$emit('onExit');
                this.resetModalScroll();
                this.$refs.conformityReportModalStep.showNextButton();
            },
            getMenuItems() {
                let menuItems = [];

                if (this.hasField('checkListGeneralInformationsFields')) menuItems.push({ label: 'Checklist - Informations générales', value: menuItems.length + 1 });
                if (this.hasField('checkListLubrificationFields')) menuItems.push({ label: 'Checklist - Contrôle pression', value: menuItems.length + 1 });
                if (this.hasField('checkListBalancingFields') || this.hasField('checklistDynamicTests')) menuItems.push({ label: 'Checklist - Équilibrage', value: menuItems.length + 1 });
                if (this.hasField('checkListGeometryFields')) menuItems.push({ label: 'Checklist - Géométrie', value: menuItems.length + 1 });
                if (this.hasField('checkListSensorFields')) menuItems.push({ label: 'Checklist - Capteurs', value: menuItems.length + 1 });
                if (this.hasField('checkListEncoderMotorControlFields')) menuItems.push({ label: 'Checklist - Contrôle moteur encodeur', value: menuItems.length + 1 });
                if (this.hasField('checkListCheckBeforeDeliveryFields')) menuItems.push({ label: 'Checklist - Contrôle avant livraison', value: menuItems.length + 1 });
                if (this.hasField('conformityReportMotorSpecificationsFields') || this.hasField('conformityReportGeometryFields')) menuItems.push({ label: 'PV de conformité - Spécifications moteur', value: menuItems.length + 1 });
                if (this.hasField('conformityReportToolTighteningCheckFields')) menuItems.push({ label: 'PV de conformité - Divers', value: menuItems.length + 1 });
                if (this.hasField('conformityReportAnnexFields')) menuItems.push({ label: 'PV de conformité - Annexe', value: menuItems.length + 1 });

                return menuItems;
            },
            hasField(stepName) {
                return crForm[stepName].filter(field => !field?.keys?.length > 0 || field.keys.filter(key => this.conformityReportKeys.indexOf(key) !== -1).length > 0).length > 0;
            }
        }
    }
</script>