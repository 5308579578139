import fieldsParser from "../../../fieldsParser";

export const _bearingsCompartmentsPhotosFields = {
    bearings_compartments_photosPhoto: {
        label: {
            fr: "Photos des logements des roulements",
            en: "Bearings compartments photos"
        },
		category: ['ebc'],
		type: 'photo',
        directoryName: 'Roulements_logement'
    }
};

export const bearingsCompartmentsPhotosFields = fieldsParser(_bearingsCompartmentsPhotosFields);