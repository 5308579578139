<style scoped>
    .messages-list {
        display: flex;
        flex-direction: column;
        padding: 35px 35px 175px 35px;
        width: 60vw;
        overflow-y: auto;
        overflow-y: overlay;
        scrollbar-color: rgb(120, 130, 140);
        scrollbar-width: thin;
    }

    .messages-list::-webkit-scrollbar {
        width: 6px;
    }

    .messages-list::-webkit-scrollbar-track {
        background: transparent;
    }

    .messages-list::-webkit-scrollbar-thumb {
        background: rgb(120, 130, 140);
    }

    .messages-list::-webkit-scrollbar-thumb:hover {
        background: rgb(100, 110, 120);
    }

    .message-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
    }

    .current-user-message {
        align-items: flex-end;
    }

    .message-created-infos {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
    }

    .current-user-message .message-createdBy {
        color: #1e73be;
    }

    .message-createdBy {
        margin-right: 10px;
        font-weight: 600;
    }

    .message-createdAt {
        font-weight: 400;
    }

    .message-content {
        padding: 12px;
        width: 30vw;
        border-radius: 15px;
        background-color: #f8f8f8;
        box-shadow: -5px 2px 8px rgba(100, 110, 120, 0.2);
        white-space: pre-wrap;
    }

    .current-user-message .message-content {
        background-color: #d6e9fa;
        box-shadow: 5px 2px 8px rgba(100, 110, 120, 0.2);
    }

    .add-message-container {
        padding: 35px 35px 0 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }

    .add-message-container .inputeo {
        width: 50vw;
    }

    .add-message-container button {
        border-radius: 20px;
    }

    .message-modal-buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>

<template>
    <Modal :hideIcon="true">
        <template v-slot:modalTitle>
            <div>{{ title }}</div>
        </template>
        <template v-slot:modalContent>
            <div class="messages-list">
                <div v-for="message in modelValue" v-bind:key="message.id" :class="'message-container' + (message.createdBy.id === $store.state.authentication.user.id ? ' current-user-message' : '')">
                    <div class="message-created-infos">
                        <div class="message-createdBy">{{ message.createdBy.firstname }} {{ message.createdBy.lastname }}</div>
                        <div class="message-createdAt"> {{ message.createdAt }}</div>
                    </div>
                    <div class="message-content">{{ message.content }}</div>
                </div>
            </div>
        </template>
        <template v-slot:modalButtons>
            <div class="message-modal-buttons">
                <div class="add-message-container">
                    <BaseInput
                        v-model="newMessageContent"
                        placeholder="Saisissez votre message"
                        type="textarea"
                        name="messageContent"
                    >
                    </BaseInput>
                    <BaseButton class="blue-button" @click="onSendMessage" buttonText="Envoyer">
                        <template v-slot:iconBefore>
                            <font-awesome-icon :icon="['fas', 'paper-plane']" />
                        </template>
                    </BaseButton>
                </div>
                <div>
                    <BaseButton class="white-button" @click="closeModal" buttonText="Fermer">
                        <template v-slot:iconBefore>
                            <font-awesome-icon :icon="['fas', 'times']" />
                        </template>
                    </BaseButton>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
    import Modal from './Modal'
    import BaseButton from '../Base/BaseButton'
    import BaseInput from '../Base/BaseInput'
    import $ from 'jquery'

    export default {
        name: 'MessagesModal',
        components: {
            Modal,
            BaseButton,
            BaseInput
        },
        props: {
            title: {
                type: String,
                default: 'Fil de discussion'
            },
            modelValue: {
                type: Array,
                default: []
            }
        },
        watch: {
            modelValue: { 
                handler(modelValue) {
                    // quand un message est ajouté à la liste (donc qu'il vient d'être créé), on vide le champ de saisie
                    this.resetNewMessageContent();
                    $('.messages-list').animate({scrollTop: $('.messages-list').get(0).scrollHeight});
                },
                deep: true
            }
        },
        data() {
            return {
                newMessageContent: null
            }
        },
        methods: {
            onSendMessage() {
                if(this.newMessageContent != null && this.newMessageContent.trim().length > 0) {
                    this.$emit('onSendMessage', this.newMessageContent);
                }
            },
            closeModal() {
                this.$emit('close');

            },
            resetNewMessageContent() {
                this.newMessageContent = null;
            }
        }
    }
</script>