<style scoped>
    .photos-label {
        font-size: 14px;
        margin: 7px 15px 0px 15px;
        transition: 0.1s ease;
        color: rgb(100, 110, 120);
        text-transform: initial;
    }

    .photos-and-input {
        display: flex;
        align-items: flex-start;
        padding: 10px 0px 0px 10px;
        margin-bottom: 10px;
    }

    .photos {
        display: flex;
    }

    .report-format {
        flex-direction: column;
    }

    .report-format .photos {
        justify-content: space-around;
        flex-wrap: wrap;
    }

    .empty-photo {
        width: 85px;
        height: 85px;
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        flex-shrink: 0;
        margin-right: 10px;
        box-sizing: border-box;
        border: 1px dashed rgb(180, 190, 200);
    }

    .take-pictures-btn {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    .take-pictures-btn + label {
        font-weight: 500;
        border: none;
        color: white;
        font-size: 12px;
        padding: 9px 15px;
        border-radius: 4px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: 0.15s ease;
        background: linear-gradient(90deg, #ff6b00, #ff9100);
        font-family: Montserrat;
    }

    .take-pictures-btn + label svg {
        margin-right: 6px;
    }

    .photo-title {
        margin-left: 10px;
        font-weight: 500;
    }

    .photo-button {
        margin: 10px;
    }

    .photo-title.no-photo {
        margin-bottom: 10px;
    }

    .container-photos {
        margin-left: 15px;
        margin-bottom: 10px;
    }
</style>

<template>
    <div class="container-photos">
        <div class="photos-label" v-if="label">
            {{ label }}
        </div>

        <div class="photos-and-input" :class="{ 'report-format': reportFormat }">
            <div class="photos">
                <Photo v-for="[index, photo] of modelValue?.entries()" v-bind:key="index" :image="photo.startsWith('data:') ? photo : `${directoryPath}/${photo}`" :index="String(index)" :buttonsPosition="photoButtons" @deletePhoto="onDeletePhoto" v-if="modelValue?.entries">
                    <template v-slot:title>
                        <div class="photo-title">{{ title }}</div>
                    </template>
                    <template v-slot:extraButtons>
                        <div class="photo-button" v-if="photoButtons === 'right'">
                            <input class="take-pictures-btn" :id="id" :type="$store.getters.isFromAppTablet ? 'button' : 'file'" accept="image/png, image/gif, image/jpeg, image/jpg" capture @click="tabletChooser" @change="(event) => $store.getters.isFromAppTablet ? '' : takePicture(event)" />
                            <label :for="id">
                                <font-awesome-icon :icon="['fas', 'camera']" />
                                Photo
                            </label>
                        </div>
                    </template>
                </Photo>
                <div class="empty-photo" v-if="!limit || (limit && (modelValue == null || modelValue.length < limit))"></div>
            </div>

            <div>
                <div v-if="(modelValue == null || modelValue?.length === 0) && photoButtons === 'right'" class="photo-title no-photo">{{ title }}</div>
                <div class="photo-button" v-if="(photoButtons === 'bottom' || modelValue == null || modelValue?.length === 0)"> <!-- && (!limit || (limit && (modelValue == null || modelValue.length < limit))) -->
                    <input class="take-pictures-btn" :id="id" :type="$store.getters.isFromAppTablet ? 'button' : 'file'" accept="image/png, image/gif, image/jpeg, image/jpg" capture @click="tabletChooser" @change="(event) => $store.getters.isFromAppTablet ? '' : takePicture(event)" />
                    <label :for="id">
                        <font-awesome-icon :icon="['fas', 'camera']" />
                        Photo
                    </label>
                </div>
            </div>
        </div>
    </div>
    <PhotoSourceChooser :display-tablet-source-chooser="displayTabletSourceChooser" @inputFile="takePicture" @closeModal="closeTabletChooserModal"/>
</template>

<script>
    import Photo from "../../components/App/Photo";
    import PhotoSourceChooser from "@/components/App/PhotoSourceChooser.vue";
    import store from "@/store";

    export default {
        name: 'PhotosWithInput',
        components: {
            PhotoSourceChooser,
            Photo
        },
        props: {
            modelValue: {
                type: Array,
                default: null
            },
            label: {
                type: String,
                default: null
            },
            limit: {
                type: Number,
                default: null
            },
            directoryPath: {
                type: String,
                default: ''
            },
            photoButtons: {
                type: String,
                default: 'bottom'
            },
            title: {
                type: String,
                default: ''
            },
            style: {
                type: String,
                default: ''
            },
            reportFormat: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            modelValue: {
                handler() {
                }
            }
        },
        data() {
            return {
                id: null,
                displayTabletSourceChooser: false
            }
        },
        mounted() {
            this.id = Math.floor(Math.random() * Date.now());
        },
        methods: {
            tabletChooser(event) {
                if (!store.getters.isFromAppTablet) return;

                this.displayTabletSourceChooser = true
            },
            takePicture(event) {
                this.displayTabletSourceChooser = false;

                let self = this;

                var file = null;
                if (event.srcElement) {
                    file = event.srcElement.files[0];
                }
                else {
                    file = event;
                }

                var reader = new FileReader();
                if (file != null) reader.readAsDataURL(file);

                reader.onload = function() {
                    let files = [];
                    if(self.modelValue) {
                        files = [...self.modelValue];
                    }

                    if(self.limit === 1) {
                        files = [reader.result];
                    }
                    else {
                        files.push(reader.result);
                    }

                    self.$emit('update:modelValue', files);
                };
            },
            onDeletePhoto(index) {
                let files = [];
                if(this.modelValue) {
                    files = [...this.modelValue];
                }
                files?.splice(index, 1);
                this.$emit('update:modelValue', files);
            },
            closeTabletChooserModal() {
                this.displayTabletSourceChooser = false;
            }
        }
    }
</script>