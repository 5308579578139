import fieldsParser from "../../fieldsParser";

export const _rotorShaftSpindleAxisFields = {
    rotorShaft_spindleAxis_nonexistent: {
        label: {
            fr: "Inexistant",
            en: "Nonexistent"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    rotorShaft_spindleAxis_nonDisassembled: {
        label: {
            fr: "Non démonté",
            en: "Non-disassembled"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    rotorShaft_spindleAxis_frontRMeasurement: {
        label: {
            fr: "R avant mesurée",
            en: "Front R measurement"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    rotorShaft_spindleAxis_backRMeasurement: {
        label: {
            fr: "R arrière mesurée",
            en: "Back R measurement"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    rotorShaft_spindleAxis_R1Measurement: {
        label: {
            fr: "R1 mesurée",
            en: "R1 measurement"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    rotorShaft_spindleAxis_fitting: {
        label: {
            fr: "Ajustage",
            en: "Fitting"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    rotorShaft_spindleAxis_repairableAxis: {
        label: {
            fr: "Axe réparable",
            en: "Repairable axis"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    rotorShaft_spindleAxis_coneRectification: {
        label: {
            fr: "Rectification du cône",
            en: "Cone rectification"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    rotorShaft_spindleAxis_faceRectification: {
        label: {
            fr: "Rectification de la face",
            en: "Face rectification"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    rotorShaft_spindleAxis_chromerPorteesRoulements: {
        label: {
            fr: "Chromer portées roulements",
            en: "Chromer portées roulements"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    rotorShaft_spindleAxis_thermalDistortion: {
        label: {
            fr: "Déformation thermique",
            en: "Thermal distortion"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    rotorShaft_spindleAxis_chromeCone: {
        label: {
            fr: "Chromer cône",
            en: "Chrome cone"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    rotorShaft_spindleAxis_straightenAxis: {
        label: {
            fr: "Redresser axe",
            en: "Straighten axis"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    rotorShaft_spindleAxis_chromerPorteeRessorts: {
        label: {
            fr: "Chromer portée ressorts",
            en: "Chromer portée ressorts"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    rotorShaft_spindleAxis_contactRust: {
        label: {
            fr: "Rouille de contact",
            en: "Contact rust"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    rotorShaft_spindleAxis_comparatorMeasurementPhoto: {
        label: {
            fr: "Mesure comparateur",
            en: "Comparator measurement"
        },
		category: ['kessler'],
		type: 'photo',
        directoryName: 'Arbre-rotor',
        columnWeight: 2
    }
};

export const rotorShaftSpindleAxisFields = fieldsParser(_rotorShaftSpindleAxisFields);