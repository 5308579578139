import fieldsParser from "../../fieldsParser";

export const _sensorsFaultsCausesFields = {
    sensors_faultsCauses_oilyAtmosphere: {
        label: {
            fr: "Atmosphère huileuse",
            en: "Oily atmosphere"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    sensors_faultsCauses_lastRepairs: {
        label: {
            fr: "Réparation précédente",
            en: "Last repairs"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    sensors_faultsCauses_flood: {
        label: {
            fr: "Inondation",
            en: "Flood"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    sensors_faultsCauses_scalpedByRing: {
        label: {
            fr: "Détérioration par bague",
            en: "Scalped by ring"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    sensors_faultsCauses_normalWear: {
        label: {
            fr: "Usure normale",
            en: "Normal wear"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    sensors_faultsCauses_overvoltage: {
        label: {
            fr: "Surtension",
            en: "Overvoltage"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } }
        }
    },
    sensors_damaged_cables: {
        label: {
            fr: "Câbles endommagés",
            en: "Damaged cables"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } }
        }
    },
};

export const sensorsFaultsCausesFields = fieldsParser(_sensorsFaultsCausesFields);