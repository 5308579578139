import fieldsParser from "../../../fieldsParser";

export const _bearingsStrutsAndOilChannelsFaultsCausesFields = {
    bearings_strutsAndOilChannels_faultsCauses_collision: {
        label: {
            fr: "Collision",
            en: "Collision"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    bearings_strutsAndOilChannels_faultsCauses_machiningEfforts: {
        label: {
            fr: "Efforts d'usinage",
            en: "Machining efforts"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    bearings_strutsAndOilChannels_faultsCauses_wear: {
        label: {
            fr: "Usure",
            en: "Wear"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    bearings_strutsAndOilChannels_faultsCauses_badLubrication: {
        label: {
            fr: "Mauvaise lubrification",
            en: "Bad lubrication"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    bearings_strutsAndOilChannels_faultsCauses_vibrations: {
        label: {
            fr: "Vibrations",
            en: "Vibrations"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    bearings_strutsAndOilChannels_faultsCauses_pollution: {
        label: {
            fr: "Pollution",
            en: "Pollution"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    bearings_strutsAndOilChannels_faultsCauses_lastRepairsNonCompliant: {
        label: {
            fr: "Réparation précédente non conforme",
            en: "Last repairs non-compliant"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
};

export const bearingsStrutsAndOilChannelsFaultsCausesFields = fieldsParser(_bearingsStrutsAndOilChannelsFaultsCausesFields);