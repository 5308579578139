import fieldsParser from "../../fieldsParser"

export const _checklistLubrificationsLubrificationFields = {
    checklist_lubrification_lubrifications_mano: {
        label: {
            fr: "Mano (bars)",
            en: "Mano (bars)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        dynamic: true,
        keys: ["standard"],
        negative: false
    },
    checklist_lubrification_lubrifications_mwm: {
        label: {
            fr: "MWM (bars)",
            en: "MWM (bars)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        dynamic: true,
        keys: ["standard"],
        negative: false
    },
    checklist_lubrification_lubrifications_airFlow: {
        label: {
            fr: "Débit air (l/min)",
            en: "Air flow (l/min)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        dynamic: true,
        keys: ["standard"],
        negative: false
    }
};

export const checkListLubrificationsLubrificationFields = fieldsParser(_checklistLubrificationsLubrificationFields);