<style scoped>

    .affair-number {
        font-weight: 600;
        color: rgb(50, 60, 70);
        font-size: 17px;
        text-align: center;
    }

    .affair-status {
        display: flex;
        align-items: center;
        background: linear-gradient(90deg, #1e73be11, #00a1cf11);
        width: -moz-fit-content;
        width: fit-content;
        padding: 5px 10px;
        border-radius: 4px;
        margin: 10px auto 15px auto;
    }

    .affair-status span {
        background: -webkit-linear-gradient(#1e73be, #00a1cf);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600;
    }

    .affair-status-icon {
        color: #1e73be;
        width: 14px;
        height: 14px;
        margin-right: 8px;
    }

    .affair-info {
        display: flex;
        justify-content: space-between;
        margin: 14px 0;
    }

    .affair-info span:first-child {
        font-size: 12px;
        color: rgb(50, 60, 70);
        padding-right: 10px;
        flex: 1;
        box-sizing: border-box;
    }

    .affair-info span:last-child {
        text-align: right;
        font-size: 14px;
        color: rgb(30, 40, 50);
        font-weight: 600;
        flex: 1;
        box-sizing: border-box;
        padding-left: 10px;
    }

    .card-button {
        font-weight: 600;
        border: none;
        color: white;
        font-size: 11px;
        padding: 10px 15px;
        border-radius: 4px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        width: 100%;
        text-align: left;
        margin-top: 10px;
        font-family: Montserrat;
    }
</style>
<style>
    .badge-messages {
        position: absolute;
        background-color: #DD0000;
        font-size: 11px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        right: -5px;
        bottom: 0px;
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>

<template>

    <Card>
        <div class="affair-number">
            {{ affair.affairNumber }}
        </div>
        <div class="affair-status" :class="statusColor">
            <font-awesome-icon :icon="$store.getters.getAffairStatusList[affair.status].icon" class="affair-status-icon"/>
            <span>{{ $store.getters.getAffairStatusList[affair.status].label }}</span>
        </div>

        <hr>

        <div class="affair-info">
            <span>Date de réception de la broche</span>
            <span>{{ affair.receiptDate }}</span>
        </div>
        <div class="affair-info">
            <span>Échéance de la prochaine tâche à effectuer</span>
            <span>{{ affair.deadlineNextTask }}</span>
        </div>
        <div class="affair-info">
            <span>Client facturé</span>
            <span>{{ affair.billToCustomer?.name }}</span>
        </div>
        <div class="affair-info">
            <span>Marque de la broche</span>
            <span>{{ affair.spindle?.spindleType.spindleBrand.name }}</span>
        </div>
        <div class="affair-info">
            <span>Type de broche</span>
            <span>{{ affair.spindle?.spindleType.name }}</span>
        </div>
        <div class="affair-info">
            <span>Type de prestation</span>
            <span>{{ affair.repairType.toUpperCase() }}</span>
        </div>
        <div class="affair-info" v-if="affair.establishment != null">
            <span>Établissement</span>
            <span>{{ affair.establishment.toUpperCase() }}</span>
        </div>
        <div class="affair-info">
            <span>Temps passé sur la broche</span>
            <span>{{ affair.affairDuration }}</span>
        </div>
        <div class="affair-info" v-if="affair.repairType !== 'issb'">
            <span>Expertise complétée</span>
            <span>{{ affair?.expertAssessment?.completed ? 'Oui' : 'Non' }}</span>
        </div>
        <div class="affair-info" v-if="affair.repairType !== 'issb'">
            <span>Expertise validée</span>
            <span>{{ affair?.expertAssessment?.validated ? 'Oui' : 'Non' }}</span>
        </div>

        <BaseButton class="card-button" @click="onOpenExpertAssessmentModal" :buttonText="`${affairStatusEnum.PENDING_EXPERT_ASSESSMENT === affair.status ? 'Créer' : 'Compléter'} le rapport d'expertise`" buttonClass="orange-button" v-if="affair.repairType !== 'issb' && [affairStatusEnum.PENDING_EXPERT_ASSESSMENT, affairStatusEnum.ONGOING_EXPERT_ASSESSMENT, affairStatusEnum.PENDING_EXPERT_ASSESSMENT_VALIDATION, affairStatusEnum.NOMENCLATURE_VALIDATED_PENDING_EXPERT_ASSESSMENT, affairStatusEnum.PENDING_QUOTATION].includes(affair.status)">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'file-check']" />
            </template>
        </BaseButton>
        <BaseButton class="card-button" @click="onOpenInterventionReportModal" :buttonText="`${affairStatusEnum.PENDING_EXPERT_ASSESSMENT === affair.status ? 'Créer' : 'Compléter'} le rapport d'intervention`" buttonClass="orange-button" v-if="affair.repairType === 'issb' && [affairStatusEnum.PENDING_EXPERT_ASSESSMENT, affairStatusEnum.ONGOING_EXPERT_ASSESSMENT, affairStatusEnum.PENDING_EXPERT_ASSESSMENT_VALIDATION].includes(affair.status)">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'file-check']" />
            </template>
        </BaseButton>
        <BaseButton class="card-button" @click="onOpenDocumentation" buttonText="Voir la documentation" buttonClass="blue-button" v-if="affairStatusEnum.PENDING_ADMIN_VALIDATION !== affair.status && !$store.getters.isTechnicianManager">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'search']" />
            </template>
        </BaseButton>
        <BaseButton class="card-button" @click="onOpenMessages" buttonText="Accéder au fil de discussion" buttonClass="green-button">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'comment-check']" />
            </template>
            <template v-slot:badge v-if="affair.newMessages > 0">
                <div class="badge-messages">{{ affair.newMessages }}</div>
            </template>
        </BaseButton>
        <BaseButton class="card-button" @click="onOpenConformityReportModal" :buttonText="`${affairStatusEnum.PENDING_TESTS === affair.status ? 'Créer' : 'Compléter'} le PV de conformité`" buttonClass="purple-button" v-if="(affair?.expertAssessment?.validated || ['issm', 'issb'].includes(affair?.repairType)) && !([affairStatusEnum.PENDING_EXPERT_ASSESSMENT, affairStatusEnum.PENDING_ADMIN_VALIDATION, affairStatusEnum.PENDING_EXPERT_ASSESSMENT_PLANNING].includes(affair.status)) ">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'file-invoice']" />
            </template>
        </BaseButton>
        <BaseButton class="card-button" @click="onDownloadExpertAssessmentPDF" buttonText="Télécharger le rapport d'expertise" buttonClass="dark-blue-button" v-if="affair?.expertAssessment?.validated">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'file-download']" />
            </template>
        </BaseButton>
    </Card>

</template>

<script>

    import Card from "../../components/App/Card";
    import BaseButton from "../../components/Base/BaseButton";
    import affairStatusEnum from "../../enums/affairStatusEnum";

    export default {
        name: 'TabletAffair',
        components: {
            Card,
            BaseButton
        },
        props: {
            affair: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {
                affairStatusEnum,
                statusColor: 'blue'
            }
        },
        mounted() {

        },
        methods: {
            onOpenDocumentation() {
                this.affair.documents && 
                    this.$emit('onOpenDocumentation', this.affair.documents);
            },
            onOpenExpertAssessmentModal() {
                this.$emit('onOpenExpertAssessmentModal', this.affair.id, 'edit');
            },
            onOpenInterventionReportModal() {
                this.$emit('onOpenInterventionReportModal', this.affair.id);
            },
            onOpenConformityReportModal() {
                this.$emit('onOpenConformityReportModal', this.affair.id);
            },
            onOpenMessages() {
                this.$emit('onOpenMessages', this.affair.id);
            },
            onDownloadExpertAssessmentPDF() {
                this.$emit('onDownloadExpertAssessmentPDF', this.affair.id, 'download');
            }
        }
    }

</script>