import fieldsParser from "@/formFields/fieldsParser"

export const _reportTableReturnFields = {
    reportTable_hoursReturnFrom: {
        label: {
            fr: "de",
            en: "from"
        },
        category: ['ebc'],
        type: "time",
        dynamic: true,
        keys: ["standard"]
    },
    reportTable_hoursReturnTo: {
        label: {
            fr: "à",
            en: "to"
        },
        category: ['ebc'],
        type: "time",
        dynamic: true,
        keys: ["standard"]
    },
    reportTable_kms_return: {
        label: {
            fr: "KMS retour",
            en: "KMS return"
        },
        category: ['ebc'],
        type: "input",
        dynamic: true,
        keys: ["standard"]
    }
}

export const reportTableReturnFields = fieldsParser(_reportTableReturnFields)