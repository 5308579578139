import fieldsParser from "../../../fieldsParser";

export const _bearingsCompartmentsBackCaseFields = {
    bearings_compartments_backCase_visualExamination: {
        label: {
            fr: "Examen visuel",
            en: "Visual examination"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    bearings_compartments_backCase_visualExaminationValue: {
        label: {
            fr: "Examen visuel (valeur)",
            en: "Visual examination (Value)"
        },
        category: ['ebc'],
        type: "input",
        extraColumnSpace: 2
    },
    bearings_compartments_backCase_validBoring: {
        label: {
            fr: "Alésage",
            en: "Valid boring"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    bearings_compartments_backCase_boring1: {
        label: {
            fr: "Alésage 1 (Ø mm)",
            en: "Boring 1 (Ø mm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number"
    },
    bearings_compartments_backCase_boring2: {
        label: {
            fr: "Alésage 2 (Ø mm)",
            en: "Boring 2 (Ø mm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number"
    },
    bearings_compartments_backCase_boring3: {
        label: {
            fr: "Alésage 3 (Ø mm)",
            en: "Boring 3 (Ø mm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number"
    },
    bearings_compartments_backCase_supports: {
        label: {
            fr: "Battement de l'appui",
            en: "Supports"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    bearings_compartments_backCase_supportsValue: {
        label: {
            fr: "Battement de l'appui (µm)",
            en: "Supports (µm)"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        extraColumnSpace: 2
    },
    bearings_compartments_backCase_photo: {
        label: {
            fr: "Photo du boitier arrière",
            en: "Back case photo"
        },
        type: 'photo',
        directoryName: 'Boitier_arriere',
        rowWeight: 3,
        extraColumnSpace: 1
    },
    bearings_compartments_backCase_wasteOilsDrainage: {
        label: {
            fr: "Drainage des huiles usées",
            en: "Waste oils drainage"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    bearings_compartments_backCase_boringOfExpansionBearingOrWaterMantleOrSheath: {
        label: {
            fr: "Alésage du palier de dilatation",
            en: "Boring of expansion bearing"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    bearings_compartments_backCase_boringOfExpansionBearingOrWaterMantleOrSheathValue: {
        label: {
            fr: "Alésage du palier de dilatation (mm)",
            en: "Boring of expansion bearing (mm)"
        },
        category: ['ebc'],
        type: "input",
        columnWeight: 1
    },
};

export const bearingsCompartmentsBackCaseFields = fieldsParser(_bearingsCompartmentsBackCaseFields);