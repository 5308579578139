import fieldsParser from "../../fieldsParser"

export const _checklistEncoderMotorControlFields = {
    checklist_encoderMotorControl_postBreakInEngineCheck: {
        label: {
            fr: "Contrôle du moteur post-rodage (voir rapport annexe)",
            en: "Post-break-in engine check (see attached report)"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        columnWeight: 2,
        keys: ["standard"]
    },
    checklist_encoderMotorControl_temperatureProbesKty: {
        label: {
            fr: "Sondes température KTY (Ω)",
            en: "Temperatures probes KTY (Ω)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_encoderMotorControl_temperatureProbesKty_location: {
        label: {
            fr: "Emplacement des sondes",
            en: "Probe location"
        },
		category: ['ebc'],
		type: "input",
        inputType: "text",
        keys: ["standard"]
    },
    checklist_encoderMotorControl_temperatureProbesMotor: {
        label: {
            fr: "Sondes température moteur (Ω)",
            en: "Temperature probes motor (Ω)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_encoderMotorControl_temperatureProbesMotor_location: {
        label: {
            fr: "Emplacement des sondes",
            en: "Probe location"
        },
		category: ['ebc'],
		type: "input",
        inputType: "text",
        keys: ["standard"]
    },
    checklist_encoderMotorControl_temperatureProbesOther: {
        label: {
            fr: "Sondes température autre",
            en: "Temperatures probes other"
        },
		category: ['ebc'],
        inputType: "text",
        keys: ["standard"],
    },
    checklist_encoderMotorControl_temperatureProbesOther_location: {
        label: {
            fr: "Emplacement des sondes",
            en: "Probe location"
        },
		category: ['ebc'],
		type: "input",
        inputType: "text",
        keys: ["standard"]
    },
    checklist_encoderMotorControl_synchronousMotorFem_1: {
        label: {
            fr: "FEM moteurs synchrones (V)",
            en: "Synchronous motors FEM (V)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    checklist_encoderMotorControl_synchronousMotorFem_2: {
        label: {
            fr: "FEM moteurs synchrones (rpm)",
            en: "Synchronous motors FEM (rpm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    
    checklist_encoderMotorControl_electricalAngle: {
        label: {
            fr: "Angle électrique (en degrés)",
            en: "Electrical angle (in degrees)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"]
    },
    checklist_encoderMotorControl_mechanicalAngle: {
        label: {
            fr: "Angle mécanique (en degrés)",
            en: "Mechanical angle (in degrees)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"]
    },
    checklist_encoderMotorControl_checkingEarthConnection: {
        label: {
            fr: "Contrôle de la reprise de masse sur la broche et les différents câbles",
            en: "Checking the earth connection on the spindle and the different cables"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_encoderMotorControl_checkingEncoderAxialPosition: {
        label: {
            fr: "Vérification de la position axiale de l'encodeur",
            en: "Checking the axial position of the encoder"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_encoderMotorControl_postRunInEncoderCheck: {
        label: {
            fr: "Contrôle de l'encodeur post-rodage (voir rapport annexe)",
            en: "Post-run-in encoder check (see attached report)"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_encoderMotorControl_amplitude_A: {
        label: {
            fr: "Amplitude A",
            en: "Amplitude A"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: true
    },
    checklist_encoderMotorControl_amplitude_B: {
        label: {
            fr: "Amplitude B",
            en: "Amplitude B"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: true
    },
    checklist_encoderMotorControl_A_B: {
        label: {
            fr: "A->B",
            en: "A->B"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: true
    },
    checklist_encoderMotorControl_amplitude_N: {
        label: {
            fr: "Amplitude N",
            en: "Amplitude N"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: true
    },
    checklist_encoderMotorControl_offset_A: {
        label: {
            fr: "Offset A",
            en: "Offset A"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: true
    },
    checklist_encoderMotorControl_offset_B: {
        label: {
            fr: "Offset B",
            en: "Offset B"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: true
    },
    checklist_encoderMotorControl_offset_N: {
        label: {
            fr: "Offset N",
            en: "Offset N"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: true
    },
    checklist_encoderMotorControl_top: {
        label: {
            fr: "Top",
            en: "Top"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"],
        negative: true
    },
    conformityReport_encoderMotorControl_encoderType: {
        label: {
            fr: "Type encodeur",
            en: "Encoder type"
        },
		category: ['ebc'],
        type: "input",
        inputType: "text",
        keys: ["standard"]
    },
    conformityReport_encoderMotorControl_numberTeethWheel: {
        label: {
            fr: "Nombre de dents de la roue",
            en: "Number of teeth of the wheel"
        },
		category: ['ebc'],
		type: "select",
        defaultOptions: {
            numberTeethWheel_1: { label: { fr: "128", en: "128" } },
            numberTeethWheel_2: { label: { fr: "256", en: "256" } },
            numberTeethWheel_3: { label: { fr: "512", en: "512" } },
            numberTeethWheel_4: { label: { fr: "1024", en: "1024" } },
        },
        keys: ["standard"]
    },
    checklist_encoderMotorControl_runOutEncoderWheel: {
        label: {
            fr: "Faux rond de la roue codeuse (µm)",
            en: "Run out encoder wheel (µm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"]
    },
    checklist_encoderMotorControl_risatti: {
        label: {
            fr: "Image Risatti",
            en: "Image Risatti"
        },
		category: ['ebc'],
		type: 'photo',
        directoryName: 'Risatti',
        keys: ["standard"]
    },
    checklist_encoderMotorControl_postBreakInCheck_1: {
        label: {
            fr: "Contrôle post-rodage 1",
            en: "Post-break-in check 1"
        },
		category: ['ebc'],
		type: 'photo',
        directoryName: 'Controle_post_rodage',
        keys: ["standard"]
    },
    checklist_encoderMotorControl_postBreakInCheck_2: {
        label: {
            fr: "Contrôle post-rodage 2",
            en: "Post-break-in check 2"
        },
		category: ['ebc'],
		type: 'photo',
        directoryName: 'Controle_post_rodage',
        keys: ["standard"]
    },
    checklist_encoderMotorControl_postBreakInCheck_3: {
        label: {
            fr: "Contrôle post-rodage 3",
            en: "Post-break-in check 3"
        },
		category: ['ebc'],
		type: 'photo',
        directoryName: 'Controle_post_rodage',
        keys: ["standard"]
    },
};

export const checkListEncoderMotorControlFields = fieldsParser(_checklistEncoderMotorControlFields);