import fieldsParser from "../../fieldsParser";

export const _motorFaultsCausesFields = {
    motor_faultsCauses_variatorOverload: {
        label: {
            fr: "Surcharge variateur",
            en: "Variator overload"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    motor_faultsCauses_debris: {
        label: {
            fr: "Débris",
            en: "Debris"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    motor_faultsCauses_severedCables: {
        label: {
            fr: "Câbles sectionnés",
            en: "Severed cables"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    motor_faultsCauses_flood: {
        label: {
            fr: "Inondation",
            en: "Flood"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    motor_faultsCauses_pollution: {
        label: {
            fr: "Pollution",
            en: "Pollution"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    motor_faultsCauses_defectiveProbe: {
        label: {
            fr: "Sonde défectueuse",
            en: "Defective probe"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
};

export const motorFaultsCausesFields = fieldsParser(_motorFaultsCausesFields);