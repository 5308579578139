import fieldsParser from "@/formFields/fieldsParser"

export const _reportTableGoToFields = {
    reportTable_hoursToGoFrom: {
        label: {
            fr: "de",
            en: "from"
        },
        category: ['ebc'],
        type: "time",
        dynamic: true,
        keys: ["standard"]
    },
    reportTable_hoursToGoTo: {
        label: {
            fr: "à",
            en: "to"
        },
        category: ['ebc'],
        type: "time",
        dynamic: true,
        keys: ["standard"]
    },
    reportTable_kms_going: {
        label: {
            fr: "KMS aller",
            en: "KMS going"
        },
        category: ['ebc'],
        type: "input",
        dynamic: true,
        keys: ["standard"]
    }
}

export const reportTableGoToFields = fieldsParser(_reportTableGoToFields)