import fieldsParser from "@/formFields/fieldsParser"

export const _reportTableWorkMealFields = {
    reportTable_lunchBreakFrom: {
        label: {
            fr: "de",
            en: "from"
        },
        category: ['ebc'],
        type: "time",
        dynamic: true,
        keys: ["standard"]
    },
    reportTable_lunchBreakTo: {
        label: {
            fr: "à",
            en: "to"
        },
        category: ['ebc'],
        type: "time",
        dynamic: true,
        keys: ["standard"]
    },
}

export const reportTableWorkMealFields = fieldsParser(_reportTableWorkMealFields)