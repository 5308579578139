import fieldsParser from "../../../fieldsParser";

export const _bearingsCompartmentsAirCurtainAndLabyrinthFields = {
    bearings_compartments_airCurtainAndLabyrinth_visualExamination: {
        label: {
            fr: "Examen visuel",
            en: "Visual examination"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    bearings_compartments_airCurtainAndLabyrinth_visualExaminationValue: {
        label: {
            fr: "Examen visuel (valeur)",
            en: "Visual examination (Value)"
        },
        category: ['ebc'],
        type: "input"
    },
    bearings_compartments_airCurtainAndLabyrinth_labyrinthState: {
        label: {
            fr: "État du labyrinthe",
            en: "Labyrinth state"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    bearings_compartments_airCurtainAndLabyrinth_labyrinthStateValue: {
        label: {
            fr: "État du labyrinthe (valeur)",
            en: "Labyrinth state (value)"
        },
		category: ['ebc', 'kessler'],
		type: "select",
        defaultOptions: {
            deformation: { label: { fr: "Déformation", en: "Deformation" } },
            pollution: { label: { fr: "Pollution", en: "Pollution" } },
        },
    },
    bearings_compartments_airCurtainAndLabyrinth_photo: {
        label: {
            fr: "Photo rideau d'air / labyrinthe",
            en: "Air curtain / labyrinth photo"
        },
        type: 'photo',
        directoryName: 'Rideau_air_labyrinthe',
        rowWeight: 3,
        extraColumnSpace: 1
    },
    bearings_compartments_airCurtainAndLabyrinth_presenceOfWasteOilsChips: {
        label: {
            fr: "Présence d'huiles usagées, de copeaux",
            en: "Presence of waste oils, chips"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Oui", en: "Yes" } },
            KO: { label: { fr: "Non", en: "No" } }
        }
    },
    bearings_compartments_airCurtainAndLabyrinth_presenceOfWasteOilsChipsValue: {
        label: {
            fr: "Présence d'huiles usagées, de copeaux (valeur)",
            en: "Presence of waste oils, chips (value)"
        },
		category: ['ebc'],
		type: "input"
    },
};

export const bearingsCompartmentsAirCurtainAndLabyrinthFields = fieldsParser(_bearingsCompartmentsAirCurtainAndLabyrinthFields);