import fieldsParser from "../../fieldsParser";

export const _rotorShaftRepairsFields = {
    rotorShaft_repairs_coneRectification: {
        label: {
            fr: "Rectification cône à profit",
            en: "Cone rectification"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } }
        }
    },
    rotorShaft_repairs_coneFaceRectificationAndChromePlating: {
        label: {
            fr: "Rectification + chromage cône (face)",
            en: "Cone face rectification + chrome plating"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } }
        }
    },
    rotorShaft_repairs_porteesRoulementsRectificationAndChromePlating: {
        label: {
            fr: "Rectification + chromage portées roulements",
            en: "Portées roulements rectification + chrome plating"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } }
        }
    },
    rotorShaft_repairs_grippingBoringRectificationAndChromePlating: {
        label: {
            fr: "Rectification + chromage alésage serreur",
            en: "Gripping boring rectification + chrome plating"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } }
        }
    },
    rotorShaft_repairs_rotorReplacement: {
        label: {
            fr: "Remplacement du rotor",
            en: "Rotor replacement"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } }
        }
    },
    rotorShaft_repairs_shaftReplacement: {
        label: {
            fr: "Remplacement arbre",
            en: "Shaft replacement"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } }
        }
    },
    rotorShaft_repairs_threadsOthersRepair: {
        label: {
            fr: "Réparation filetages/autres",
            en: "Threads/others repair"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } }
        }
    },
    rotorShaft_repairs_superFinishing: {
        label: {
            fr: "Superfinition",
            en: "Superfinishing"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } }
        }
    },
};

export const rotorShaftRepairsFields = fieldsParser(_rotorShaftRepairsFields);