import fieldsParser from "@/formFields/fieldsParser";

export const _spindleRemovalFields = {
    spindleRemoval_spindleSerialNumber: {
        label: {
            fr: "Numéro de série broche démontée",
            en: "Dismantled spindle serial number"
        },
        category: ['ebc'],
        type: "input"
    },
    spindleRemoval_disassemblyCause: {
        label: {
            fr: "Cause démontage",
            en: "Cause of disassembly"
        },
        category: ['ebc'],
        type: "input"
    },
    spindleRemoval_recording: {
        label: {
            fr: "Consignation",
            en: "Recording"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } }
        }
    }
}

export const spindleRemovalFields = fieldsParser(_spindleRemovalFields);