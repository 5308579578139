import fieldsParser from "@/formFields/fieldsParser";

export const _signaturesFields = {
    signatures_date: {
        label: {
            fr: "Date",
            en: "Date"
        },
        type: "date",
        category: ['ebc'],
    },
    signatures_customer: {
        label: {
            fr: "Signature client",
            en: "Customer signature"
        },
        type: "input",
        category: ['ebc'],
    },
    signatures_technician: {
        label: {
            fr: "Signature technicien",
            en: "Technician signature"
        },
        type: "input",
        category: ['ebc'],
    }
}

export const signaturesFields = fieldsParser(_signaturesFields)