import fieldsParser from "@/formFields/fieldsParser"

export const _observationFields = {
    observations: {
        label: {
            fr: "Observations",
            en: "Observations"
        },
        category: ['ebc'],
        type: "input",
        inputType: "textarea",
        columnWeight: 3
    }
}

export const observationFields = fieldsParser(_observationFields)