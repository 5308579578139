<style scoped>
.inputeo {
  width: 240px;
}

.radiobutton-container {
  margin-bottom: 10px;
}

.modal:not(.dialog) {
  align-items: initial;
}

.photos {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.photo-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.photo-topbar-title {
  font-size: 15px;
  font-weight: 500;
  color: rgb(50, 60, 70);
}

#photo-section {
  border-bottom: 1px solid rgb(230, 235, 240);
  margin: -30px -15px 20px -15px;
  align-items: flex-start;
}

#photo-section > div {
  width: 50%;
  padding: 30px;
  box-sizing: border-box;
}

#photo-section > div:first-child {
  border-right: 1px solid rgb(230, 235, 240);
}

.take-pictures-btn {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.take-pictures-btn + label {
  font-weight: 500;
  border: none;
  color: white;
  font-size: 12px;
  padding: 9px 15px;
  border-radius: 4px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.15s ease;
  background: linear-gradient(90deg, #ff6b00, #ff9100);
  font-family: Montserrat;
}

.take-pictures-btn + label svg {
  margin-right: 6px;
}

.radiobutton-container label {
  display: block;
}

.textareas > * {
  width: initial;
  flex: 1;
}

.radio-button input:checked + div {
  background: linear-gradient(90deg, #1e73be, #00a1cf) !important;
}

.multiselect__tags {
  padding-top: 5px;
}

.multiselect__placeholder {
  margin-bottom: 0;
}

.multiselect__select {
  height: 32px;
  padding: 0px 8px;
}

.multiselect {
  min-height: initial;
  height: 32px;
}

.textareas textarea {
  min-height: 80px !important;
}

:deep(.modal-container) {
  max-height: calc(100vh - 75px) !important;
}

.tablet :deep(.modal-container) {
  max-height: 598px;
}

#input-section {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#input-section .affair-form-row {
  width: 100%;
  display: grid;
  flex-direction: row;
  justify-content: center;
}

#input-section .affair-form-row .radiobutton-container {
  margin: 0;
}

#input-section .affair-form-row .inputeo {
  width: 95%;
  margin-top: 4px;
  margin-bottom: 4px;
  max-height: 60px;
}

.column-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.inputeo.input-to-create {
  border: 2px solid orange;
  border-radius: 1px;
}
</style>

<template>
  <Modal :hideTitle="true" :maxHeightContainer="1050" :fullscreen="true">
    <template v-slot:modalContent>
      <form id="form-create-affaire">
        <div id="photo-section" v-show="affair.repairType?.value !== repairTypeEnum.FOUR">
          <div>
            <div class="photo-topbar">
              <div class="photo-topbar-title">Photos</div>

              <div v-show="affair.spindlePhotos.length < 3">
                <input class="take-pictures-btn" id="take-pictures-btn-1" :type="$store.getters.isFromAppTablet ? 'button' : 'file'"
                      accept="image/png, image/gif, image/jpeg" capture @click="tabletChooser(event, 'spindle')" @change="(event) => $store.getters.isFromAppTablet ? '' : takeSpindlePicture(event)"/>
                <label for="take-pictures-btn-1">
                  <font-awesome-icon :icon="['fas', 'camera']"/>
                  Prendre une photo
                </label>
              </div>
            </div>
            <div class="photos" v-show="affair.spindlePhotos.length > 0">
              <Photo
                  v-for="(spindlePhoto, index) of affair.spindlePhotos"
                  :key="index"
                  :image="spindlePhoto"
                  :index="String(index)"
                  @deletePhoto="onDeleteSpindlePhoto(index, spindlePhoto)"
              ></Photo>
            </div>
          </div>
          <div>
            <div class="photo-topbar">
              <div class="photo-topbar-title">Photos des documents</div>
              <div v-show="affair.documentPhotos.length < 3">
                <input class="take-pictures-btn" id="take-pictures-btn-2" :type="$store.getters.isFromAppTablet ? 'button' : 'file'"
                     accept="image/png, image/gif, image/jpeg" capture @click="tabletChooser(event, 'document')" @change="(event) => $store.getters.isFromAppTablet ? '' : takeDocumentPicture(event)"/>
                <label for="take-pictures-btn-2">
                  <font-awesome-icon :icon="['fas', 'camera']"/>
                  Prendre une photo
                </label>
              </div>
              
            </div>
            <div class="photos" v-show="affair.documentPhotos.length > 0">
              <Photo v-for="(documentPhoto, index) of affair.documentPhotos" :key="index" :image="documentPhoto"
                     :index="String(index)" @deletePhoto="onDeleteDocumentPhoto(index, documentPhoto)"></Photo>
            </div>
          </div>
        </div>
        <div id="input-section">
          <div class="affair-form-row"
               :style="'grid-template-columns: repeat(3, 1fr); margin-bottom: 10px; margin-top: 5px;' + (modalMode !== 'create' ? 'margin-left: 20px;' : '')">
               <BaseInput
                    v-if="modalMode === 'create'"
                      v-model="affair.affairNumberForce"
                      label="Forcer le numéro de l'affaire"
                      type="text"
                      name="affairNumberForce"
                  />
            <BaseCheckbox
                v-model="affair.internal"
                label="Affaire interne"
            />
            <BaseCheckbox
                v-model="affair.clockingTaskable"
                label="Affaire pointable"
            />
          </div>
          <div class="affair-form-row" style="grid-template-columns: repeat(3, 1fr); margin-bottom: 13px">
            <BaseDatepicker
                v-model="affair.receiptDate"
                :label="[RepairTypeEnum.FOUR, RepairTypeEnum.ISSM, RepairTypeEnum.MAIM, RepairTypeEnum.ISSB].includes(affair.repairType?.value) ? 'Date de création' : 'Date d\'arrivée'"
                name="receiptDate"
                :required="true"
                :error="affairFormErrors?.receiptDate?.error?.message"
                @onChange="onAffairFormInputChange"
                :displayError="displayError"
            />
            <BaseSelect
                v-model="affair.establishment"
                label="Établissement"
                :defaultOptions="establishments"
                name="establishment"
                :error="affairFormErrors?.establishment?.error?.message"
                @onChange="onAffairFormInputChange"
                :displayError="displayError"
                :required="true"
            />
            <BaseSelect
                v-model="affair.repairType"
                label="Type de prestation"
                :defaultOptions="repairTypes"
                name="repairType"
                :error="affairFormErrors?.repairType?.error?.message"
                @onChange="onAffairFormInputChange"
                :displayError="displayError"
                :required="true"
                :max-height="300"
            />
          </div>
          <div class="affair-form-row" style="grid-template-columns: repeat(3, 1fr); margin-bottom: 13px">
            <BaseSelect
                v-model="affair.billToCustomerId"
                label="Client facturé"
                :defaultOptions="defaultOptions"
                name="billToCustomerId"
                api="customer/search"
                fieldValue="id"
                fieldLabel="name"
                :required="true"
                :error="affairFormErrors?.billToCustomerId?.error?.message"
                @onChange="onAffairFormInputChange"
                :displayError="displayError"
                :searchable="true"
                :minChars="1"
                :class="{'input-to-create': (affair.billToCustomerId?.value == -1) }"
            />
            <BaseSelect
                v-model="affair.shipToCustomerId"
                label="Client final"
                :defaultOptions="defaultOptions"
                name="shipToCustomerId"
                api="customer/search"
                fieldValue="id"
                fieldLabel="name"
                :required="true"
                :error="affairFormErrors?.shipToCustomerId?.error?.message"
                @onChange="onAffairFormInputChange"
                :displayError="displayError"
                :searchable="true"
                :minChars="1"
                :class="{'input-to-create': (affair.shipToCustomerId?.value == -1) }"
            />
            <RadioButtonContainer
                v-show="repairCondition.includes(affair.repairType?.value)"
                label="Conditionnement (caisse)"
                name="packaging"
                :error="affairFormErrors?.packaging?.error?.message"
                :displayError="displayError"
                @onChange="onAffairFormInputChange"
                :required="repairCondition.includes(affair.repairType?.value)"
                v-model="affair.packaging"
                :style="'grid-column: span 1; grid-row: span 1'"
            >
              <BaseRadioButton v-model="affair.packaging" label="À réparer" name="packaging"
                               value="repair"></BaseRadioButton>
              <BaseRadioButton v-model="affair.packaging" label="À conserver" name="packaging"
                               value="keep"></BaseRadioButton>
              <BaseRadioButton v-model="affair.packaging" label="À remplacer" name="packaging"
                               value="replace"></BaseRadioButton>
            </RadioButtonContainer>
          </div>
          <div
              v-show="[RepairTypeEnum.FOUR, RepairTypeEnum.REEA, RepairTypeEnum.ISSM, RepairTypeEnum.MAIM, RepairTypeEnum.ISSB, RepairTypeEnum.RETR, RepairTypeEnum.FORM].includes(affair.repairType?.value)"
              class="affair-form-row" style="grid-template-columns: repeat(4, 1fr); margin-bottom: 13px">
            <BaseInput
                v-model="affair.label"
                label="Libellé"
                type="textarea"
                name="label"
                :error="affairFormErrors?.label?.error?.message"
                @onChange="onAffairFormInputChange"
                :displayError="displayError"
                :required="repairTypesFields?.label?.required.includes(affair.repairType?.value)"
                v-show="repairTypesFields?.label?.show.includes(affair.repairType?.value)"
                style="min-height: 118px; max-height: 200px; grid-column: 1 / 3; width: 97.5%;"
                :default-value="repairTypesFields?.label?.defaultValue[affair.repairType?.value?.toUpperCase()]"
            />
            <div style="display: grid; grid-template-rows: repeat(2, 1fr); gap: 10px; grid-column: 3 / 5; width: 100%;">
              <BaseInput
                  v-model="affair.machine"
                  label="Machine"
                  name="machine"
                  :error="affairFormErrors?.machine?.error?.message"
                  @onChange="onAffairFormInputChange"
                  :displayError="displayError"
                  :required="repairTypesFields?.machine?.required.includes(affair.repairType?.value)"
                  v-show="repairTypesFields?.machine?.show.includes(affair.repairType?.value)"
              />
              <BaseSelect
                  v-model="affair.machineMaintenanceId"
                  label="Maintenance machine"
                  :defaultOptions="defaultMachineMaintenanceOptions"
                  name="machineMaintenance"
                  :required="repairTypesFields?.machineMaintenance?.required.includes(affair.repairType?.value)"
                  v-show="repairTypesFields?.machineMaintenance?.show.includes(affair.repairType?.value)"
                  :error="affairFormErrors?.machineMaintenance?.error?.message"
                  @onChange="onAffairFormInputChange"
                  :displayError="displayError"
              />
            </div>
          </div>
          <div class="affair-form-row" style="grid-template-columns: repeat(3, 1fr); margin-bottom: 13px">
            <BaseSelect
                v-model="affair.spindleBrandId"
                label="Marque"
                :defaultOptions="defaultOptions"
                name="spindleBrandId"
                api="spindleBrand/search"
                fieldValue="id"
                fieldLabel="name"
                :required="repairTypesFields?.spindleBrandId?.required.includes(affair.repairType?.value)"
                v-show="repairTypesFields?.spindleBrandId?.show.includes(affair.repairType?.value)"
                :error="affairFormErrors?.spindleBrandId?.error?.message"
                @onChange="onAffairFormInputChange"
                :displayError="displayError"
                :searchable="true"
                :min-chars="2"
                :class="{'input-to-create': (affair.spindleBrandId?.value == -1) }"
            />
            <BaseSelect
                v-model="affair.spindleTypeId"
                label="Type"
                :defaultOptions="defaultOptions"
                name="spindleTypeId"
                api="spindleType/search"
                :apiParams="{
                        spindleBrandId: affair.spindleBrandId?.value
                    }"
                fieldValue="id"
                fieldLabel="name"
                :required="repairTypesFields?.spindleTypeId?.required.includes(affair.repairType?.value)"
                v-show="repairTypesFields?.spindleTypeId?.show.includes(affair.repairType?.value)"
                :error="affairFormErrors?.spindleTypeId?.error?.message"
                @onChange="onAffairFormInputChange"
                :displayError="displayError"
                :searchable="true"
                :min-chars="2"
                :class="{'input-to-create': (affair.spindleTypeId?.value == -1) }"
            />
            <RadioButtonContainer
                v-show="repairTypesFields?.model?.show.includes(affair.repairType?.value)"
                label="Modèle de broche"
                name="model"
                :error="affairFormErrors?.model?.error?.message"
                :displayError="displayError"
                @onChange="onAffairFormInputChange"
                :required="repairTypesFields?.model?.required.includes(affair.repairType?.value)"
                v-model="affair.model"
                :style="'grid-column: span 1; grid-row: span 1'"
            >
              <BaseRadioButton v-model="affair.model" label="Mécanique" name="model" value="mechanical"
                               :disabled="modelRadioButtonDisabled"></BaseRadioButton>
              <BaseRadioButton v-model="affair.model" label="Électrobroche" name="model" value="electrospindle"
                               :disabled="modelRadioButtonDisabled"></BaseRadioButton>
              <BaseRadioButton v-model="affair.model" label="Autre" name="model" value="other"
                               :disabled="modelRadioButtonDisabled"></BaseRadioButton>
            </RadioButtonContainer>
          </div>
          <div class="affair-form-row" style="grid-template-columns: repeat(3, 1fr); margin-bottom: 13px">
            <BaseSearchingInput
                v-model="affair.serialNumber"
                label="Numéro de série"
                type="text"
                name="serialNumber"
                api="spindle/search"
                :apiParams="{
                                spindleTypeId: affair.spindleTypeId?.value,
                                spindleBrandId: affair.spindleBrandId?.value
                            }"
                :error="affairFormErrors?.serialNumber?.error?.message"
                @onChange="onAffairFormInputChange"
                fieldId="id"
                fieldValue="serialNumber"
                v-show="repairTypesFields?.serialNumber?.show.includes(affair.repairType?.value)"
            />
            <BaseInput
                v-model="affair.weight"
                label="Poids (en kg)"
                type="number"
                name="weight"
                validator="number"
                :error="affairFormErrors?.weight?.error?.message"
                @onChange="onAffairFormInputChange"
                :displayError="displayError"
                :required="repairTypesFields?.weight?.required.includes(affair.repairType?.value)"
                v-show="repairTypesFields?.weight?.show.includes(affair.repairType?.value)"
            />
            <BaseInput
                v-model="affair.elements"
                label="Éléments joints"
                name="elements"
            />
          </div>
          <div class="affair-form-row" style="grid-template-columns: 0.6fr 0.6fr 1.5fr;"
               :style="[affair.customsTransit === 'false' ? {'margin-bottom': '13px'} : {'margin-bottom': '4px'}]">
            <RadioButtonContainer
                label="Joindre document douane"
                name="customsTransit"
                :error="affairFormErrors?.customsTransit?.error?.message"
                :displayError="displayError"
                @onChange="onAffairFormInputChange"
                :required="true"
                v-model="affair.customsTransit"
                v-show="repairTypesFields?.customsTransit?.show.includes(affair.repairType?.value)"
            >
              <BaseRadioButton v-model="affair.customsTransit" label="Oui" name="customs"
                               value="true"></BaseRadioButton>
              <BaseRadioButton v-model="affair.customsTransit" label="Non" name="customs"
                               value="false"></BaseRadioButton>
            </RadioButtonContainer>
            <RadioButtonContainer
                v-show="repairTypesFields?.guarantee?.show.includes(affair.repairType?.value)"
                label="Demande de prise sous garantie"
                name="guarantee"
                :error="affairFormErrors?.guarantee?.error?.message"
                :displayError="displayError"
                @onChange="onAffairFormInputChange"
                :required="true"
                v-model="affair.guarantee"
            >
              <BaseRadioButton v-model="affair.guarantee" label="Oui" name="guarantee" value="true"></BaseRadioButton>
              <BaseRadioButton v-model="affair.guarantee" label="Non" name="guarantee" value="false"></BaseRadioButton>
            </RadioButtonContainer>
            <BaseSelect
                v-show="affair.guarantee === 'true'"
                v-model="affair.guaranteeStatus"
                :default-options="guaranteeStatusOptions"
                label="Statut garantie"
                name="guaranteeStatus"
                :required="affair.guarantee === 'true'"
                :error="affairFormErrors?.guaranteeStatus?.error?.message"
                :displayError="displayError"
                @onChange="onAffairFormInputChange"
            ></BaseSelect>
          </div>
          <div class="affair-form-row" v-show="affair.customsTransit === 'true'"
               style="grid-template-columns: repeat(4, 1fr); margin-bottom: 13px">
            <BaseDatepicker
                v-model="affair.customsTransitDate"
                label="Date de passage en douane"
                name="customsTransitDate"
                :required="true"
                :error="affairFormErrors?.customsTransitDate?.error?.message"
                @onChange="onAffairFormInputChange"
                :displayError="displayError"
            />
            <BaseInput
                v-model="affair.customsImaNumber"
                label="Numéro IMA"
                type="text"
                name="customsImaNumber"
                validator="text"
                :error="affairFormErrors?.customsImaNumber?.error?.message"
                @onChange="onAffairFormInputChange"
                :displayError="displayError"
            />
            <BaseInput
                v-model="affair.customsExaNumber"
                label="Numéro EXA"
                type="text"
                name="customsExaNumber"
                validator="text"
                :error="affairFormErrors?.customsExaNumber?.error?.message"
                @onChange="onAffairFormInputChange"
                :displayError="displayError"
            />
            <BaseInput
                v-model="affair.customsValue"
                label="Valeur douane"
                type="text"
                name="customsValue"
                validator="price"
                :error="affairFormErrors?.customsValue?.error?.message"
                @onChange="onAffairFormInputChange"
                :displayError="displayError"
            />
          </div>
          <div class="affair-form-row textareas" style="grid-template-columns: repeat(2, 1fr)">
            <BaseInput
                v-model="affair.notes"
                label="Notes (premières informations du fil de discussion)"
                type="textarea"
                name="notes"
                :style="'width: 97%; min-height: 90px; max-height: 200px;'"
            />
            <BaseInput
                v-model="affair.customerInformations"
                label="Informations client à reprendre"
                type="textarea"
                name="customerInformations"
                :style="'width: 97%; min-height: 90px; max-height: 200px;'"
                v-show="repairTypesFields?.customerInformations?.show.includes(affair.repairType?.value)"
            />
          </div>
        </div>
      </form>
    </template>
    <template v-slot:modalButtons>
      <BaseButton class="white-button" @click="closeAffairModal" buttonText="Fermer">
        <template v-slot:iconBefore>
          <font-awesome-icon :icon="['fas', 'times']"/>
        </template>
      </BaseButton>

      <BaseButton class="orange-button" @click="submitAffairModalForRegistration" :disabled="isLoading"
                  buttonText="Enregistrer">
        <template v-slot:iconBefore>
          <font-awesome-icon :icon="['fas', 'check']"/>
        </template>
      </BaseButton>
      <BaseButton class="orange-button" @click="submitAffairModalForValidation" :disabled="isLoading"
                  buttonText="Valider">
        <template v-slot:iconBefore>
          <font-awesome-icon :icon="['fas', 'check']"/>
        </template>
      </BaseButton>

    <PhotoSourceChooser :display-tablet-source-chooser="displaySpindleTabletSourceChooser" limit="3" @inputFile="takeSpindlePicture" @closeModal="closeSpindleTabletChooserModal"/>
    <PhotoSourceChooser :display-tablet-source-chooser="displayDocumentTabletSourceChooser" limit="3" @inputFile="takeDocumentPicture" @closeModal="closeDocumentTabletChooserModal"/>
    </template>
  </Modal>
</template>

<script>
import {format} from "date-fns";
import axios from 'axios';
import Modal from "../../components/App/Modal";
import BaseButton from "../../components/Base/BaseButton";
import BaseInput from "../../components/Base/BaseInput";
import BaseSearchingInput from "../../components/Base/BaseSearchingInput";
import RadioButtonContainer from "../../components/App/RadioButtonContainer";
import BaseRadioButton from "../../components/Base/BaseRadioButton";
import BaseSelect from "../../components/Base/BaseSelect";
import BaseDatepicker from "../../components/Base/BaseDatepicker";
import Photo from "../../components/App/Photo";
import {dataUrlToFile} from "../../utils/dataUrlToFile";
import repairTypeEnum from "@/enums/repairTypeEnum";
import {repairTypesFields} from "@/formFields/repairTypesConditions";
import RepairTypeEnum from "@/enums/repairTypeEnum";
import BaseCheckbox from "@/components/Base/BaseCheckbox.vue";
import PhotoSourceChooser from "@/components/App/PhotoSourceChooser.vue";
import store from "@/store";

export default {
  name: 'NewAffairModal',
  computed: {
    RepairTypeEnum() {
      return RepairTypeEnum
    },
    repairTypeEnum() {
      return repairTypeEnum
    }
  },
  components: {
      PhotoSourceChooser,
    BaseCheckbox,
    Modal,
    BaseButton,
    BaseInput,
    BaseSearchingInput,
    RadioButtonContainer,
    BaseRadioButton,
    BaseSelect,
    BaseDatepicker,
    Photo
  },
  props: {
    modelValue: {
      type: Object,
      default: null
    }
  },
  watch: {
    modelValue: {
      handler(modelValue) {
        this.affair = modelValue;
        if (this.affair) {
          this.modalMode = 'update';
        }
      },
    }
  },
  data() {
    return {
      isLoading: false,
      repairTypesFields: repairTypesFields,
      affair: null,
      modalMode: 'create',
      affairFormErrors: {},
      displayError: false,
      modelRadioButtonDisabled: false,
      repairTypes: [
        {label: "Intervention sur site broches", value: "issb"},
        {label: "Remise en état broches", value: "reeb"},
        {label: "Remise en état arbre / sous-ensemble", value: "reea"},
        {label: "Passage sur banc", value: "pass"},
        {label: "Maintenance machine", value: "maim"},
        {label: "Rétrofit machines", value: "retr"},
        {label: "Intervention sur site machines", value: "issm"},
        {label: "Spindle hotel", value: "spin"},
        {label: "Fourniture diverses", value: "four"},
        {label: "Formation", value: "form"},
        {label: "Retour sans réparation", value: "rsre"},
        {label: "Retour sans facturation", value: "rsfa"},
        {label: "Ferraillage", value: "ferr"}
      ],
      repairCondition: ["reeb", "reea"],
      establishments: [
        {label: 'EBC', value: 'ebc'},
        {label: 'SMG', value: 'smg'}
      ],
      defaultOptions: [
        {label: 'À CRÉER', value: '-1'}
      ],
      guaranteeStatusOptions: [
        {label: 'Acceptée', value: 'accepted'},
        {label: 'Refusée', value: 'refused'},
        {label: 'En cours', value: 'inProgress'},
      ],
      defaultMachineMaintenanceOptions: [
        {label: 'AVP', value: "avp"},
        {label: 'Sur site (au tps passé)', value: "on_Site"},
        {label: 'Matériel en atelier', value: "workshop_Equipment"}
      ],
        newSpindlePhotos: [],
        newDocumentPhotos: [],
        spindlePhotosToDelete: [],
        documentPhotosToDelete: [],
        displaySpindleTabletSourceChooser: false,
        displayDocumentTabletSourceChooser: false
    }
  },
  beforeMount() {
    this.resetAffair();

    if (this.$store.getters.isTechnician)
      this.repairTypes = this.repairTypes.filter((type) => !(['issb', 'issm', 'rsre', 'rsfa'].includes(type.value)))
  },
  methods: {
    onAffairFormInputChange(input) {
      if (input.error?.message !== null) {
        this.affairFormErrors[input.name] = input;
      } else {
        delete this.affairFormErrors[input.name];
      }

      switch (input.name) {
        case 'billToCustomerId':
          this.affair.shipToCustomerId = input.value;
          break;
        case 'spindleBrandId':
          if (input.value?.value === "-1") {
            this.affair.spindleTypeId = {label: 'À CRÉER', value: '-1'};
          } else if (this.affair.spindleTypeId != null && this.affair.spindleTypeId?.value !== "-1" && this.affair.spindleTypeId?.data.spindleBrand.id !== input.value?.value) {
            this.affair.spindleTypeId = null;
          }

          if (this.affair.serialNumber != null && this.affair.serialNumber?.data?.spindleType.spindleBrand.id !== input.value?.value && input.changeType === 'user') {
            this.affair.serialNumber = null;
          }
          break;
        case 'spindleTypeId':
          if ((this.affair.spindleBrandId === null || this.affair.spindleBrandId?.value === "-1") && this.affair.spindleTypeId != null && this.affair.spindleTypeId?.value !== "-1") {
            this.affair.spindleBrandId = {
              value: input.value.data.spindleBrand.id,
              label: input.value.data.spindleBrand.name
            }
          }

          if (this.affair.serialNumber != null && this.affair.serialNumber?.data?.spindleType.id !== input.value?.value && input.changeType === 'user') {
            this.affair.serialNumber = null;
          }
          break;
        case 'serialNumber': {
          if (input.value?.data != null) {
            this.affair.spindleTypeId = {
              value: input.value.data.spindleType.id,
              label: input.value.data.spindleType.name,
              data: {
                spindleBrand: {
                  id: input.value.data.spindleType.spindleBrand.id,
                  name: input.value.data.spindleType.spindleBrand.name
                }
              }
            }

            this.affair.spindleBrandId = {
              value: input.value.data.spindleType.spindleBrand.id,
              label: input.value.data.spindleType.spindleBrand.name
            }

            this.affair.model = input.value.data.model;

            this.modelRadioButtonDisabled = true;
          } else {
            this.modelRadioButtonDisabled = false;
          }
          break;
        }
        case 'customsTransit':
          if (input.value !== 'true') {
            delete this.affairFormErrors['customsTransitDate'];
            delete this.affairFormErrors['customsImaNumber'];
            delete this.affairFormErrors['customsExaNumber'];
            delete this.affairFormErrors['customsValue'];
          }
          break;
        case 'repairType':
          Object.entries(repairTypesFields).map(([key, value]) => {
            if (!value.required?.includes(this.affair.repairType?.value) && this.affairFormErrors[key]) {
              delete this.affairFormErrors[key];
            }
          })
          break;
      }
    },
    resetAffair() {
      this.affair = {
        receiptDate: format(new Date(), 'dd/MM/yyyy'),
        repairType: null,
        establishment: null,
        model: '',
        packaging: '',
        weight: null,
        billToCustomerId: null,
        shipToCustomerId: null,
        spindleBrandId: null,
        spindleTypeId: null,
        spindleId: null,
        serialNumber: null,
        guarantee: 'false',
        customsTransit: 'false',
        customsTransitDate: null,
        customsImaNumber: null,
        customsExaNumber: null,
        customsValue: null,
        elements: '',
        notes: null,
        customerInformations: null,
        spindlePhotos: [],
        documentPhotos: [],
        messages: [],
        currentNomenclature: null,
        label: null,
        machine: null,
        machineMaintenance: null,
        clockingTaskable: true
      };
        this.newSpindlePhotos = []
        this.newDocumentPhotos = []
        this.spindlePhotosToDelete = []
        this.documentPhotosToDelete = []
    },
    closeAffairModal() {
      this.resetAffair();
      this.displayError = false;
      this.$emit('onClose');
    },
    modalModeIsCreate() {
      return this.modalMode === 'create';
    },
    async submitAffairModalForRegistration() {
      await this.submitAffairModal(false)
    },
    async submitAffairModalForValidation() {
      await this.submitAffairModal(true)
    },

    async submitAffairModal(isValidationWanted) {
      delete this.affairFormErrors?.serialNumber;
      if (!this.checkAffairForm()) return;
      if (this.modalMode === 'create') {
        this.isLoading = true;
        axios
            .post(`affair/create`, await this.formateAffair(this.affair, isValidationWanted), {
              toastSuccessMessage: `Affaire créée`,
              toastError: true,
              showPreloader: true
            })
            .then(() => {
              this.resetAffair();
              this.displayError = false;
              this.$emit('onCreateAffair');
            })
            .catch(() => {
            }).finally(() => {
              setTimeout(() => {
                this.isLoading = false;
              }, 1000)
        })
      } else {
        if (this.modalMode === 'update') {
          this.isLoading = true;
          axios
              .put(`affair/update`, await this.formateAffair(this.affair, isValidationWanted), {
                toastSuccessMessage: `Modification effectuée`,
                toastError: true,
                showPreloader: true
              })
              .then(() => {
                this.resetAffair();
                this.displayError = false;
                this.$emit('onCreateAffair');
              })
              .catch(() => {})
              .finally(() => {
            setTimeout(() => {
              this.isLoading = false;
            }, 1000)
          })
        }
      }
    },

    async formateAffair(affair, isValidated) {
      affair = JSON.parse(JSON.stringify(affair));
      affair.weight = parseInt(affair.weight);
      affair.guarantee = affair.guarantee === 'true';
      affair.customsTransit = affair.customsTransit === 'true';
      if (affair.customsTransit) {
        affair.customsValue = parseFloat(affair.customsValue);
      }
      affair.repairType = affair.repairType.value;
      affair.establishment = affair.establishment.value;
      affair.billToCustomerId = affair.billToCustomerId?.value;
      affair.shipToCustomerId = affair.shipToCustomerId?.value;
      affair.spindleBrandId = affair.spindleBrandId?.value;
      affair.spindleTypeId = affair.spindleTypeId?.value;
      if (affair.serialNumber?.id) {
        affair.spindleId = affair.serialNumber.id;
        delete affair.serialNumber;
        delete affair.model;
      } else {
        affair.serialNumber = affair.serialNumber?.value;
        delete affair.spindleId;
      }
      affair.guaranteeStatus = affair.guaranteeStatus?.value
      affair.machineMaintenance = affair.machineMaintenance?.value;
      affair.clockingTaskable = affair.clockingTaskable?.value || true;

      affair.spindlePhotos = await Promise.all(affair.spindlePhotos.map(async (base64Image, index) => {
        return await dataUrlToFile(base64Image, `broche-${index}`);
      }));
      affair.documentPhotos = await Promise.all(affair.documentPhotos.map(async (base64Image, index) => {
        return await dataUrlToFile(base64Image, `document-${index}`);
      }));

        let spindlePhotos = await Promise.all(this.newSpindlePhotos.map(async (base64Image, index) => {
            return await dataUrlToFile(base64Image, `broche-${index}`);
        }));
        let documentPhotos = await Promise.all(this.newDocumentPhotos.map(async (base64Image, index) => {
            return await dataUrlToFile(base64Image, `document-${index}`);
        }));

      let formData = new FormData();

      (this.modalMode === 'update') && (formData.append('id', affair.id));
      formData.append('receiptDate', affair.receiptDate);
      formData.append('guarantee', affair.guarantee);
      formData.append('weight', affair.weight);
      formData.append('repairType', affair.repairType);
      formData.append('establishment', affair.establishment);
      formData.append('customsTransit', affair.customsTransit);
      formData.append('packaging', affair.packaging);
      if (affair.customsTransit) {
        formData.append('customsTransitDate', affair.customsTransitDate);
        formData.append('customsImaNumber', affair.customsImaNumber);
        formData.append('customsExaNumber', affair.customsExaNumber);
        formData.append('customsValue', affair.customsValue);
      }
      if (affair.notes) {
        formData.append('notes', affair.notes);
      }
      if (affair.customerInformations) {
        formData.append('customerInformations', affair.customerInformations);
      }
      if (affair.billToCustomerId !== '-1') {
        formData.append('billToCustomerId', affair.billToCustomerId);
      }
      if (affair.shipToCustomerId !== '-1') {
        formData.append('shipToCustomerId', affair.shipToCustomerId);
      }
      if (affair.spindleBrandId !== '-1') {
        formData.append('spindleBrandId', affair.spindleBrandId);
      }
      if (affair.spindleTypeId !== '-1') {
        formData.append('spindleTypeId', affair.spindleTypeId);
      }
      if (affair.spindleId) {
        formData.append('spindleId', affair.spindleId);
      } else if (affair.serialNumber) {
        formData.append('serialNumber', affair.serialNumber);
      }
      affair.spindlePhotos.map((file) => {
        formData.append('spindlePhotos', file);
      });
      affair.documentPhotos.map((file) => {
        formData.append('documentPhotos', file);
      });

      if (this.repairCondition.includes(this.affair.repairType?.value)) {
        formData.append('model', affair.model);
      }
      if (affair.guarantee) {
        formData.append('guaranteeStatus', affair.guaranteeStatus)
      }
      formData.append('elements', affair.elements)
      if (affair.label) formData.append('label', affair.label)
      if (affair.machine) formData.append('machine', affair.machine)
      if (affair.machineMaintenance) formData.append('machineMaintenance', affair.machineMaintenance)

      formData.append('isValidated', isValidated);
      formData.append('affairNumberForce', affair.affairNumberForce || null);
      formData.append('internal', affair.internal || false);
      formData.append('clockingTaskable', affair.clockingTaskable);

        spindlePhotos.map((file) => {
            formData.append('newSpindlePhotos', file);
        });
        documentPhotos.map((file) => {
            formData.append('newDocumentPhotos', file);
        });

        this.spindlePhotosToDelete.map((file) => {
            formData.append('spindlePhotosToDelete', file)
        })
        this.documentPhotosToDelete.map((file) => {
            formData.append('documentPhotosToDelete', file)
        })
      return formData;
    },
    checkAffairForm() {
      this.displayError = true;

      return Object.keys(this.affairFormErrors).length === 0;
    },
    onDeleteSpindlePhoto(index, photo = null) {
        this.spindlePhotosToDelete.push(photo.split('/')?.slice(-1)?.[0])
      this.affair.spindlePhotos?.splice(index, 1);
    },
    onDeleteDocumentPhoto(index, photo = null) {
        this.documentPhotosToDelete.push(photo.split('/')?.slice(-1)?.[0])
      this.affair.documentPhotos?.splice(index, 1);
    },
    takePicture(event, callback) {
        this.displaySpindleTabletSourceChooser = false;
        this.displayDocumentTabletSourceChooser = false;

        let ext = null;
        let file = null;
        if (event.srcElement) {
            file = event.srcElement.files[0];
            ext = event?.srcElement?.files[0].name.match(/\.([^\.]+)$/)[1];
        }
        else {
            file = event;
        }
        if ((event == null || ext == 'pdf') && typeof callback === 'function') {
            callback(null);
            return;
        }

      let reader = new FileReader();
      if (file != null) reader.readAsDataURL(file);

      reader.onload = function () {
        if (typeof callback === 'function') callback(reader);
      };
    },
    takeSpindlePicture(event) {
      let that = this;
      this.takePicture(event, (reader) => {
          if (reader == null) return;
          that.newSpindlePhotos.push(reader.result);
          that.affair.spindlePhotos.push(reader.result);
      });
    },
    takeDocumentPicture(event) {
      let that = this;
      this.takePicture(event, (reader) => {
          if (reader == null) return;
          that.newDocumentPhotos.push(reader.result);
          that.affair.documentPhotos.push(reader.result);
      });
    },
      closeSpindleTabletChooserModal() {
        this.displaySpindleTabletSourceChooser = false;
      },
      closeDocumentTabletChooserModal() {
        this.displayDocumentTabletSourceChooser = false;
      },
      tabletChooser(event, type) {
          if (!store.getters.isFromAppTablet) return;

          if (type === 'spindle' && this.affair.spindlePhotos.length < 3) {
              this.displaySpindleTabletSourceChooser = true
          } else if (type === 'document' && this.affair.documentPhotos.length < 3) {
              this.displayDocumentTabletSourceChooser = true
          }
      }
  }
}
</script>
