import fieldsParser from "../../fieldsParser";

export const _conclusionRepairsFields = {
    conclusion_repairs_componentsCleaningAndReconditioning: {
        label: {
            fr: "Nettoyage + remise en état des composants",
            en: "Components cleaning + reconditioning"
        },
		category: ['ebc', 'light'],
		type: "checkbox",
        columnWeight: 2
    },
    conclusion_repairs_rotatingAssembliesDynamicPreBalancing: {
        label: {
            fr: "Pré-équilibrage dynamique des ensembles tournants",
            en: "Rotating assemblies dynamic pre-balancing"
        },
		category: ['ebc', 'light'],
		type: "checkbox",
        columnWeight: 2
    },
    conclusion_repairs_coneRectification: {
        label: {
            fr: "Rectification cône à profit",
            en: "Cone rectification"
        },
		category: ['ebc', 'light'],
		type: "checkbox",
        columnWeight: 2
    },
    conclusion_repairs_coneFaceRectificationAndChromePlating: {
        label: {
            fr: "Rectification + chromage cône (face)",
            en: "Cone face rectification + chrome plating"
        },
		category: ['ebc', 'light'],
		type: "checkbox",
        columnWeight: 2
    },
    conclusion_repairs_porteesRoulementsRectificationAndChromePlating: {
        label: {
            fr: "Rectification + chromage portées roulements",
            en: "Portées roulements rectification + chrome plating"
        },
		category: ['ebc', 'light'],
		type: "checkbox",
        columnWeight: 2
    },
    conclusion_repairs_grippingBoringRectificationAndChromePlating: {
        label: {
            fr: "Rectification + chromage alésage serreur",
            en: "Gripping boring rectification + chrome plating"
        },
		category: ['ebc', 'light'],
		type: "checkbox",
        columnWeight: 2
    },
    conclusion_repairs_otherRectificationAndChromePlating: {
        label: {
            fr: "Rectification + chromage autre",
            en: "Other rectification + chrome plating"
        },
		category: ['ebc', 'light'],
		type: "checkbox",
        columnWeight: 2
    },
    conclusion_repairs_casesFaceBoringsRectificationAndChromePlating: {
        label: {
            fr: "Rectification + chromage alésages boîtiers (face)",
            en: "Cases (face) borings rectification + chrome plating"
        },
		category: ['ebc', 'light'],
		type: "checkbox",
        columnWeight: 2
    },
    conclusion_repairs_statorSteaming: {
        label: {
            fr: "Étuvage stator",
            en: "Stator steaming"
        },
		category: ['ebc', 'light'],
		type: "checkbox",
        columnWeight: 2
    },
    conclusion_repairs_statorRewinding: {
        label: {
            fr: "Rebobinage stator",
            en: "Stator rewinding"
        },
		category: ['ebc', 'light'],
		type: "checkbox",
        columnWeight: 2
    },
    conclusion_repairs_rechargingRepairsElectroerosionOther: {
        label: {
            fr: "Rechargement, réparation, électroérosion, autres",
            en: "Recharging, repairs, electro-erosion, other"
        },
		category: ['ebc', 'light'],
		type: "checkbox",
        columnWeight: 2
    },
    conclusion_repairs_dynamicBalancingOnTestBench: {
        label: {
            fr: "Rodage – équilibrage dynamique sur banc d’essais",
            en: "Running in – dynamic balancing on test bench"
        },
		category: ['ebc', 'light'],
		type: "checkbox",
        columnWeight: 2
    },
    conclusion_repairs_finalTestsAndConformityReport: {
        label: {
            fr: "Tests finaux + PV de conformité",
            en: "Final tests + conformity report"
        },
		category: ['ebc', 'light'],
		type: "checkbox",
        columnWeight: 2
    },
    conclusion_repairs_porteesSuperfinishing: {
        label: {
            fr: "Superfinition des portées + alésages",
            en: "Portées superfinishing"
        },
		category: ['ebc', 'light'],
		type: "checkbox",
        columnWeight: 2
    },
};

export const conclusionRepairsFields = fieldsParser(_conclusionRepairsFields);