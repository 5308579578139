import fieldsParser from "../../fieldsParser"

export const _conformityReportMotorSpecificationsFields = {
    conformityReport_motorSpecifications_schema: {
        label: {},
        category: ['ebc'],
        type: "schema",
        img: "pre-disassembly-schema.png",
        width: '400px',
        columnWeight: 2,
    },
    conformityReport_motorSpecifications_voltage: {
        label: {
            fr: "Tension (V)",
            en: "Voltage (V)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    }, conformityReport_motorSpecifications_frequency: {
        label: {
            fr: "Fréquence (Hz)",
            en: "Frequency (Hz)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    }, conformityReport_motorSpecifications_powerS6: {
        label: {
            fr: "Puissance S6 (kW)",
            en: "Power S6 (kW)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    }, conformityReport_motorSpecifications_powerS1: {
        label: {
            fr: "Puissance S1 (kW)",
            en: "Power S1 (kW)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    }, conformityReport_motorSpecifications_currentS6: {
        label: {
            fr: "Courant S6 (A)",
            en: "Current S6 (A)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    }, conformityReport_motorSpecifications_currentS1: {
        label: {
            fr: "Courant S1 (A)",
            en: "Current S1 (A)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    conformityReport_motorSpecifications_rotationSpeed: {
        label: {
            fr: "Vitesse de rotation (1/min)",
            en: "Rotation speed (1/min)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
};

export const conformityReportMotorSpecificationsFields = fieldsParser(_conformityReportMotorSpecificationsFields);