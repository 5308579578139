import fieldsParser from "../../fieldsParser"

export const _checklistSensorFields = {
    checklist_sensor_postBreakInSensorCheck: {
        label: {
            fr: "Contrôle des capteurs",
            en: "Sensor check"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        columnWeight: 4,
        keys: ["standard"]
    },
    checklist_sensor_loose: {
        label: {
            fr: "Desserré",
            en: "Loose"
        },
		category: ['ebc'],
		type: "text",
        keys: ["standard"]
    },
    checklist_sensor_looseLogic: {
        label: {
            fr: "Logique",
            en: "Logic"
        },
		category: ['ebc'],
		type: "input",
        keys: ["standard"]
    },
    checklist_sensor_looseAnalog: {
        label: {
            fr: "Analogique",
            en: "Analog"
        },
		category: ['ebc'],
		type: "input",
        keys: ["standard"],
        columnWeight: 2
    },
    checklist_sensor_clampedWithoutTools: {
        label: {
            fr: "Serré sans outil",
            en: "Clamped without"
        },
		category: ['ebc'],
		type: "text",
        keys: ["standard"]
    },
    checklist_sensor_clampedWithoutToolsLogic: {
        label: {
            fr: "Logique",
            en: "Logic"
        },
		category: ['ebc'],
		type: "input",
        keys: ["standard"]
    },
    checklist_sensor_clampedWithoutToolsAnalog: {
        label: {
            fr: "Analogique",
            en: "Analog"
        },
		category: ['ebc'],
		type: "input",
        keys: ["standard"],
        columnWeight: 2
    },
    checklist_sensor_clampedWithTools: {
        label: {
            fr: "Serré avec outil",
            en: "Clamped with tools"
        },
		category: ['ebc'],
		type: "text",
        keys: ["standard"]
    },
    checklist_sensor_clampedWithToolsLogic: {
        label: {
            fr: "Logique",
            en: "Logic"
        },
		category: ['ebc'],
		type: "input",
        keys: ["standard"]
    },
    checklist_sensor_clampedWithToolsAnalog: {
        label: {
            fr: "Analogique",
            en: "Analog"
        },
		category: ['ebc'],
		type: "input",
        keys: ["standard"]
    },
    checklist_sensor_clampedWithToolsClampForce: {
        label: {
            fr: "Force de serrage",
            en: "Clamp Force"
        },
		category: ['ebc'],
		type: "input",
        unit: "kN",
        keys: ["standard"]
    },
    checklist_sensor_rotation: {
        label: {
            fr: "Rotation",
            en: "Rotation"
        },
		category: ['ebc'],
		type: "text",
        keys: ["standard"]
    },
    checklist_sensor_rotationLogic: {
        label: {
            fr: "Logique",
            en: "Logic"
        },
		category: ['ebc'],
		type: "input",
        columnWeight: 3,
        keys: ["standard"]
    },
    checklist_sensor_topSpeedControl: {
        label: {
            fr: "Contrôle top vitesse",
            en: "Top speed control"
        },
		category: ['ebc'],
		type: "text",
        keys: ["standard"]
    },
    checklist_sensor_topSpeedControlLogic: {
        label: {
            fr: "Logique",
            en: "Logic"
        },
		category: ['ebc'],
		type: "input",
        columnWeight: 3,
        keys: ["standard"]
    },
    checklist_sensor_cylinderRecoil: {
        label: {
            fr: "Recul vérin",
            en: "Cylinder recoil"
        },
		category: ['ebc'],
		type: "text",
        keys: ["standard"]
    },
    checklist_sensor_cylinderRecoilLogic: {
        label: {
            fr: "Logique",
            en: "Logic"
        },
		category: ['ebc'],
		type: "input",
        columnWeight: 3,
        keys: ["standard"]
    },
    checklist_sensor_stateDivision_1: {
        label: {
            fr: "État en -1 division",
            en: "State in -1 division"
        },
		category: ['ebc'],
		type: "text",
        keys: ["standard"]
    },
    checklist_sensor_stateDivision_1Logic: {
        label: {
            fr: "Logique",
            en: "Logic"
        },
		category: ['ebc'],
		type: "input",
        keys: ["standard"]
    },
    checklist_sensor_stateDivision_1Analog: {
        label: {
            fr: "Analogique",
            en: "Analog"
        },
		category: ['ebc'],
		type: "input",
        keys: ["standard"]
    },
    checklist_sensor_stateDivision_1ClampForce: {
        label: {
            fr: "Force de serrage",
            en: "Clamp Force"
        },
		category: ['ebc'],
		type: "input",
        unit: "kN",
        keys: ["standard"]
    },
    checklist_sensor_stateDivision_2: {
        label: {
            fr: "État en -2 division",
            en: "State in -2 division"
        },
		category: ['ebc'],
		type: "text",
        keys: ["standard"]
    },
    checklist_sensor_stateDivision_2Logic: {
        label: {
            fr: "Logique",
            en: "Logic"
        },
		category: ['ebc'],
		type: "input",
        keys: ["standard"]
    },
    checklist_sensor_stateDivision_2Analog: {
        label: {
            fr: "Analogique",
            en: "Analog"
        },
		category: ['ebc'],
		type: "input",
        keys: ["standard"]
    },
    checklist_sensor_stateDivision_2ClampForce: {
        label: {
            fr: "Force de serrage",
            en: "Clamp Force"
        },
		category: ['ebc'],
		type: "input",
        unit: "kN",
        keys: ["standard"]
    },
    checklist_sensor_stateDivision_3: {
        label: {
            fr: "État en -3 division",
            en: "State in -3 division"
        },
		category: ['ebc'],
		type: "text",
        keys: ["standard"]
    },
    checklist_sensor_stateDivision_3Logic: {
        label: {
            fr: "Logique",
            en: "Logic"
        },
		category: ['ebc'],
		type: "input",
        keys: ["standard"]
    },
    checklist_sensor_stateDivision_3Analog: {
        label: {
            fr: "Analogique",
            en: "Analog"
        },
		category: ['ebc'],
		type: "input",
        keys: ["standard"]
    },
    checklist_sensor_stateDivision_3ClampForce: {
        label: {
            fr: "Force de serrage",
            en: "Clamp Force"
        },
		category: ['ebc'],
		type: "input",
        unit: "kN",
        keys: ["standard"]
    },
    checklist_sensor_stateDivision1: {
        label: {
            fr: "État en 1 division",
            en: "State in 1 division"
        },
		category: ['ebc'],
		type: "text",
        keys: ["standard"]
    },
    checklist_sensor_stateDivision1Logic: {
        label: {
            fr: "Logique",
            en: "Logic"
        },
		category: ['ebc'],
		type: "input",
        keys: ["standard"]
    },
    checklist_sensor_stateDivision1Analog: {
        label: {
            fr: "Analogique",
            en: "Analog"
        },
		category: ['ebc'],
		type: "input",
        keys: ["standard"]
    },
    checklist_sensor_stateDivision1ClampForce: {
        label: {
            fr: "Force de serrage",
            en: "Clamp Force"
        },
		category: ['ebc'],
		type: "input",
        unit: "kN",
        keys: ["standard"]
    },
    checklist_sensor_stateDivision2: {
        label: {
            fr: "État en 2 division",
            en: "State in 2 division"
        },
		category: ['ebc'],
		type: "text",
        keys: ["standard"]
    },
    checklist_sensor_stateDivision2Logic: {
        label: {
            fr: "Logique",
            en: "Logic"
        },
		category: ['ebc'],
		type: "input",
        keys: ["standard"]
    },
    checklist_sensor_stateDivision2Analog: {
        label: {
            fr: "Analogique",
            en: "Analog"
        },
		category: ['ebc'],
		type: "input",
        keys: ["standard"]
    },
    checklist_sensor_stateDivision2ClampForce: {
        label: {
            fr: "Force de serrage",
            en: "Clamp Force"
        },
		category: ['ebc'],
		type: "input",
        unit: "kN",
        keys: ["standard"]
    },
    checklist_sensor_stateDivision3: {
        label: {
            fr: "État en 3 division",
            en: "State in 3 division"
        },
		category: ['ebc'],
		type: "text",
        keys: ["standard"]
    },
    checklist_sensor_stateDivision3Logic: {
        label: {
            fr: "Logique",
            en: "Logic"
        },
		category: ['ebc'],
		type: "input",
        keys: ["standard"]
    },
    checklist_sensor_stateDivision3Analog: {
        label: {
            fr: "Analogique",
            en: "Analog"
        },
		category: ['ebc'],
		type: "input",
        keys: ["standard"]
    },
    checklist_sensor_stateDivision3ClampForce: {
        label: {
            fr: "Force de serrage",
            en: "Clamp Force"
        },
		category: ['ebc'],
		type: "input",
        unit: "kN",
        keys: ["standard"]
    },
};

export const checkListSensorFields = fieldsParser(_checklistSensorFields);