import fieldsParser from "../../../fieldsParser";

export const _bearingsStrutsFields = {
    bearings_struts_frontOnShaft: {
        label: {
            fr: "Avant sur arbre",
            en: "Front on shaft"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    bearings_struts_frontInCompartment: {
        label: {
            fr: "Avant dans logement",
            en: "Front in compartment"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    bearings_struts_backOnShaft: {
        label: {
            fr: "Arrière sur arbre",
            en: "Back on shaft"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    bearings_struts_backInCompartment: {
        label: {
            fr: "Arrière dans logement",
            en: "Back in compartment"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    bearings_struts_other: {
        label: {
            fr: "Autre",
            en: "Other"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    bearings_struts_spacerSetPhotos: {
        label: {
            fr: "Photo ensembles entretoises",
            en: "Spacer sets photo"
        },
        category: ['ebc'],
        type: 'photo',
        directoryName: 'Ensembles_entretoises'
    }
};

export const bearingsStrutsFields = fieldsParser(_bearingsStrutsFields);