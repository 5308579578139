import fieldsParser from "../../../fieldsParser";

export const _bearingsOilChannelsFields = {
    bearings_oilChannels_MV1: {
        label: {
            fr: "MV1",
            en: "MV1"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    bearings_oilChannels_MV1Value: {
        label: {
            fr: "MV1 (valeur)",
            en: "MV1 (value)"
        },
		category: ['ebc'],
		type: "select",
        defaultOptions: {
            Obstructed: { label: { fr: "Obstrué", en: "Obstructed" } },
            polluted: { label: { fr: "Pollué", en: "Polluted" } },
            no_oil: { label: { fr: "Absence d'huile", en: "No oil" } },
            other: { label: { fr: "Autre", en: "Other" } },
        }
    },
    bearings_oilChannels_MV2: {
        label: {
            fr: "MV2",
            en: "MV2"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    bearings_oilChannels_MV2Value: {
        label: {
            fr: "MV2 (valeur)",
            en: "MV2 (value)"
        },
		category: ['ebc'],
		type: "select",
        defaultOptions: {
            Obstructed: { label: { fr: "Obstrué", en: "Obstructed" } },
            polluted: { label: { fr: "Pollué", en: "Polluted" } },
            no_oil: { label: { fr: "Absence d'huile", en: "No oil" } },
            other: { label: { fr: "Autre", en: "Other" } },
        }
    },
    bearings_oilChannels_MV3: {
        label: {
            fr: "MV3",
            en: "MV3"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    bearings_oilChannels_MV3Value: {
        label: {
            fr: "MV3 (valeur)",
            en: "MV3 (value)"
        },
		category: ['ebc'],
		type: "select",
        defaultOptions: {
            Obstructed: { label: { fr: "Obstrué", en: "Obstructed" } },
            polluted: { label: { fr: "Pollué", en: "Polluted" } },
            no_oil: { label: { fr: "Absence d'huile", en: "No oil" } },
            other: { label: { fr: "Autre", en: "Other" } },
        }
    },
    bearings_oilChannels_MV4: {
        label: {
            fr: "MV4",
            en: "MV4"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    bearings_oilChannels_MV4Value: {
        label: {
            fr: "MV4 (valeur)",
            en: "MV4 (value)"
        },
		category: ['ebc'],
		type: "select",
        defaultOptions: {
            Obstructed: { label: { fr: "Obstrué", en: "Obstructed" } },
            polluted: { label: { fr: "Pollué", en: "Polluted" } },
            no_oil: { label: { fr: "Absence d'huile", en: "No oil" } },
            other: { label: { fr: "Autre", en: "Other" } },
        }
    },
    bearings_oilChannels_MV5: {
        label: {
            fr: "MV5",
            en: "MV5"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    bearings_oilChannels_MV5Value: {
        label: {
            fr: "MV5 (valeur)",
            en: "MV5 (value)"
        },
		category: ['ebc'],
		type: "select",
        defaultOptions: {
            Obstructed: { label: { fr: "Obstrué", en: "Obstructed" } },
            polluted: { label: { fr: "Pollué", en: "Polluted" } },
            no_oil: { label: { fr: "Absence d'huile", en: "No oil" } },
            other: { label: { fr: "Autre", en: "Other" } },
        }
    },
};

export const bearingsOilChannelsFields = fieldsParser(_bearingsOilChannelsFields);