import fieldsParser from "../../fieldsParser";

export const _rotorShaftSpindleBodyFields = {
    rotorShaft_spindleBody_nonexistent: {
        label: {
            fr: "Inexistant",
            en: "Nonexistent"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    rotorShaft_spindleBody_nonDisassembled: {
        label: {
            fr: "Non démonté",
            en: "Non-disassembled"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    rotorShaft_spindleBody_outsideCorrosion: {
        label: {
            fr: "Aspect visuel extérieur - Corrosion",
            en: "Outside appearance - Corrosion"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    rotorShaft_spindleBody_outsideContactRust: {
        label: {
            fr: "Aspect visuel extérieur - Rouille de contact",
            en: "Outside appearance - Contact rust"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    rotorShaft_spindleBody_outsideMechanicalWear: {
        label: {
            fr: "Aspect visuel extérieur - Usure mécanique",
            en: "Outside appearance - Mechanical wear"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    rotorShaft_spindleBody_insideCorrosion: {
        label: {
            fr: "Aspect visuel intérieur - Corrosion",
            en: "Inside appearance - Corrosion"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    rotorShaft_spindleBody_insideContactRust: {
        label: {
            fr: "Aspect visuel intérieur - Rouille de contact",
            en: "Inside appearance - Contact rust"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    rotorShaft_spindleBody_insideMechanicalWear: {
        label: {
            fr: "Aspect visuel intérieur - Usure mécanique",
            en: "Inside appearance - Mechanical wear"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    rotorShaft_spindleBody_porteesRoulementsCorrosion: {
        label: {
            fr: "Portées des roulements - Corrosion",
            en: "Portées des roulements - Corrosion"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    rotorShaft_spindleBody_porteesRoulementsContactRust: {
        label: {
            fr: "Portées des roulements - Rouille de contact",
            en: "Portées des roulements - Contact rust"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    rotorShaft_spindleBody_porteesRoulementsMechanicalWear: {
        label: {
            fr: "Portées des roulements - Usure mécanique",
            en: "Portées des roulements - Mechanical wear"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    rotorShaft_spindleBody_possibleCauses: {
        label: {
            fr: "Causes possibles",
            en: "Possible causes"
        },
		category: ['kessler'],
		type: "input"
    },
    rotorShaft_spindleBody_necessaryMeasures: {
        label: {
            fr: "Mesures à prendre",
            en: "Necessary measures"
        },
		category: ['kessler'],
		type: "input"
    },
    rotorShaft_spindleBody_spindleBodyPhoto: {
        label: {
            fr: "Photo du corps de la broche",
            en: "Spindle body photo"
        },
		category: ['kessler'],
		type: 'photo',
        directoryName: 'Arbre-rotor',
        columnWeight: 4
    }
};

export const rotorShaftSpindleBodyFields = fieldsParser(_rotorShaftSpindleBodyFields);