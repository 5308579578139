import fieldsParser from "../../../fieldsParser";

export const _tighteningAndDousingSystemKesslerMechanicalTestsFields = {
    tighteningAndDousingSystem_kessler_mechanicalTests_ratedInjectionPliers: {
        label: {
            fr: "Pince rentrée nominale (mm)",
            en: "Rated injection pliers (mm)"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_mechanicalTests_measuredInjectionPliers: {
        label: {
            fr: "Pince rentrée mesurée (mm)",
            en: "Measured injection pliers (mm)"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_mechanicalTests_ratedCoteEjection: {
        label: {
            fr: "Côte d'éjection nominale (mm)",
            en: "Côte d'éjection nominale (mm)"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_mechanicalTests_measuredCoteEjection: {
        label: {
            fr: "Côte d'éjection mesurée (mm)",
            en: "Côte d'éjection mesurée (mm)"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_mechanicalTests_ratedClampingStroke: {
        label: {
            fr: "Course de serrage nominale (mm)",
            en: "Rated clamping stroke (mm)"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_mechanicalTests_measuredClampingStroke: {
        label: {
            fr: "Course de serrage mesurée (mm)",
            en: "Measured clamping stroke (mm)"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_mechanicalTests_ratedHSKBoltBattement: {
        label: {
            fr: "Battement boulon HSK nominal (mm)",
            en: "Battement boulon HSK nominal (mm)"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    tighteningAndDousingSystem_kessler_mechanicalTests_measuredHSKBoltBattement: {
        label: {
            fr: "Battement boulon HSK mesuré (mm)",
            en: "Battement boulon HSK mesuré (mm)"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
};

export const tighteningAndDousingSystemKesslerMechanicalTestsFields = fieldsParser(_tighteningAndDousingSystemKesslerMechanicalTestsFields);