<style src="../../assets/css/inputeo.css" scoped></style>

<style scoped>

    .search-result {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: white;
        border-radius: 0 0 4px 4px;
        box-shadow: 0 4px 12px -2px rgba(0, 10, 20, 0.3);
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 250px;
        z-index: 20;
    }

    .search-result .result {
        padding: 10px 15px;
        color: rgb(50, 60, 70);
        font-weight: 500;
        cursor: pointer;
        transition: 0.1s ease;
    }

    .search-result .result:not(:last-child) {
        border-bottom: 1px solid rgb(230, 235, 240);
    }

    .search-result .result:hover, .search-result .result.selected {
        background-color: rgb(246, 248, 250);
    }

</style>

<template>

    <div class="inputeo">
        <div v-if="displayError && error" class="invalid-mark">
            <div class="invalid-mark-message-container">
                <div class="invalid-mark-message">{{ error }}</div>
            </div>
        </div>
        <label>{{ label }}</label>
        <input
            :id="inputId"
            :value="modelValue?.value"
            @input="asyncSearch"
            @focusin="asyncSearch"
            @focusout="inputFocusOut"
            @keyup="selectResultKeyPress"
        />
        <div class="search-result" :id="searchResultId">
            <div class="result" v-for="option in options" :key="option" :data-id="option.id" :data-value="option.value" @click="selectResult">
                {{ option.value }}
            </div>
        </div>
    </div>

</template>

<script>

    import $ from 'jquery';
    import anime from 'animejs';
    import axios from 'axios';

    export default {
        name: 'BaseSearchingInput',
        components: {
        },
        props: {
            name: {
                type: String,
                default: ''
            },
            label: {
                type: String,
                default: ''
            },
            api: {
                type: String,
                default: ''
            },
            apiParams: {
                type: Object,
                default: null
            },
            modelValue: {
                type: Object,
                default: null
            },
            required: {
                type: Boolean,
                default: true
            },
            displayError: {
                type: Boolean,
                default: false
            },
            error: {
                type: String,
                default: ''
            },
            fieldId: {
                type: String,
                default: ''
            },
            fieldValue: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                inputId: null,
                searchResultId: null,
                selectedId: null,
                options: [],
                isFromUser: false
            }
        },
        watch: {
            modelValue: {
                handler(modelValue) {
                    this.checkValue(modelValue, this.isFromUser ? 'user' : 'auto');
                    this.isFromUser = false;
                }
            }
        },
        mounted() {
            this.inputId = Math.floor(Math.random() * Date.now());
            this.searchResultId = Math.floor(Math.random() * Date.now());
            this.checkValue(this.modelValue, 'init');
        },
        methods: {
            async asyncSearch(event) {
                let value = event.target.value;

                if(value !== this.modelValue?.value) {
                    this.$nextTick(() => {
                        this.isFromUser = value.length === 0;
                        this.$emit('update:modelValue', {
                            value: value,
                            id: null,
                            data: null
                        });
                    });
                }

                let query = $('#' + this.inputId).val();
                let searchResult = $('#' + this.searchResultId);
                let $this = this;
                if(event.data != null) {
                    $this.selectedId = null;
                }
                searchResult.css('opacity', '1');

                // Si on ne recherche rien ou pas suffisament de caractères, on fourni aucune option
                if (query === null || query.length < parseInt(this.minChars) || query === "") {
                    this.hasMinimalCharSearch = false;
                    this.selectedId = null;
                    this.options = [];
                    searchResult.css('display', 'none');
                    return [];
                }

                this.hasMinimalCharSearch = true;

                // Si on utilise une API pour fournir les options
                if (this.api !== null) {
                    let payload = {
                        searchedText: query,
                    }

                    for (let apiParamKey in this.apiParams) {
                        if (this.apiParams[apiParamKey] == null) {
                            delete this.apiParams[apiParamKey];
                        }
                    }

                    if (this.apiParams !== null) {
                        payload = Object.assign(payload, this.apiParams);
                    }

                    const params = new URLSearchParams(payload);

                  console.log(this.api + '?' + params)
                    let result = await axios
                        .get(this.api + '?' + params, {
                            showPreloader: false
                        });

                    this.options = result.data.length === 0 ? [] : result.data.map((element) => {
                        return {
                            id: element[this.fieldId],
                            value: element[this.fieldValue],
                            data: element
                        }
                    });

                    if(this.options.length > 0) {
                        searchResult.css('display', 'block');
                    } else {
                        searchResult.css('display', 'none');
                    }
                }
            },
            inputFocusOut() {
                let $this = this;
                anime({
                    targets: $('#' + $this.searchResultId).get(0),
                    duration: 150,
                    easing: 'easeOutSine',
                    opacity: [1, 0],
                    complete: function () {
                        $('#' + $this.searchResultId).css('display', 'none');
                    }
                });
            },
            selectResult(event) {
                let element = $(event.target);
                this.selectedId = element.attr('data-id');
                this.$nextTick(() => {
                    let value = element.attr('data-value');
                    this.isFromUser = true;
                    this.$emit('update:modelValue', {
                        value: value,
                        id: this.selectedId,
                        data: this.options.find(e => e.id == this.selectedId)?.data
                    });
                    this.checkValue({value: value}, this.isFromUser ? 'user' : 'auto');
                });
                $('#' + this.searchResultId).find('.selected').removeClass('selected');
            },
            selectResultKeyPress(event) {
                let searchResult = $('#' + this.searchResultId);
                if(searchResult.is(':visible')) {
                    let resultSelected = searchResult.find('.selected');
                    resultSelected.removeClass('selected');

                    switch(event.key) {
                        case "ArrowDown":
                            if(resultSelected.length == 0) {
                                searchResult.find('.result:first-child').addClass('selected');
                            } else {
                                let next = resultSelected.next();
                                if(next.length == 0) {
                                    resultSelected.addClass('selected');
                                } else {
                                    next.addClass('selected');
                                }
                            }
                            break;
                        case "ArrowUp":
                            if(resultSelected.length > 0) {
                                let prev = resultSelected.prev();
                                if(prev.length == 0) {
                                    resultSelected.addClass('selected');
                                } else {
                                    prev.addClass('selected');
                                }
                            }
                            break;
                        case "Enter":
                            if(resultSelected.length > 0) {
                                this.selectedId = resultSelected.attr('data-id');
                                this.$nextTick(() => {
                                    let value = resultSelected.attr('data-value');
                                    this.isFromUser = true;
                                    this.$emit('update:modelValue', {
                                        value: value,
                                        id: this.selectedId,
                                        data: this.options.find(e => e.id == this.selectedId)?.data
                                    });
                                    this.checkValue({value: value}, this.isFromUser ? 'user' : 'auto');
                                });
                                $('#' + this.inputId).trigger('blur');
                                $('#' + this.searchResultId).css('display', 'none');
                            }
                            break;
                        case "Backspace":
                            this.selectedId = null;
                            break;
                        default:
                            break;
                    }
                }
            },
            checkValue(value, changeType) {
                let error = null;

                // Si on a spécifié une longueur minimale et/ou maximale
                if (this.required === true) {
                    if (value === null || (typeof value.value === 'string' && value?.value.trim().length === 0)) {
                        error = `Merci de saisir au moins un caractère`;
                    }
                }

                // On émet l'événement pour spécifier qu'il y a un changement
                this.$emit('onChange', {
                    name: this.name,
                    value: value,
                    error: {
                        type: 'validator',
                        message: error
                    },
                    changeType: changeType
                });
            }
        }
    }

</script>
