import fieldsParser from "@/formFields/fieldsParser";

export const _onSiteSurveysFields = {
    onsite_electrical_part: {
        label: {
            fr: "Partie électrique",
            en: "Electrical part"
        },
        category: ['ebc'],
        type: "input",
        inputType: "textarea",
        columnWeight: 4,
        rowWeight: 6
    },
    onsite_mechanical_part: {
        label: {
            fr: "Partie mécanique",
            en: "Mechanical part"
        },
        category: ['ebc'],
        type: "input",
        inputType: "textarea",
        columnWeight: 4,
        rowWeight: 6
    }
}

export const onSiteSurveysFields = fieldsParser(_onSiteSurveysFields);