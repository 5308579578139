import fieldsParser from "../../fieldsParser";

export const _coolingSystemRepairsFields = {
    coolingSystem_repairs_cleaning: {
        label: {
            fr: "Nettoyage",
            en: "Cleaning"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    coolingSystem_repairs_installationOfFretsOrInserts: {
        label: {
            fr: "Pose de frettes ou d'inserts",
            en: "Installation of frets or inserts"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    coolingSystem_repairs_gasketsReplacement: {
        label: {
            fr: "Remplacement des joints",
            en: "Gaskets replacement"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    coolingSystem_repairs_piecesReplacement: {
        label: {
            fr: "Remplacement des pièces",
            en: "Pieces replacement"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    coolingSystem_repairs_observations: {
        label: {
            fr: "Observations",
            en: "Observations"
        },
		category: ['ebc'],
		type: "input",
        inputType: "textarea",
        columnWeight: 4
    },
};

export const coolingSystemRepairsFields = fieldsParser(_coolingSystemRepairsFields);