import fieldsParser from "../../fieldsParser";

export const _coolingSystemPhotosFields = {
    coolingSystem_photosPhoto: {
        label: {
            fr: "Photos du circuit de refroidissement",
            en: "Cooling system photos"
        },
		category: ['ebc'],
		type: 'photo',
        directoryName: 'Systeme_refroidissement'
    }
};

export const coolingSystemPhotosFields = fieldsParser(_coolingSystemPhotosFields);