import fieldsParser from "../../fieldsParser";

export const _encoderRotaryEncoderReadingFields = {
    encoder_rotaryEncoderReading_wheelType: {
        label: {
            fr: "Type de roue",
            en: "Wheel type"
        },
		category: ['ebc'],
		type: "select",
        defaultOptions: {
            without_speed_measurement: { label: { fr: "Sans mesure de vitesse", en: "Without speed measurement" } },
            monoblock_wheel_03: { label: { fr: "Roue m 0.3 monobloc", en: "Monoblock m 0.3 wheel" } },
            monoblock_wheel_05: { label: { fr: "Roue m 0.5 monobloc", en: "Monoblock m 0.5 wheel" } },
            top_added_wheel_03: { label: { fr: "Roue m 0.3 top rapporté", en: "Wheel m 0.3 top added" } },
            top_added_wheel_05: { label: { fr: "Roue m 0.5 top rapporté", en: "Wheel m 0.5 top added" } },
        }
    },
    encoder_rotaryEncoderReading_generalAppearance_okko: {
        label: {
            fr: "Aspect général",
            en: "General appearance"
        },
		category: ['ebc'],
        type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    encoder_rotaryEncoderReading_generalAppearance: {
        label: {
            fr: "Aspect général",
            en: "General appearance"
        },
		category: ['ebc'],
		type: "input"
    },
    encoder_rotaryEncoderReading_battement: {
        label: {
            fr: "Battement",
            en: "Battement"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    encoder_rotaryEncoderReading_measuredRunout: {
        label: {
            fr: "Faux rond mesuré (µm)",
            en: "Measured runout (µm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number"
    },
};

export const encoderRotaryEncoderReadingFields = fieldsParser(_encoderRotaryEncoderReadingFields);