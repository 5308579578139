import fieldsParser from "../../fieldsParser";

export const _sensorsRepairsFields = {
    sensors_repairs_sensorsReplacement: {
        label: {
            fr: "Remplacement des capteurs",
            en: "Sensors replacement"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    sensors_repairs_baseplateSocketReplacement: {
        label: {
            fr: "Remplacement prise embase",
            en: "Baseplate socket replacement"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
    sensors_repairs_connectorsReplacement: {
        label: {
            fr: "Remplacement connecteurs",
            en: "Connectors replacement"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
        }
    },
};

export const sensorsRepairsFields = fieldsParser(_sensorsRepairsFields);