import fieldsParser from "../../fieldsParser";

export const _sensorsFields = {
    sensors_nonexistent: {
        label: {
            fr: "Inexistants",
            en: "Nonexistent"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    sensors_nonDisassembled: {
        label: {
            fr: "Non démontés",
            en: "Non-disassembled"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    sensors_PNP: {
        label: {
            fr: "PNP",
            en: "PNP"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    sensors_analog: {
        label: {
            fr: "Analog",
            en: "Analog"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    sensors_typeB: {
        label: {
            fr: "Type B",
            en: "Type B"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    sensors_typeS: {
        label: {
            fr: "Type S",
            en: "Type S"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    sensors_articleNumber: {
        label: {
            fr: "Numéro de l'article",
            en: "Article number"
        },
		category: ['precise'],
		type: "input",
        inputType: "number"
    },
    sensors_controlDevice: {
        label: {
            fr: "Appareil de contrôle",
            en: "Control device"
        },
		category: ['precise'],
		type: "select",
        defaultOptions: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    sensors_NR: {
        label: {
            fr: "NR",
            en: "NR"
        },
		category: ['precise'],
		type: "input",
        inputType: "number"
    },
    sensors_a1Min: {
        label: {
            fr: "A1 min",
            en: "A1 min"
        },
		category: ['precise'],
		type: "input",
        inputType: "number"
    },
    sensors_a1Max: {
        label: {
            fr: "A1 max",
            en: "A1 max"
        },
		category: ['precise'],
		type: "input",
        inputType: "number"
    },
    sensors_a1Measured: {
        label: {
            fr: "A1 mesurée",
            en: "A1 measured"
        },
		category: ['precise'],
		type: "input",
        inputType: "number"
    },
    sensors_a2Min: {
        label: {
            fr: "A2 min",
            en: "A2 min"
        },
		category: ['precise'],
		type: "input",
        inputType: "number"
    },
    sensors_a2Max: {
        label: {
            fr: "A2 max",
            en: "A2 max"
        },
		category: ['precise'],
		type: "input",
        inputType: "number"
    },
    sensors_a2Measured: {
        label: {
            fr: "A2 mesurée",
            en: "A2 measured"
        },
		category: ['precise'],
		type: "input",
        inputType: "number"
    }
};

export const sensorsFields = fieldsParser(_sensorsFields);