import fieldsParser from "../../fieldsParser";

export const _motorResistanceTestsFields = {
    motor_resistanceTests_ratedU1U2: {
        label: {
            fr: "U1/U2 nominale",
            en: "Rated U1/U2"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    motor_resistanceTests_measuredU1U2: {
        label: {
            fr: "U1/U2 mesurée",
            en: "Measured U1/U2"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    motor_resistanceTests_ratedV1V2: {
        label: {
            fr: "V1/V2 nominale",
            en: "Rated V1/V2"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    motor_resistanceTests_measuredV1V2: {
        label: {
            fr: "V1/V2 mesurée",
            en: "Measured V1/V2"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    motor_resistanceTests_ratedW1W2: {
        label: {
            fr: "W1/W2 nominale",
            en: "Rated W1/W2"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    motor_resistanceTests_measuredW1W2: {
        label: {
            fr: "W1/W2 mesurée",
            en: "Measured W1/W2"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
};

export const motorResistanceTestsFields = fieldsParser(_motorResistanceTestsFields);