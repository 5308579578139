import fieldsParser from "../../fieldsParser"

export const _checklistBalancingFields = {
    checklist_balancing_vibrationMeasuringDevice: {
        label: {
            fr: "Appareil de mesure vibratoire",
            en: "Vibration measuring device"
        },
        category: ['ebc'],
        type: "input",
        inputType: "text"
    },
    checklist_balancing_enduranceCycleWithM19Indexing: {
        label: {
            fr: "Cycle d'endurance avec indexages M19",
            en: "Endurance cycle with M19 indexing"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_balancing_duration: {
        label: {
            fr: "Durée (Heures)",
            en: "Duration (Hours)"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number"
    },
    checklist_balancing_spindleBenchPhoto: {
        label: {
            fr: "Contrôle des relevés d'équilibrage dynamique sur banc d'essai",
            en: "Checking dynamic balancing readings on a test bench"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_balancing_bearingFrequencyStatement: {
        label: {
            fr: "Relevé des fréquences propres aux roulements avec système d'analyse",
            en: "Frequency reading specific to bearings with analysis system"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        keys: ["standard"]
    },
    checklist_balancing_spindleNoiseTemperature: {
        label: {
            fr: "Température nez de broche",
            en: "Spindle noise temperature"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        negative: false
    },
    checklist_balancing_savedProgram: {
        label: {
            fr: "Nom du programme enregistré",
            en: "Saved program name"
        },
        category: ['ebc'],
        type: "input",
        inputType: "text"
    },
    checklist_balancing_measurementTestBenchPhoto: {
        label: {
            fr: "Photo du relevé d'équilibrage dynamique sur banc d'essai",
            en: "Dynamic balancing measurement on a test bench photo"
        },
        category: ['ebc'],
        type: 'photo',
        directoryName: 'Releve_equilibrage',
        limit: 1
    },
    checklist_balancing_measurementPhoto: {
        label: {
            fr: "Photo relevé d'équilibrage",
            en: "Balancing measurement photo"
        },
        category: ['ebc'],
        type: 'photo',
        directoryName: 'Releve_equilibrage',
        limit: 1
    }
};

export const checkListBalancingFields = fieldsParser(_checklistBalancingFields);