import fieldsParser from "../../fieldsParser";

export const _motorTemperatureFields = {
    motor_temperature_temperatureProbe: {
        label: {
            fr: "Sonde température (Ω)",
            en: "Temperature probe (Ω)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number"
    },
    motor_temperature_PTC: {
        label: {
            fr: "PTC (Ω)",
            en: "PTC (Ω)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number"
    },
    motor_temperature_PT100: {
        label: {
            fr: "PT100 (Ω)",
            en: "PT100 (Ω)"
        },
		category: ['ebc', 'kessler'],
		type: "input",
        inputType: "number"
    },
    motor_temperature_NTC: {
        label: {
            fr: "NTC (Ω)",
            en: "NTC (Ω)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number"
    },
    motor_temperature_contact: {
        label: {
            fr: "Autre (Ω)",
            en: "Other (Ω)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number"
    },
    motor_temperature_KTY: {
        label: {
            fr: "KTY (Ω)",
            en: "KTY (Ω)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number"
    },
    motor_temperature_PT1000: {
        label: {
            fr: "PT1000 (Ω)",
            en: "PT1000 (Ω)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number"
    },
    motor_temperature_ratedThermometerProbe: {
        label: {
            fr: "Sonde thermomètre nominale (kΩ)",
            en: "Rated thermometer probe (kΩ)"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    motor_temperature_measuredThermometerProbe: {
        label: {
            fr: "Sonde thermomètre mesurée (kΩ)",
            en: "Measured thermometer probe (kΩ)"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    motor_temperature_ratedPTCProbe: {
        label: {
            fr: "Sonde PTC nominale (kΩ)",
            en: "Rated PTC probe (kΩ)"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    motor_temperature_measuredPTCProbe: {
        label: {
            fr: "Sonde PTC mesurée (kΩ)",
            en: "Measured PTC probe (kΩ)"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    motor_temperature_spindleTemperature: {
        label: {
            fr: "Température de la broche",
            en: "Spindle temperature"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
};

export const motorTemperatureFields = fieldsParser(_motorTemperatureFields);