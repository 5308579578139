import fieldsParser from "@/formFields/fieldsParser"

export const _balancingAnalysisFields = {
    balancing_rpm: {
        label: {
            fr: "Trs/min",
            en: "Trs/min"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        dynamic: true,
        keys: ["standard"]
    },
    balancing_front: {
        label: {
            fr: "mm/s avant",
            en: "mm/s front"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        dynamic: true,
        keys: ["standard"]
    },
    balancing_rear: {
        label: {
            fr: "mm/s arrière",
            en: "mm/s rear"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        dynamic: true,
        keys: ["standard"]
    }
}

export const balancingAnalysisFields = fieldsParser(_balancingAnalysisFields)
