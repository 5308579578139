<style scoped>
form {
    display: grid;
    column-gap: 15px;
    align-items: flex-start;
    padding: 25px 10px 0 10px;
}
.checkbox-button-container {
    padding: 0;
    margin: 0px 15px 20px 15px;
}
.green-radio-button :deep(.radio-button input:checked + div) {
    background: linear-gradient(90deg, #4FA601 ,  #7ad12c) !important;
}

.custom-form-title {
    background-color: #0d3b70;
    color: white;
    height: 32px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 13px;
    position: relative;
    margin-top: 20px;
}

.custom-form-title:after {
    position: absolute;
    right: -20px;
    top: 0;
    content: '';
    width: 0;
    height: 0;
    border-top: 0 solid transparent;
    border-bottom: 32px solid transparent;
    border-left: 20px solid #0d3b70;
}
.text {
    margin: 10px 15px 20px 30px;
    font-weight: bold;
    font-size: 15px;
    text-transform: uppercase;
}
</style>

<template>
    <div class="custom-form-title" v-if="formTitle !== null && fields.filter(field => isFieldFromCategory(field) && (!field?.keys?.length > 0 || field.keys.filter(key => keys.indexOf(key) !== -1).length > 0) && checkCondition(field)).length > 0">{{ formTitle }}</div>
    <form :style="formGridStyle" v-if="fields.filter(field => isFieldFromCategory(field) && (!field?.keys?.length > 0 || field.keys.filter(key => keys.indexOf(key) !== -1).length > 0) && checkCondition(field)).length > 0">
        <template v-for='field in fields' :key="field.name">
            <template v-if="field.type === 'text' && isFieldFromCategory(field)">
                <div class="text">{{ field.label }}</div>
            </template>
            <template v-else-if="field.type === 'input' && isFieldFromCategory(field)">
                <BaseInput
                    v-if="(!field?.keys?.length > 0 || field.keys.filter(key => keys.indexOf(key) !== -1).length > 0) && checkCondition(field)"
                    v-model="modelValue[`${field.name}${index ? `_${index}` : ''}`]"
                    :name="field.name"
                    :label="field.label"
                    :type="field.inputType"
                    :style="`${getFieldGridStyle(field)}`"
                    :isMedium="field.medium ?? false"
                    :disabled="disabled"
                    :allowNegative="field.negative"
                />
            </template>
            <template v-else-if="field.type === 'select' && isFieldFromCategory(field)">
                <BaseSelect
                    v-if="!field?.keys?.length > 0 || field.keys.filter(key => keys.indexOf(key) !== -1).length > 0"
                    v-model="modelValue[`${field.name}${index ? `_${index}` : ''}`]"
                    :name="field.name"
                    :label="field.label"
                    :defaultOptions="field.defaultOptions"
                    :multiple="field.multiple ?? false"
                    :style="`${getFieldGridStyle(field)}`"
                    :disabled="disabled"
                    :searchable="true"
                    :taggable="true"
                    :default-selected-value="field.defaultValue"
                    :api="field.api"
                    :apiParams="field.apiParams"
                    :fieldLabel="field.fieldLabel"
                    :fieldValue="field.fieldLabel"
                />
            </template>
            <template v-else-if="field.type === 'radio' && isFieldFromCategory(field)">
                <RadioButtonContainer
                    v-if="!field?.keys?.length > 0 || field.keys.filter(key => keys.indexOf(key) !== -1).length > 0"
                    v-model="modelValue[`${field.name}${index ? `_${index}` : ''}`]"
                    :name="field.name"
                    :label="field.label"
                    :defaultOptions="field.defaultOptions"
                    :style="`${getFieldGridStyle(field)}`"
                    :isMedium="field.medium ?? false"
                    :disabled="disabled"
                >
                    <template v-for='option in field.options' :key="option.value">
                        <BaseRadioButton
                            v-if="!field?.keys?.length > 0 || field.keys.filter(key => keys.indexOf(key) !== -1).length > 0"
                            v-model="modelValue[`${field.name}${index ? `_${index}` : ''}`]"
                            :name="field.name"
                            :label="option.label"
                            :value="option.value"
                            :class="option.value.toUpperCase() === 'OK' || option.value.toUpperCase() === 'YES' ? 'green-radio-button' : ''"
                            :disabled="disabled"
                        />
                    </template>
                    <BaseRadioButton
                        v-if="field.hasNA === null || field.hasNA === true"
                        v-model="modelValue[`${field.name}${index ? `_${index}` : ''}`]"
                        :name="field.name"
                        label="Sans objet"
                        value="NA"
                        :disabled="disabled"
                    />
                </RadioButtonContainer>
            </template>
            <template v-else-if="field.type === 'checkbox' && isFieldFromCategory(field)">
                <BaseCheckbox
                    v-if="!field?.keys?.length > 0 || field.keys.filter(key => keys.indexOf(key) !== -1).length > 0"
                    v-model="modelValue[`${field.name}${index ? `_${index}` : ''}`]"
                    :label="field.label"
                    :color="checkboxColor"
                    :style="`${getFieldGridStyle(field)}`"
                    :disabled="disabled"
                />
            </template>
            <template v-else-if="field.type === 'date' && isFieldFromCategory(field)">
                <BaseDatepicker
                    v-if="!field?.keys?.length > 0 || field.keys.filter(key => keys.indexOf(key) !== -1).length > 0"
                    v-model="modelValue[`${field.name}${index ? `_${index}` : ''}`]"
                    :name="field.name"
                    :label="field.label"
                    :style="`${getFieldGridStyle(field)}`"
                    :disabled="disabled"
                />
            </template>
            <template v-else-if="field.type === 'time' && isFieldFromCategory(field)">
                <BaseDatepicker
                    v-if="!field?.keys?.length > 0 || field.keys.filter(key => keys.indexOf(key) !== -1).length > 0"
                    v-model="modelValue[`${field.name}${index ? `_${index}` : ''}`]"
                    :name="field.name"
                    :label="field.label"
                    :onlyTime="true"
                    :style="`${getFieldGridStyle(field)}`"
                    :disabled="disabled"
                />
            </template>
            <template v-else-if="field.type === 'photo' && isFieldFromCategory(field)">
                <PhotosWithInput
                    v-if="!field?.keys?.length > 0 || field.keys.filter(key => keys.indexOf(key) !== -1).length > 0"
                    v-model="modelValue[`${field.name}${index ? `_${index}` : ''}`]"
                    :label="field.label"
                    :style="`${getFieldGridStyle(field)}`"
                    :directoryPath="`${directoryPath}/${field.directoryName}`"
                    :limit="field.limit"
                    :photoButtons="field.photoButtons"
                    :title="title"
                    :reportFormat="true"
                />
            </template>
            <template v-else-if="field.type === 'schema' && isFieldFromCategory(field)">
                <img
                    :style="`${getFieldGridStyle(field)}; width: ${field.width}; padding: 15px;`"
                    :src="require(`@/assets/images/${field.img}`)"
                />
            </template>
            <div v-if="isFieldFromCategory(field) && (!field?.keys?.length > 0 || field.keys.filter(key => keys.indexOf(key) !== -1).length > 0) && checkCondition(field) && (field.extraColumnSpace || field.extraRowSpace)" :style="`${getFieldGridStyle({columnWeight: field.extraColumnSpace, rowWeight: field.extraRowSpace})}`"></div>
        </template>
    </form>
</template>
<script>
import BaseInput from '../Base/BaseInput'
import BaseSelect from '../Base/BaseSelect'
import RadioButtonContainer from '../App/RadioButtonContainer'
import BaseRadioButton from '../Base/BaseRadioButton'
import BaseDatepicker from '../Base/BaseDatepicker'
import BaseCheckbox from '../Base/BaseCheckbox'
import PhotosWithInput from '../App/PhotosWithInput'
import Photo from "@/components/App/Photo";
export default {
    name: 'CustomForm',
    components: {
        Photo,
        BaseInput,
        BaseSelect,
        RadioButtonContainer,
        BaseRadioButton,
        BaseDatepicker,
        BaseCheckbox,
        PhotosWithInput
    },
    props: {
        modelValue: {
            type: Object,
            default: null
        },
        fields: {
            type: Array,
            default: []
        },
        fieldsPerRow: {
            type: Number,
            default: 1
        },
        checkboxColor: {
            type: String,
            default: ''
        },
        directoryPath: {
            type: String,
            default: ''
        },
        index: {
            type: Number,
            default: null
        },
        title: {
            type: String,
            default: ''
        },
        keys: {
            type: Array,
            default: []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        fieldCategory: {
            type: String,
            default: 'ebc'
        },
        formTitle: {
            type: [String, null],
            default: null
        },
        categoryBorder: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        formGridStyle: function () {
            return `grid-template-columns: ${Array(this.fieldsPerRow).fill('1fr').join(' ')};`;
        }
    },
    data() {
        return {
            defaultRowWeight: 1,
            defaultColumnWeight: 1
        }
    },
    methods: {
        checkCondition(field) {
            if (field?.vif == null) return true;
            let functionCondition = new Function('form', field.vif);
            let isVisible = functionCondition(this.modelValue);
            if (!isVisible) {
                let modelValue = this.modelValue;
                modelValue[field.name] = null;
                this.$emit('update:modelValue', modelValue);
            }
            return isVisible;
        },
        getField(x, y) {
            return this.fields[(x*this.fieldsPerRow)+y];
        },
        getFieldGridStyle(field) {
            let columnWeight = field.columnWeight ?? this.defaultColumnWeight;
            let rowWeight = field.rowWeight ?? this.defaultRowWeight;
            let border = '';
            if (this.categoryBorder) {
                if (field.category?.includes('precise')) {
                    border = 'border: 1px solid #EE7F00;';
                } else if (field.category?.includes('kessler')) {
                    border = 'border: 1px solid #4C8EC3;';
                }
            }
            return `grid-column: span ${columnWeight}; grid-row: span ${rowWeight};${field.color == null || field.color === true ? border : ''}`;
        },
        isFieldFromCategory(field) {
            return field.category?.includes(this.fieldCategory) || (this.fieldCategory === 'kessler' && field.category?.includes('ebc'));
        }
    }
}
</script>