import fieldsParser from "../../fieldsParser"

export const _conformityReportGeometryFields = {
    conformityReport_geometry_concentricity: {
        label: {
            fr: "Concentricité sur cône R1 (µ)",
            en: "Concentricity on cone R1 (µ)"
        },
        category: ['ebc'],
		type: "input",
        inputType: "text",
        keys: ["standard"]
    },
    conformityReport_geometry_tolerance: {
        label: {
            fr: "Tolérance face A1",
            en: "Tolerance on side A1"
        },
        category: ['ebc'],
        type: "select",
        defaultOptions: {
            kn_1: { label: { fr: "<1 µ", en: "<1 µ" } },
            kn_2: { label: { fr: "<2 µ", en: "<2 µ" } },
            kn_3: { label: { fr: "<3 µ", en: "<3 µ" } },
        },
        keys: ["standard"]
    },
    conformityReport_geometry_distance: {
        label: {
            fr: "Distance A sur nez",
            en: "Distance A on nose"
        },
        category: ['ebc'],
        type: "select",
        defaultOptions: {
            kn_1: { label: { fr: "<1 µ", en: "<1 µ" } },
            kn_2: { label: { fr: "<2 µ", en: "<2 µ" } },
            kn_3: { label: { fr: "<3 µ", en: "<3 µ" } },
        },
        keys: ["standard"]
    },
    conformityReport_geometry_parallelism: {
        label: {
            fr: "Parallélisme P (mm)",
            en: "Parallelism P (mm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    conformityReport_geometry_runOut: {
        label: {
            fr: "Faux rond Q (mm)",
            en: "Runout Q (mm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    conformityReport_geometry_flatness: {
        label: {
            fr: "Planéité R (mm)",
            en: "Flatness R (mm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    conformityReport_geometry_length: {
        label: {
            fr: "Longueur L (mm)",
            en: "Length L (mm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
    conformityReport_geometry_length1: {
        label: {
            fr: "Longueur L1 (mm)",
            en: "Length L1 (mm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        keys: ["standard"],
        negative: false
    },
};

export const conformityReportGeometryFields = fieldsParser(_conformityReportGeometryFields);