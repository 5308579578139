import fieldsParser from "../../fieldsParser";

export const _motorFields = {
    motor_motorPhoto: {
      label: {
        fr: "Photos moteur",
        en: "Motor photos"
      },
      category: ['ebc'],
      type: 'photo',
      directoryName: 'Moteur',
      columnWeight: 2,
    },
    motor_statorPhoto: {
        label: {
            fr: "Photo stator",
            en: "Stator photo"
        },
		category: ['kessler', 'ebc'],
		type: 'photo',
        directoryName: 'Moteur',
        columnWeight: 2,
    },
    motor_motorType: {
        label: {
            fr: "Type de moteur",
            en: "Motor type"
        },
		category: ['ebc', 'kessler'],
		type: "select",
        defaultOptions: {
            synchronous: { label: { fr: "Synchrone", en: "Synchronous" } },
            asynchronous: { label: { fr: "Asynchrone", en: "Asynchronous" } },
        }
    },
    motor_risattiTest: {
        label: {
            fr: "Test RISATTI",
            en: "RISATTI test"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    motor_risattiTestValue: {
        label: {
            fr: "Test RISATTI (valeur)",
            en: "RISATTI test (value)"
        },
		category: ['ebc'],
		type: "input"
    },
    motor_FEM: {
        label: {
            fr: "FEM",
            en: "FEM"
        },
		category: ['ebc', 'kessler'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    motor_FEMValue: {
        label: {
            fr: "FEM (valeur)",
            en: "FEM (value)"
        },
		category: ['ebc', 'kessler'],
		type: "input"
    },
    motor_ratedFEMSpeed: {
        label: {
            fr: "FEM vitesse (RPM)",
            en: "FEM speed (RPM)"
        },
		category: ['ebc', 'kessler'],
		type: "input",
        inputType: "number"
    },
    motor_internalCoupling: {
        label: {
            fr: "Couplage interne",
            en: "Internal coupling"
        },
		category: ['kessler'],
		type: "input",
        inputType: "number"
    },
    motor_spindleARconnectionsPhotos: {
        label: {
            fr: "Photos connexions AR de broche",
            en: "Spindle AR connections photos"
        },
		category: ['kessler'],
		type: 'photo',
        directoryName: 'Moteur',
        columnWeight: 2,
    },
    motor_statorStateTestPhoto: {
        label: {
            fr: "Photo du test de l'état du stator",
            en: "Stator state test photo"
        },
		category: ['kessler'],
		type: 'photo',
        directoryName: 'Moteur'
    },
    motor_encoderPhoto: {
        label: {
            fr: "Photo de l'encodeur",
            en: "Encoder photo"
        },
		category: ['kessler'],
		type: 'photo',
        directoryName: 'Moteur'
    },
    motor_analogSensorPhoto: {
        label: {
            fr: "Photo du capteur analogique",
            en: "Analog sensor photo"
        },
		category: ['kessler'],
		type: 'photo',
        directoryName: 'Moteur'
    },
    motor_shortCircuitStator: {
        label: {
            fr: "Stator en court-circuit",
            en: "Short circuit stator"
        },
		category: ['precise'],
		type: "input"
    },
    motor_nonexistent: {
        label: {
            fr: "Inexistant",
            en: "Nonexistent"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    motor_nonDisassembled: {
        label: {
            fr: "Non démonté",
            en: "Non-disassembled"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
};

export const motorFields = fieldsParser(_motorFields);