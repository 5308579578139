import fieldsParser from "../fieldsParser";

export const _preDisassemblyFields = {
     tighteningStrength: {
        label: {
            fr: "Force de serrage",
            en: "Tightening strength"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        },
    },
    tighteningStrengthValue: {
        label: {
            fr: "Force de serrage (kN)",
            en: "Tightening strength (kN)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        extraColumnSpace: 2
    },
    coteEjection: {
        label: {
            fr: "Côte d'éjection",
            en: "Côte d'éjection"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    coteEjectionValue: {
        label: {
            fr: "Côte d'éjection (mm)",
            en: "Côte d'éjection (mm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number"
    },
    preDisassemblySchema: {
        label: {},
        category: ['ebc'],
        type: "schema",
        img: "pre-disassembly-schema.png",
        width: '400px',
        columnWeight: 2,
        rowWeight: 7
    },
    r1battementCone: {
        label: {
            fr: "R1 battement cône",
            en: "R1 battement cône"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    r1battementConeValue: {
        label: {
            fr: "R1 battement cône (µm)",
            en: "R1 battement cône (µm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        extraColumnSpace: 2
    },
    r2battementQuille: {
        label: {
            fr: "R2 battement quille",
            en: "R2 battement quille"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    r2battementQuilleValue: {
        label: {
            fr: "R2 battement quille (µm)",
            en: "R2 battement quille (µm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        extraColumnSpace: 2
    },
    a1battementFace: {
        label: {
            fr: "A1 battement face",
            en: "A1 battement face"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },
    a1battementFaceValue: {
        label: {
            fr: "A1 battement face (µm)",
            en: "A1 battement face (µm)"
        },
		category: ['ebc'],
		type: "input",
        inputType: "number",
        extraColumnSpace: 2
    },
    cablesAndConnectorsState: {
        label: {
            fr: "État câbles et connecteurs",
            en: "Cables and connectors state"
        },
		category: ['ebc'],
		type: "input"
    },
    motorCoilsIsolation: {
        label: {
            fr: "Isolement des enroulements",
            en: "Motor coils isolation"
        },
		category: ['ebc', 'kessler'],
		type: "input"
    },
    fittingsState: {
        label: {
            fr: "État des raccords",
            en: "Fittings state"
        },
		category: ['ebc'],
		type: "input",
        extraColumnSpace: 1
    },
    waterCircuitTightnessPressureLoss: {
        label: {
            fr: "Étanchéité du circuit d'eau",
            en: "Watercircuit tightness"
        },
		category: ['ebc'],
        type: "radio",
        options: {
            OK: { label: { fr: "Bon", en: "Good" } },
            KO: { label: { fr: "Mauvais", en: "Bad" } }
        }
    },

    coneBlowing: {
        label: {
            fr: "Soufflage du cône",
            en: "Cone blowing"
        },
		category: ['ebc'],
		type: "select",
        defaultOptions: {
            nad: { label: { fr: "R.A.S.", en: "N.A.D." } },
            polluted: { label: { fr: "Pollué", en: "Polluted" } },
            obstructed: { label: { fr: "Obstrué", en: "Obstructed" } },
            presence_of_cutting_fluid: { label: { fr: "Présence de liquide de coupe", en: "Presence of cutting fluid" } },
        }
    },
    rotationHardPoint: {
        label: {
            fr: "Point dur lors de la rotation",
            en: "Hard point during rotation"
        },
		category: ['ebc'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    bearingsNoise: {
        label: {
            fr: "Bruit roulements",
            en: "Bearings noise"
        },
		category: ['ebc', 'kessler'],
		type: "select",
        defaultOptions: {
            light: { label: { fr: "Léger", en: "Light" } },
            normal: { label: { fr: "Normal", en: "Normal" } },
            high: { label: { fr: "Élevé", en: "High" } },
        },
    },
    spindlePhotos: {
        label: {
            fr: "Photos vue face",
            en: "Front view photos"
        },
        category: ['ebc'],
        type: 'photo',
        directoryName: 'Broche',
        columnWeight: 1,
        limit: 1,
    },
    rearViewPhoto: {
        label: {
            fr: "Photos vue arrière",
            en: "Rear view photos"
        },
        category: ['ebc'],
        type: 'photo',
        directoryName: 'Broche',
        columnWeight: 1,
        limit: 1,
    },
    sideViewPhoto: {
        label: {
            fr: "Photos vue côtés",
            en: "Side view photos"
        },
        category: ['ebc'],
        type: 'photo',
        directoryName: 'Broche',
        columnWeight: 1,
        limit: 1,
    },
    remarks: {
        label: {
            fr: "Remarques",
            en: "Remarks"
        },
        category: ['ebc'],
        type: "input",
        inputType: "textarea",
        columnWeight: 4
    },
    shaftStuckedInRotation: {
        label: {
            fr: "Arbre bloqué en rotation",
            en: "Shaft stucked in rotation"
        },
		category: ['kessler'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        },
        extraColumnSpace: 4
    },
    cableLength: {
        label: {
            fr: "Longueur câble (mm)",
            en: "Cable length"
        },
		category: ['precise'],
		type: "input",
        inputType: "number"
    },
    captCableLength: {
        label: {
            fr: "Longueur câble Capt (mm)",
            en: "Capt cable length"
        },
		category: ['precise'],
		type: "input",
        inputType: "number",
        extraColumnSpace: 2
    },
    pt1001000_1: {
        label: {
            fr: "Sonde (1)",
            en: "Sonde (1)"
        },
		category: ['precise'],
		type: "input",
        inputType: "number"
    },
    pt1001000_2: {
        label: {
            fr: "Sonde (2)",
            en: "Sonde (2)"
        },
		category: ['precise'],
		type: "input",
        inputType: "number"
    },
    pt1001000_3: {
        label: {
            fr: "Sonde (3)",
            en: "Sonde (3)"
        },
		category: ['precise'],
		type: "input",
        inputType: "number"
    },
    impossibleMeasurements: {
        label: {
            fr: "Mesures impossibles",
            en: "Impossible measurements"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    impactsAndMarks: {
        label: {
            fr: "Chocs et traces",
            en: "Impacts and marks"
        },
		category: ['precise'],
		type: "select",
        defaultOptions: {
            nad: { label: { fr: "R.A.S.", en: "N.A.D." } },
            front_shocks: { label: { fr: "Chocs face avant", en: "Front shocks" } },
            impacts_on_the_spindle_body: { label: { fr: "Chocs sur le corps de broche", en: "Impacts on the spindle body" } },
            rear_cover_shocks: { label: { fr: "Chocs capot arrière", en: "Rear cover shocks" } },
            front_flanges: { label: { fr: "Flasques avant", en: "Front flanges" } }
        }
    },
    assembledSpindle: {
        label: {
            fr: "Broche assemblée",
            en: "Assembled spindle"
        },
		category: ['precise'],
		type: "select",
        defaultOptions: {
            nad: { label: { fr: "R.A.S.", en: "N.A.D." } },
            received_disassembled: { label: { fr: "Réceptionnée démontée", en: "Received disassembled" } },
            not_compliant_fischer: { label: { fr: "Montage non conforme au standard Fischer", en: "Installation not compliant with Fischer standard" } },
            clamps_bolt_removed: { label: { fr: "Pinces/boulon démontés (de nombreux pré-contrôles ne peuvent être réalisés)", en: "Clamps/bolt removed (many pre-checks cannot be carried out)" } },
        }
    },
    motor: {
        label: {
            fr: "Moteur",
            en: "Motor"
        },
		category: ['precise'],
		type: "select",
        defaultOptions: {
            nad: { label: { fr: "R.A.S.", en: "N.A.D." } },
            burnt: { label: { fr: "Brûlé", en: "Burnt" } },
            short_circuit: { label: { fr: "Court-circuit", en: "Short circuit" } },
            out_of_phase: { label: { fr: "Déphasé", en: "Out of phase" } },
        }
    },
    airCurtain: {
        label: {
            fr: "Rideau d'air",
            en: "Air curtain"
        },
		category: ['precise'],
		type: "select",
        defaultOptions: {
            nad: { label: { fr: "R.A.S.", en: "N.A.D." } },
            polluted: { label: { fr: "Pollué", en: "Polluted" } },
            obstructed: { label: { fr: "Obstrué", en: "Obstructed" } },
            presence_of_cutting_fluid: { label: { fr: "Présence de liquide de coupe", en: "Presence of cutting fluid" } },
        }
    },
    completeDissassembly: {
        label: {
            fr: "Démontage complet",
            en: "Complete dissassembly"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    waterLeak: {
        label: {
            fr: "Fuite d'eau",
            en: "Water leak"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    dirty: {
        label: {
            fr: "Sale",
            en: "Dirty"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    obstructed: {
        label: {
            fr: "Bouché",
            en: "Obstructed"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    possibleCauses: {
        label: {
            fr: "Causes possibles",
            en: "Possible causes"
        },
		category: ['precise'],
		type: "input",
        inputType: "textarea",
        columnWeight: 4,
    }
};

export const preDisassemblyFields = fieldsParser(_preDisassemblyFields);