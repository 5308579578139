import fieldsParser from "../../../fieldsParser";

export const _bearingsBackCartridgesFields = {
    bearings_backCartridges_nonexistent: {
        label: {
            fr: "Inexistant",
            en: "Nonexistent"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    bearings_backCartridges_nonDisassembled: {
        label: {
            fr: "Non démonté",
            en: "Non-disassembled"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    bearings_backCartridges_corrosion: {
        label: {
            fr: "Aspect visuel - Corrosion",
            en: "Appearance - Corrosion"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    bearings_backCartridges_contactRust: {
        label: {
            fr: "Aspect visuel - Rouille de contact",
            en: "Appearance - Contact rust"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    bearings_backCartridges_mechanicalWear: {
        label: {
            fr: "Aspect visuel - Usure mécanique",
            en: "Appearance - Mechanical wear"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    bearings_backCartridges_porteesRoulementsCorrosion: {
        label: {
            fr: "Portées des roulements - Corrosion",
            en: "Portées des roulements - Corrosion"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    bearings_backCartridges_porteesRoulementsContactRust: {
        label: {
            fr: "Portées des roulements - Rouille de contact",
            en: "Portées des roulements - Contact rust"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    bearings_backCartridges_porteesRoulementsMechanicalWear: {
        label: {
            fr: "Portées des roulements - Usure mécanique",
            en: "Portées des roulements - Mechanical wear"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    bearings_backCartridges_coolingSystemCorrosion: {
        label: {
            fr: "Circuit de refroidissement - Corrosion",
            en: "Cooling system - Corrosion"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    bearings_backCartridges_coolingSystemContactRust: {
        label: {
            fr: "Circuit de refroidissement - Rouille de contact",
            en: "Cooling system - Contact rust"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    bearings_backCartridges_coolingSystemMechanicalWear: {
        label: {
            fr: "Circuit de refroidissement - Usure mécanique",
            en: "Cooling system - Mechanical wear"
        },
		category: ['precise'],
		type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } },
        }
    },
    bearings_backCartridges_possibleCauses: {
        label: {
            fr: "Causes possibles",
            en: "Possible causes"
        },
		category: ['precise'],
		type: "select",
        defaultOptions: {
            nad: { label: { fr: "R.A.S.", en: "N.A.D." } },
            mechanical_wear: { label: { fr: "Usure mécanique", en: "Mechanical wear" } },
            mounting_error: { label: { fr: "Erreur de montage", en: "Mounting error" } },
            coolant_port_corrosion: { label: { fr: "Corrosions aux orifices du liquide de refroidissement", en: "Coolant port corrosion" } },
            geometry_out_tolerance: { label: { fr: "Géométrie hors tolérance", en: "Geometry out of tolerance" } },
        },
        multiple: true
    },
    bearings_backCartridges_necessaryMeasures: {
        label: {
            fr: "Mesures à prendre",
            en: "Necessary measures"
        },
		category: ['precise'],
		type: "select",
        defaultOptions: {
            nad: { label: { fr: "R.A.S.", en: "N.A.D." } },
            replacing_rear_bearing_cartridge: { label: { fr: "Remplacement de la cartouche des roulements arrière", en: "Replacing the rear bearing cartridge" } },
            rear_bearing_cartridge_preserved: { label: { fr: "Cartouche des roulements avant conservée après nettoyage", en: "Rear bearing cartridge preserved after cleaning" } },
            not_disassembled: { label: { fr: "Non démontée", en: "Not disassembled" } },
        },
        multiple: true
    },
};

export const bearingsBackCartridgesFields = fieldsParser(_bearingsBackCartridgesFields);