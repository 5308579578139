export default Object.freeze({
    ISSB: "issb",
    FOUB: "foub",
    FOUR: "four",
    REEA: "reea",
    REEB: "reeb",
    PASS: "pass",
    MAIM: "maim",
    RETR: "retr",
    ISSM: "issm",
    SPIN: "spin",
    FORM: "form",
    RSRE: "rsre",
    RSFA: "rsfa",
    FERR: "ferr"
})