/* INFORMATIONS GÉNÉRALES */
import { _generalInformationsFields } from "./generalInformations";
/* DEPOSE BROCHE */
import {_spindleRemovalFields} from "@/formFields/interventionReport/spindleRemoval";
/* REPOSE BROCHE */
import {_spindleRestFields} from "@/formFields/interventionReport/spindleRest/spindleRest";
import {_spindleRestCommissioning} from "@/formFields/interventionReport/spindleRest/commissioning";
import {_lubrificationFields} from "@/formFields/interventionReport/lubrifications";
import {_spindleRestGeometry} from "@/formFields/interventionReport/spindleRest/geometry";
import {_spindleRestKeel} from "@/formFields/interventionReport/spindleRest/keel";
/* OBSERVATIONS */
import {_observationFields} from "@/formFields/interventionReport/observation";
/* POINTAGE */
import {_reportTableCheckInFields} from "@/formFields/interventionReport/reportTable/checkIn";
import {_reportTableGoToFields} from "@/formFields/interventionReport/reportTable/goTo";
import {_reportTableWorkHourFields} from "@/formFields/interventionReport/reportTable/workHour";
import {_reportTableWorkMealFields} from "@/formFields/interventionReport/reportTable/workMeal";
import {_reportTableReturnFields} from "@/formFields/interventionReport/reportTable/return";
import {_reportTableFeesFields} from "@/formFields/interventionReport/reportTable/fees";
/* SIGNATURES */
import {_signaturesFields} from "@/formFields/interventionReport/signatures";
/* ANALYSE D'ÉQUILIBRAGE */
import {_balancingAnalysisFields} from "@/formFields/interventionReport/balancingAnalysis";
/* RELEVÉS SUR SITE */
import {_onSiteSurveysFields} from "@/formFields/interventionReport/onSiteSurveys";
/* PHOTO */
import {_photoFields} from "@/formFields/interventionReport/photos";

import fieldsParser from "@/formFields/fieldsParser";


export const interventionReportFields = {
    /* INFORMATIONS GÉNÉRALES */
    ..._generalInformationsFields,
    /* DEPOSE BROCHE */
    ..._spindleRemovalFields,
    /* REPOSE BROCHE */
    ..._spindleRestFields,
    ..._spindleRestCommissioning,
    ..._lubrificationFields,
    ..._spindleRestGeometry,
    ..._spindleRestKeel,
    /* OBSERVATION */
    ..._observationFields,
    /* POINTAGE */
    ..._reportTableCheckInFields,
    ..._reportTableGoToFields,
    ..._reportTableWorkHourFields,
    ..._reportTableWorkMealFields,
    ..._reportTableReturnFields,
    ..._reportTableFeesFields,
    /* SIGNATURES */
    ..._signaturesFields,
    /* ANALYSE D'ÉQUILIBRAGE */
    ..._balancingAnalysisFields,
    /* RELEVÉS SUR SITE */
    ..._onSiteSurveysFields,
    /* PHOTOS */
    ..._photoFields
}

export const reportTableFields = fieldsParser({
    ..._reportTableCheckInFields,
    ..._reportTableGoToFields,
    ..._reportTableWorkHourFields,
    ..._reportTableWorkMealFields,
    ..._reportTableReturnFields,
    ..._reportTableFeesFields
})

/* INFORMATIONS GÉNÉRALES */
export { generalInformationsFields } from "./generalInformations"
/* DEPOSE BROCHE */
export { spindleRemovalFields } from "./spindleRemoval";
/* REPOSE BROCHE */
export { spindleRestFields } from "./spindleRest/spindleRest";
export { spindleRestCommissioning } from "./spindleRest/commissioning"
export { lubrificationFields } from "./lubrifications";
export { spindleRestGeometry } from "./spindleRest/geometry"
export { spindleRestKeel } from "./spindleRest/keel"
/* OBSERVATION */
export { observationFields } from "./observation"
/* POINTAGE */
export { reportTableCheckInFields } from "./reportTable/checkIn";
export { reportTableGoToFields } from "./reportTable/goTo";
export { reportTableWorkHourFields } from "./reportTable/workHour";
export { reportTableWorkMealFields } from "./reportTable/workMeal";
export { reportTableReturnFields } from "./reportTable/return";
export { reportTableFeesFields } from "./reportTable/fees";
/* SIGNATURES */
export { signaturesFields } from "./signatures";
/* ANALYSE D'ÉQUILIBRAGE */
export { balancingAnalysisFields } from "./balancingAnalysis"
/* RELEVÉS SUR SITE */
export { onSiteSurveysFields } from "./onSiteSurveys"
/* PHOTO */
export { photoFields } from "./photos"
