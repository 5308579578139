<style scoped>
    .photo-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 22px 0 0;
    }

    .photo {
        position: relative;
        width: 100px;
        height: 100px;
        border-radius: 4px;
        overflow: hidden;
    }

    .photo img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .photo-buttons {
        display: flex;
        justify-content: center;
        margin-top: 6px;
        width: 100%;
    }

    .photo-buttons-right {
        display: flex;
        flex-direction: column;
    }

    .photo-buttons-right > div {
        display: flex;
    }

    .photo-buttons-right button {
        margin-left: 10px;
    }

    .photo-buttons button {
        position: relative;
        flex: 1;
        height: 30px;
        border: none;
        border-radius: 4px;
        background-color: #0d3b70;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        cursor: pointer;
        transition: 0.15s ease;
    }

    .photo-buttons button:hover {
        opacity: 0.85;
    }

    .photo-buttons button:first-child {
        background-color: #1e73be;
    }

    .photo-buttons button:not(:last-child) {
        margin-right: 6px;
    }

    .photo-buttons button svg {
        color: white;
        width: 45%;
        height: 45%;
    }

    .photo-viewer {
        position: fixed;
        z-index: 1000;
        display: none;
        justify-content: center;
        align-content: center;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 10, 20, 0.4);
        padding: 30px;
        box-sizing: border-box;
        opacity: 0;
        top: 0;
        left: 0;
    }

    .photo-viewer img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .photo-with-right-buttons {
        margin-right: 10px;
    }

    .photo-container-row {
        flex-direction: row;
    }

</style>

<template>

    <div class="photo-container" :class="{'photo-container-row': buttonsPosition === 'right'}">
        <div class="photo" :class="{'photo-with-right-buttons': buttonsPosition === 'right'}">
            <img :src="image" alt="">
        </div>
        <div class="photo-buttons" v-if="buttonsPosition === 'bottom'">
            <button type="button" class="show-photo-btn" @click="showPhoto">
                <font-awesome-icon :icon="['fas', 'search']" />
            </button>
            <button v-if="displayDeleteButton" type="button" class="delete-photo-btn" @click="deletePhoto">
                <font-awesome-icon :icon="['fas', 'times']" />
            </button>
        </div>
        <div class="photo-buttons-right" v-else-if="buttonsPosition === 'right'">
            <div>
                <slot name="title"></slot>
            </div>
            <div>
                <BaseButton class="blue-button" @click="showPhoto" buttonText="">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'search']" />
                    </template>
                </BaseButton>
                <BaseButton class="dark-blue-button" @click="deletePhoto" buttonText="">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'times']" />
                    </template>
                </BaseButton>
                <slot name="extraButtons"></slot>
            </div>
            
        </div>
    </div>

    <div class="photo-viewer" :id="id" @click="hidePhoto">
        <img :src="image" alt="">
    </div>

</template>

<script>

    import anime from 'animejs';
    import BaseButton from '../Base/BaseButton';

    export default {
        name: 'Photo',
        components: {
            BaseButton
        },
        props: {
            image: {
                type: String,
                default: ''
            },
            index: {
                type: String,
                default: ''
            },
            displayDeleteButton: {
                type: Boolean,
                default: true
            },
            buttonsPosition: {
                type: String,
                default: 'bottom'
            }
        },
        emits: ["deletePhoto"],
        data() {
            return {
                id: null
            }
        },
        mounted() {
            this.id = Math.floor(Math.random() * Date.now());
        },
        methods: {
            deletePhoto() {
                this.$emit('deletePhoto', this.index);
            },
            showPhoto() {
                let photoViewer = document.getElementById(this.id);
                photoViewer.style.display = 'flex';
                anime({
                    targets: photoViewer,
                    duration: 300,
                    easing: 'easeOutSine',
                    opacity: [0, 1]
                });
            },
            hidePhoto() {
                let photoViewer = document.getElementById(this.id);
                anime({
                    targets: photoViewer,
                    duration: 300,
                    easing: 'easeOutSine',
                    opacity: [1, 0],
                    complete: function () {
                        photoViewer.style.display = 'none';
                    }
                });
            }
        }
    }
</script>