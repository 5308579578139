<style scoped>

    .container {
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    .container-ag-grid {
        display: flex;
        flex: 1;
        padding: 30px;
    }

    #cards {
        display: flex;
        overflow-x: auto;
        width: 100vw;
        padding-bottom: 30px;
    }

    :deep(.card) {
        margin: 2.5vw 0 0 2.5vw !important;
        width: 30vw !important;
        padding: 20px !important;
        display: block !important;
        flex: 0 0 30vw !important;
    }

    :deep(.card hr) {
        width: calc(100% + 40px);
        margin: 20px 0 20px -20px;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid rgb(220, 225, 230);
    }

    /* Permet de corriger le problème de la margin qui ne s'affichait pas sur la tablette */
    #cards > :last-child {
        margin-left: 2.2vw;
        color: transparent;
    }
</style>

<template>

    <ButtonTopbar buttonText="Nouvelle affaire" @onTopbarButtonClicked="displayCreateAffairModal">
        <template v-slot:iconBefore>
            <font-awesome-icon :icon="['fas', 'plus']" />
        </template>
    </ButtonTopbar>

    <div v-if="$store.getters.isTechnician" class="container">
        <div id="cards">
            <TabletAffair
                v-for='affair in affairs'
                v-bind:key="affair"
                :affair="affair"
                @onOpenDocumentation="openDocumentation"
                @onOpenExpertAssessmentModal="displayExpertAssessmentTypeModalAccordingToContext"
                @onOpenConformityReportModal="openConformityReportModal"
                @onOpenInterventionReportModal="openInterventionReportModal"
                @onOpenMessages="function(affairId) { getMessagesAndOpenModal(affairId) }"
                @onDownloadExpertAssessmentPDF="displayExpertAssessmentTypeModalAccordingToContext"
            ></TabletAffair>
            <!-- Permet de corriger le problème de la margin qui ne s'affichait pas sur la tablette -->
            <span>|</span>
        </div>
    </div>

    <div class="container-ag-grid" v-else>
        <Aggrid
            apiRoute='affair/listForTablet'
            :apiParams="filter"
            :columnDefs="columnDefs" 
            :context="context"
            :frameworkComponents="frameworkComponents"
            :cellRendererFunctions="cellRendererFunctions"
            aggridHeightSubstract="100px"
        >
            <template v-slot:filter>
                <div>
                    <BaseInput v-model="search.affair" label="Rechercher une affaire" type="text" name="search" @onChange="onSearchInputChange($event)">
                        <template v-slot:iconBefore>
                            <font-awesome-icon :icon="['fas', 'search']"/>
                        </template>
                    </BaseInput>
                </div>
                <div>
                    <BaseSelect 
                        v-model="search.technician" 
                        label="Agent concerné"
                        name="technicianId" 
                        api="account/search"
                        :apiParams="{
                            role: accountRoleEnum.TECHNICIAN
                        }"
                        fieldValue="id"
                        fieldLabel="name"
                        :required="true"
                        :searchable="true"
                        @onChange="onFiltersChange($event)"
                        :minChars="1"
                    />
                </div>
                <div>
                    <BaseSelect 
                        v-model="affairStatusSearch"
                        label="Statut de l'affaire"
                        :defaultOptions="affairStatus"
                        name="affairStatus"
                        @onChange="onFiltersChange($event)"
                    />
                </div>
            </template>
        </Aggrid>
    </div>

    <NewAffairModal 
        v-show="displayAffairModal === true" 
        @onCreateAffair="onCreateAffair"
        @onClose="closeAffairModal"
    >
    </NewAffairModal>

    <ExpertAssessmentModal
        ref="expertAssessmentModal"
        v-show="displayExpertAssessmentModal"
        @onExit="closeExpertAssessmentModal"
        @openMessagesModal="openMessagesModal"
        @refresh="reloadAffairs"
        :eaType="eaType"
    >
    </ExpertAssessmentModal>

    <ConformityReportModal
        ref="conformityReportModal"
        v-show="displayConformityReportModal"
        @onExit="closeConformityReportModal"
        @refresh="reloadAffairs"
    >
    </ConformityReportModal>

    
    <InterventionReportModal
        ref="interventionReportModal"
        v-show="displayInterventionReportModal"
        @onExit="closeInterventionReportModal"
        @refresh="reloadAffairs"
    >
    </InterventionReportModal>

    <MessagesModal
        v-show="displayMessagesModal"
        v-model="messages"
        :title="`Fil de discussion de l'affaire`"
        @close="closeMessagesModal"
        @onSendMessage="onSendMessage"
    >
    </MessagesModal>

    <Modal :hideIcon="true" v-show="displayExpertAssessmentTypeModal === true">
        <template v-slot:modalTitle>
            <div>RAPPORT D'EXPERTISE</div>
        </template>
        <template v-slot:modalContent>
            <form>
                <div>
                    <BaseSelect 
                        :defaultOptions="[
                            { label: 'EBC', value: 'ebc' },  
                            { label: 'Kessler', value: 'kessler' },
                            { label: 'Light', value: 'light' }
                        ]"
                        label="Type de rapport"
                        name="eaType"
                        v-model="eaType"
                        :required="true"
                    />
                </div>
            </form>
        </template>
        <template v-slot:modalButtons>
            <BaseButton @click="closeExpertAssessmentTypeModal" buttonText="Fermer" class="white-button">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton @click="validateExpertAssessmentType" buttonText="Valider" class="orange-button">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'check']" />
                </template>
            </BaseButton>
        </template>
    </Modal>

    <Dialog ref="dialog"/>
</template>

<script>
    import axios from 'axios';
    import TabletAffair from "./TabletAffair";
    import ExpertAssessmentModal from "./ExpertAssessmentModal";
    import ConformityReportModal from "./ConformityReportModal";
    import InterventionReportModal from "./InterventionReportModal";
    import ButtonTopbar from "../../components/Topbar/ButtonTopbar";
    import Modal from "../../components/App/Modal";
    import BaseButton from "../../components/Base/BaseButton";
    import BaseInput from "../../components/Base/BaseInput";
    import BaseSearchingInput from "../../components/Base/BaseSearchingInput";
    import RadioButtonContainer from "../../components/App/RadioButtonContainer";
    import BaseRadioButton from "../../components/Base/BaseRadioButton";
    import BaseSelect from "../../components/Base/BaseSelect";
    import BaseDatepicker from "../../components/Base/BaseDatepicker";
    import Photo from "../../components/App/Photo";
    import accountRoleEnum from "../../enums/accountRoleEnum";
    import MessagesModal from '../../components/App/MessagesModal';
    import Aggrid from '../../components/Aggrid/Aggrid';
    import ListTabletAffairsActionButtonsRenderer from './ListTabletAffairsActionButtonsRenderer.vue';
    import ListTextWithIconRenderer from '../../components/Aggrid/ListTextWithIconRenderer';
    import changeTypeEnum from '../../enums/changeTypeEnum';
    import Dialog from "@/components/App/Dialog";
    import NewAffairModal from "./NewAffairModal";
    import config from "../../config";

    export default {
        name: 'Affairs',
        components: {
            Dialog,
            Photo,
            BaseSelect,
            BaseRadioButton,
            RadioButtonContainer,
            BaseInput,
            BaseSearchingInput,
            BaseDatepicker,
            BaseButton,
            Modal,
            ButtonTopbar,
            TabletAffair,
            ExpertAssessmentModal,
            ConformityReportModal,
            InterventionReportModal,
            MessagesModal,
            Aggrid,
            ListTabletAffairsActionButtonsRenderer,
            ListTextWithIconRenderer,
            NewAffairModal,
        },
        data() {
            return {
                accountRoleEnum,
                context: null,
                downloadContext: null,
                affairs: [],
                affairId: null,
                messages: [],
                displayAffairModal: false,
                search: {
                    search: null,
                    technician: null
                },
                pictureType: null,
                displayMessagesModal: false,
                columnDefs: [
                    { field: 'affairNumber', headerName : `Numéro`, flex: 1 },
                    { field: 'receiptDate', headerName : `Date récep.`, flex: 1 },
                    { field: 'deadlineNextTask', headerName : `Date éché.`, flex: 2, cellRendererFunctionName: 'EcheanceRendererFunction', suppressMenu: true },
                    { field: 'shipToCustomer.name', headerName : `Client`, flex: 2 },
                    { headerName : `Marque / Type`, flex: 2, cellRendererFunctionName:'SprindleRendererFunction', suppressMenu: true },
                    { field: 'affairDuration', headerName : `Temps passé`, flex: 1 },
                    { headerName : 'Statut', flex: 2, cellRenderer: 'ListTextWithIconRenderer', cellRendererParams: { 
                        field: 'status',
                        mode: 'status'
                    }},
                    { headerName : '', width: 200, cellRenderer: 'ListTabletAffairsActionButtonsRenderer', suppressMenu: true }
                ],

                frameworkComponents: {
                    ListTabletAffairsActionButtonsRenderer: ListTabletAffairsActionButtonsRenderer,
                    ListTextWithIconRenderer: ListTextWithIconRenderer
                },
                cellRendererFunctions: new Map([
                    [
                        'EcheanceRendererFunction',
                        function(param) {
                            return `
                                ${param?.data?.deadlineNextTask ? `<div>Tâche : ${param?.data?.deadlineNextTask}</div>` : ''}
                                ${param?.data?.deadline ? `<div>Affaire : ${param?.data?.deadline}</div>` : ''}
                            `;
                        }
                    ],[
                        'SprindleRendererFunction',
                        function(param) {
                            return `${param?.data?.spindle?.spindleType.spindleBrand.name ?? ''}<br/>${param?.data?.spindle?.spindleType.name ?? ''}`;
                        }
                    ]
                ]),
                displayExpertAssessmentModal: false,
                displayConformityReportModal: false,
                displayInterventionReportModal: false,
                affairStatusSearch: localStorage.getItem('affairStatusFilter') ? JSON.parse(localStorage.getItem('affairStatusFilter')) : null,
                affairStatus: Object.keys(this.$store.getters.getAffairStatusList).map(affairStatus => {
                    return {
                        label: this.$store.getters.getAffairStatusList[affairStatus].label,
                        value: affairStatus
                    };
                }),
                filter: {
                    affairStatus: localStorage.getItem('affairStatusFilter') ? JSON.parse(localStorage.getItem('affairStatusFilter')).value : null
                },
                displayExpertAssessmentTypeModal: false,
                eaType: { label: "EBC", value: "ebc" },
                searchTimeout: null,
                expertAssessmentTypeModalContext: null
            }
        },
        beforeMount() {
            this.context = {
                componentParent: this
            };
        },
        mounted() {
            if(this.$store.getters.isTechnician) {
                this.loadAffairs();
            }
        },
        methods: {
            loadAffairs() {
                axios
                .get('affair/listForTablet', { 
                    showPreloader: true 
                })
                .then(response => {
                    let datas = response.data;

                    this.affairs = datas;
                })
                .catch(() => {});
            },
            reloadAffairs() {
                if(this.$store.getters.isTechnician) {
                    this.loadAffairs();
                }
                else {
                    this.emitter.emit('ag-grid-reload');
                }
            },
            closeAffairModal() {
                this.displayAffairModal = false;
            },
            displayCreateAffairModal() {
                this.displayAffairModal = true;
            },
            getMessagesAndOpenModal(affairId) {
                this.affairId = affairId;

                axios
                .get(`affair/${affairId}/getMessages`, { 
                    toastError: true,
                    showPreloader: true 
                })
                .then(response => {
                    this.messages = response.data;

                    this.openMessagesModal();
                })
                .catch(() => {});
            },
            openMessagesModal() {
                this.displayMessagesModal = true;
            },
            closeMessagesModal() {
                this.messages = [];
                this.affairId = null;
                this.displayMessagesModal = false;
            },
            onSendMessage(messageContent) {
                axios
                .post('message/create', {
                    affairId: this.affairId,
                    content: messageContent
                }, { 
                    showPreloader: true 
                })
                .then(response => {
                    this.messages.push(response.data);
                })
                .catch(() => {});
            },
            onSearchInputChange(input) {
                if (this.searchTimeout !== null) {
                    clearTimeout(this.searchTimeout);
                }

                this.searchTimeout = setTimeout(() => {
                    this.filter.search = input.value
                }, 250)
            },
            onFiltersChange(input) {
                if (input.changeType === changeTypeEnum.USER) {
                    switch (input.name) {
                        case 'technicianId':
                            this.filter[input.name] = input?.value?.value || null;
                        break;
                        case 'affairStatus':
                            if (!this.$store.getters.isTechnician) {
                                this.filter[input.name] = input?.value?.value || null;
                                if (input?.value) {
                                    localStorage.setItem('affairStatusFilter', JSON.stringify(input?.value));
                                } else {
                                    localStorage.removeItem('affairStatusFilter');
                                }
                            }

                        break;
                    }
                }
            },
            openDocumentation(url) {
                if(url) {
                    window.open(url, '_blank').focus();
                }
            },
            openExpertAssessmentModal(affairId) {
                this.eaType = { label: "EBC", value: "ebc" };
                this.displayExpertAssessmentTypeModal = true;
                this.affairId = affairId;
            },
            closeExpertAssessmentTypeModal() {
                this.displayExpertAssessmentTypeModal = false;
            },
            validateExpertAssessmentType() {
                if (this.downloadContext === 'download') {
                    this.downloadExpertAssessmentPDF(this.affairId, this.eaType.value)
                } else {
                    axios
                    .get(`affair/tablet/${this.affairId}`, { 
                        toastError: true, 
                        showPreloader: true 
                    })
                    .then((result) => {
                        let affair = result.data

                        axios
                            .get('articleFamily/all', {
                                toastError: true,
                                showPreloader: true
                            })
                            .then((result) => {
                                affair.articlesFamily = result.data;

                                this.messages = affair.messages;

                                this.$refs.expertAssessmentModal.loadExpertAssessment(affair);

                                this.closeExpertAssessmentTypeModal();

                                this.displayExpertAssessmentModal = true;
                            });
                    })
                    .catch((error) => {console.log('error', error)});
                }
            },
            closeExpertAssessmentModal() {
                this.displayExpertAssessmentModal = false;
            },
            openConformityReportModal(affairId) {
                axios
                .get(`affair/tablet/${affairId}`, { 
                    toastError: true, 
                    showPreloader: true 
                })
                .then((result) => {
                    let affair = result.data;

                    this.$refs.conformityReportModal.loadConformityReport(affair);
                    this.displayConformityReportModal = true;
                })
                .catch((error) => {console.log('error', error)});
            },
            closeConformityReportModal() {
                this.displayConformityReportModal = false;
            },
            openInterventionReportModal(affairId) {
                axios
                .get(`affair/tablet/${affairId}`, { 
                    toastError: true, 
                    showPreloader: true 
                })
                .then((result) => {
                    let affair = result.data;

                    this.$refs.interventionReportModal.loadInterventionReport(affair);
                    this.displayInterventionReportModal = true;
                })
                .catch((error) => {console.log('error', error)});
            },
            closeInterventionReportModal() {
                this.displayInterventionReportModal = false;
            },
            onCreateAffair() {
                this.closeAffairModal();
                if (this.$store.getters.isTechnician) {
                    this.loadAffairs();
                } else {
                    this.emitter.emit('ag-grid-reload');
                }
            },
            displayExpertAssessmentTypeModalAccordingToContext(affairId, context) {
                this.downloadContext = context;
                this.openExpertAssessmentModal(affairId);
            },
            downloadExpertAssessmentPDF(affairId, reportType) {
                axios
                .get(`expertAssessment/download?affairId=${affairId}&reportType=${reportType}`, {
                    toastError: true,
                    showPreloader: true
                })
                .then(response => { 
                    if(this.$store.getters.isFromAppTablet){
                        document.dispatchEvent(new CustomEvent('downloadFile', {
                            detail: { 
                                absoluteFileUrl: `${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${response.data}` 
                            }
                        }));
                    }
                    else {
                        window.open(`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${response.data}`, "_blank").focus();
                    }


                })
                .catch((error) => {});
            }
        }
    }

</script>