import fieldsParser from "@/formFields/fieldsParser"

export const _spindleRestCommissioning = {
    spindleRest_deconsignment: {
        label: {
            fr: "Déconsignation",
            en: "Deconsignment"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } }
        }
    },
    spindleRest_pressureControl: {
        label: {
            fr: "Contrôle des pressions",
            en: "Pressure control"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } }
        }
    },
    spindleRest_hydraulicSystem: {
        label: {
            fr: "Purge circuit hydraulique et contrôle de la pression",
            en: "Hydraulic system bleeding and pressure control"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } }
        }
    },
    spindleRest_pressurizationControl: {
        label: {
            fr: "Contrôle de la pressurisation",
            en: "Control of the pressurization"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } }
        }
    },
    spindleRest_coolingSystemControl: {
        label: {
            fr: "Contrôle du bon fonctionnement du circuit de refroidissement",
            en: "Control of the correct operation of the cooling system"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } }
        }
    },
    spindleRest_manualToolChangeTests: {
        label: {
            fr: "Essais changement outils manuel",
            en: "Manual tool change tests"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } }
        }
    },
    spindleRest_running: {
        label: {
            fr: "Rodage (vitesse max ou utilisation)",
            en: "Running-in (max speed or use)"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } }
        }
    },
    spindleRest_automaticToolChangeTests: {
        label: {
            fr: "Essais changement outils automatique",
            en: "Automatic tool change tests"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } }
        }
    },
    spindleRest_bearingFrequenciesRecording: {
        label: {
            fr: "Relevé des fréquences propres aux roulements",
            en: "Recording of the frequencies specific to the bearings"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } }
        }
    },
    spindleRest_balancingCheckAndRecord: {
        label: {
            fr: "Contrôle d'équilibrage et relevé d'équilibrage",
            en: "Balancing check and balancing record"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } }
        }
    },
    spindleRest_sensorsControl: {
        label: {
            fr: "Contrôle capteur(s)",
            en: "Sensor(s) control"
        },
        category: ['ebc'],
        type: "radio",
        options: {
            yes: { label: { fr: "Oui", en: "Yes" } },
            no: { label: { fr: "Non", en: "No" } }
        }
    },
    spindleRest_toolTighteningControl: {
        label: {
            fr: "Contrôle de serrage outils",
            en: "Tool tightening control"
        },
        category: ['ebc'],
        type: "input",
        inputType: "number",
        unit: "kN"
    }
}

export const spindleRestCommissioning = fieldsParser(_spindleRestCommissioning)